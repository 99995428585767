import React from "react";
import NoReviews from "./NoReviews";
import ReviewsList from "./ReviewsList";
import { useSelector } from "react-redux";

const LawyerReviews = ({ lawyerHubData }) => {
  return (
    <>
      {lawyerHubData && (
        <div className="py-5">
          <ul className="flex gap-4 border-b">
            <li className="border-b-[2px] pb-2 border-b-[#18475A]">Lawyer Reviews</li>
            {/* <li className="pb-2">Additional Lawyer Projects</li> */}
          </ul>

          {/* <ul className="flex gap-2 mt-6">
            <li className=" text-sm  font-medium bg-[#18475A] border text-white border-[#D1D1D1] rounded-3xl py-2 px-4">
              All
            </li>
            <li className=" text-sm  font-medium  border border-[#D1D1D1] rounded-3xl py-2 px-4">Review Requested</li>
            <li className=" text-sm  font-medium  border border-[#D1D1D1] rounded-3xl py-2 px-4">Review Ongoing</li>
            <li className=" text-sm  font-medium  border border-[#D1D1D1] rounded-3xl py-2 px-4">Completed</li>
          </ul> */}

          <ReviewsList />
          {/* <NoReviews /> */}
        </div>
      )}
    </>
  );
};

export default LawyerReviews;
