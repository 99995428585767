import { useEffect } from "react";
import { Modal } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { changePaymentSuccessModalView } from "../../chatSlice";

const PaymentSuccessModal = (props) => {
  const dispatch = useDispatch();
  const openPaymentSuccessModal = useSelector((state) => state.chat.openPaymentSuccessModal);

  useEffect(() => {
    if (openPaymentSuccessModal) {
      setTimeout(() => {
        dispatch(changePaymentSuccessModalView(false));
      }, 5000);
    }
  }, [openPaymentSuccessModal]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <Modal
      open={openPaymentSuccessModal}
      //   className="draft-document-popup rounded overflow-hidden pb-[24px]"
      className="w-full overflow-hidden"
      footer={null}
    >
      <div className="h-full rounded-[12px] px-[32px] py-[24px] bg-white shadow-[0px 2px 4px -2px #0000000F] mx-auto">
        <p className="font-[lora] text-[24px] text-[#18475A] font-bold text-center leading-[38px]">
          Welcome to the Inhouse Pro club! 🎉
        </p>
        <p className="font-[lora] text-[18px] text-[#353535] font-medium text-center">
          Enjoy access to generating unlimited legal documents
        </p>
      </div>
    </Modal>
  );
};

export default PaymentSuccessModal;
