import React, { useEffect, useState } from "react";
import Modal from "antd";
import { Briefcase, CalendarDots, CreditCard } from "@phosphor-icons/react";
import { useDispatch, useSelector } from "react-redux";
import {
  setCurrentPageLegalReviewStatus,
  setShowFindBestLawyer,
  setShowLegalReviewCompletedModal,
  setShowLegalReviewRequestStatus,
} from "../onboarding/onboardingSlice";
import { openPopup } from "src/components/DocumentViewer/helpers";
import { PLANS, ReviewStatus } from "src/components/Constants";
import { useUser } from "@clerk/clerk-react";
const LegalReviewMessagePrompt = (props) => {
  const { user } = useUser();
  const dispatch = useDispatch();
  const { threadData } = props;
  const [pageData, setPageData] = useState({});
  const [legalReviewRequest, setLegalReviewRequest] = useState({});
  const [showCta, setShowCta] = useState(false);

  const lawyerHubData = useSelector((state) => state.onboarding.lawyerHubData);
  const userMetadata = useSelector((state) => state.onboarding.userMetadata);

  // const completePayment = () => {
  //     openPopup(dispatch, "legal_review");
  // }

  // const scheduleCall = () => {
  //     dispatch(setShowFindBestLawyer(true))
  // }

  // const showScheduledcall = () => {
  //     dispatch(setShowLegalReviewRequestStatus(true))
  // }

  // const showLaywerDetails = () => {
  //     dispatch(setShowLegalReviewRequestStatus(true))
  // }

  // const viewReviewDetails = () => {
  //     dispatch(setShowLegalReviewCompletedModal(true))
  // }

  useEffect(() => {
    if (lawyerHubData && userMetadata && threadData) {
      const legalReview = lawyerHubData.find((item) => item.thread_id === threadData.id);
      setLegalReviewRequest(legalReview);
      dispatch(setCurrentPageLegalReviewStatus(legalReview));
    }
  }, [lawyerHubData, userMetadata, threadData]);

  useEffect(() => {
    if (lawyerHubData) {
      var legalReview = lawyerHubData?.find((item) => item.thread_id === threadData.id);
      setLegalReviewRequest(legalReview);
      dispatch(setCurrentPageLegalReviewStatus(legalReview));
    }
  }, [lawyerHubData]); // eslint-disable-line

  return (
    <>
      <div className="flex flex-row justify-between bg-white gap-[12px] border p-[24px] rounded-[12px] my-[12px]">
        <div className={`flex flex-row gap-[12px] w-full my-auto`} id="left-side">
          <div
            className="flex items-center justify-center my-auto w-[40px] h-[40px] bg-accurate rounded-full"
            id="content"
          >
            <Briefcase size={24} className="text-[#353535]" />
          </div>
          <div className="flex flex-col gap-[4px] my-auto" id="icon">
            <p className="text-[#333333] text-[14px] leading-[21px] font-[700]">Legal Review Requested</p>
            <p className="text-[#686868] text-[14px] leading-[19.6px] font-[500] ">
              at{" "}
              {new Date(legalReviewRequest?.lr_requested_at + "Z").toLocaleString("en-US", {
                day: "2-digit",
                month: "long",
                year: "numeric",
                hour: "2-digit",
                minute: "2-digit",
                hour12: true,
              })}
            </p>
          </div>
        </div>
        {/* {showCta && (
          <div id="right-side" className="my-auto">
            <p
              className="my-auto text-[#18475A] font-[700] text-[14px] leading-[19.6px] underline cursor-pointer"
              onClick={pageData?.cta_action}
            >
              {pageData?.cta}
            </p>
          </div>
        )} */}
      </div>
    </>
  );
};

export default LegalReviewMessagePrompt;
