import React, { useEffect } from "react";
import { ArrowRight, CaretDown, ChatsCircle, Check, Clock, ClockCountdown, PenNib } from "@phosphor-icons/react";
import Button from "../Button";
import { useSelector } from "react-redux";
import VerticalProgress, { Step } from "../components/VerticalProgress";
import { ReviewStatus } from "src/components/Constants";

const ReviewsList = () => {
  const [legalReviews, setLegalReviews] = React.useState([]);
  const lawyerHubData = useSelector((state) => state.onboarding.lawyerHubData);
  const threads = useSelector((state) => state.threads.threads);

  useEffect(() => {
    if (lawyerHubData) {
      try {
        setLegalReviews(lawyerHubData.sort((a, b) => new Date(b.lr_requested_at) - new Date(a.lr_requested_at)));
      } catch (e) {
        setLegalReviews([]);
      }
    } else {
      setLegalReviews([]);
    }
  }, [lawyerHubData]);

  const getThread = (threadId) => {
    for (const key in threads) {
      if (threads.hasOwnProperty(key)) {
        const thread = threads[key].find((item) => item.id === threadId);
        if (thread) {
          return thread;
        }
      }
    }
    return null;
    // debugger;
    // Object.keys(threads).forEach((key) => {
    //   debugger;
    //   const result = threads[key].filter((item) => {
    //     debugger;
    //     const thread = threads[key].find((item) => item.id === threadId);
    //     if (thread) {
    //       return thread;
    //     }
    //     return null;
    //   });
    // });
  };
  const getThreadIcon = (threadId) => {
    const thread = getThread(threadId);
    if (thread) {
      switch (thread.type) {
        case "ask":
          return <ChatsCircle className="mr-2 text-[#A843AA]" size={20} />;
        case "draft":
          return <PenNib className="mr-2 text-[#137513]" size={20} />;
        default:
          return;
      }
    }
  };
  const getLegalReviewStatus = (status) => {
    var ongoingReview = (
      <div className="flex items-center bg-[#DCA218]/10 px-[10px] py-[6px] rounded-lg">
        <p className="text-xs font-semibold">Review Ongoing</p>
        <ClockCountdown className="text-[#DCA218] ml-2" size={20} />
      </div>
    );

    var pendingLawyerMatching = (
      <div className="flex items-center bg-[#DCA218]/10 px-[10px] py-[6px] rounded-lg">
        <p className="text-xs font-semibold">Lawyer Matching is Pending</p>
        <ClockCountdown className="text-[#DCA218] ml-2" size={20} />
      </div>
    );

    var completed = (
      <div className="flex items-center bg-[#137513]/10 px-[10px] py-[6px] rounded-lg">
        <p className="text-xs font-semibold">Review Completed</p>
        <Check className="text-[#137513] ml-2" size={20} />
      </div>
    );

    switch (status) {
      case ReviewStatus.pending_lawyer_matching:
        return pendingLawyerMatching;
      case ReviewStatus.in_progress:
        return ongoingReview;
      case ReviewStatus.completed:
        return completed;
      default:
        return ongoingReview;
    }
  };

  const generateSteps = (legalReviewRequest) => {
    // const options = { weekday: 'long', day: 'numeric', month: 'short', year: 'numeric' };
    var steps = [
      {
        stepIcon: {
          icon: Check,
          color: "#137513",
        },
        title: {
          text: "Review Requested",
        },
        description: {
          text: `on ${new Date(legalReviewRequest?.lr_requested_at + "Z").toLocaleDateString("en-US", {
            weekday: "long",
            day: "numeric",
            month: "short",
            year: "numeric",
            hour: "numeric",
            minute: "numeric",
          })}`,
        },
        // info: 'Can you please help me understand what formalities do I need to undergo toensure I don’t get sued for not providing health ensure for my employees? I have 50 employees'
      },
    ];
    if (legalReviewRequest?.status === ReviewStatus.pending_lawyer_matching) {
      steps.push({
        stepIcon: {
          icon: Clock,
          color: "#DCA218",
        },
        title: {
          text: "Lawyer Matching is Pending",
        },
        description: {
          text: "We are currently looking for the best lawyer to review your request. You will be promptly notified on your email ID regarding this",
        },
      });
    }

    if (legalReviewRequest?.status === ReviewStatus.in_progress) {
      steps.push({
        stepIcon: {
          icon: Clock,
          color: "#DCA218",
        },
        title: {
          text: "Lawyer is analysing your request",
        },
        description: {
          text: "Mr. Andrew will understand your specific needs before starting the legal review. You will be promptly notified on your email ID regarding this",
        },
      });
    }
    if (legalReviewRequest?.status === ReviewStatus.completed) {
      steps.push({
        stepIcon: {
          icon: Check,
          color: "#137513",
        },
        title: {
          text: "Review Completed",
        },
        description: {
          text: "Your lawyer has analysed your request and has provided their review over the email.",
        },
      });
    }

    return steps;
  };
  return (
    <>
      {legalReviews &&
        legalReviews.map((legalReviewRequest) => (
          <div className="py-6 mt-6 bg-white border border-[#E4E4E4] rounded-xl">
            <div className="flex items-center justify-between px-6">
              <div className="flex items-center text-base font-medium">
                {getThreadIcon(legalReviewRequest?.thread_id)}
                {/* <ChatsCircle className="mr-2 text-[#A843AA]" size={20} /> */}
                {legalReviewRequest?.thread_title}
              </div>
              {getLegalReviewStatus(legalReviewRequest?.status)}
            </div>
            <hr className="text-[#E4E4E4] border-[1px] mt-6" />
            {/* <p className=''>{JSON.stringify(legalReviewRequest)}</p> */}
            <VerticalProgress wrapperClassName="px-6 mt-6" steps={generateSteps(legalReviewRequest)} />
            <div className="flex justify-end px-6 mt-6">
              <Button
                secondary
                className={" text-sm  px-3 "}
                text={
                  <>
                    Go to chat <ArrowRight size={20} />
                  </>
                }
                onClick={() => {}}
              />
            </div>
          </div>
        ))}
    </>
  );
};

export default ReviewsList;
