import { Briefcase, Check } from "@phosphor-icons/react";
import React from "react";

const ReviewRequestedButton = () => {
  return (
    <button className="bg-accurate border-[1px] border-[#137513] text-black w-[158x] h-[48px] rounded-[8px] gap-[12px] px-[12px] flex flex-row">
      <span className="my-auto">
        <Briefcase size={14} className="text-black my-auto" />
      </span>
      <span className="text-black my-auto text-[14px] leading-[19.6px]"> Review Requested</span>
      <span className="my-auto">
        <Check size={14} className="text-[#137513] my-auto" />
      </span>
    </button>
  );
};

export default ReviewRequestedButton;
