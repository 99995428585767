import { useEffect, useRef, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useUser } from "@clerk/clerk-react";
import BaseLayout from "../layouts/BaseLayout";
import useValidatePageAccess from "../../hooks/useValidatePageAccess";
import TopNav from "./Navs/TopNav";
import DocIcon from "src/images/doc-icon.png";
import ChatBoxComponent from "./components/ChatBoxComponent";
import ReviewShimmer from "../../components/Shimmers/ReviewShimmer";
import DraftShimmer from "../../components/Shimmers/DraftShimmer";
import AskShimmer from "../../components/Shimmers/AskShimmer";
import { useDispatch, useSelector } from "react-redux";
import { renderMessage } from "../../components/DocumentViewer/helpers";
import SavedDataModalComponent from "../../components/Prompts/SavedDataModalComponent";
import UpgradeToUnlockDocument from "./UpgradeToUnlockDocument";
import {
  addMessage,
  getAskThreadMessages,
  getDraftThreadMessages,
  resetThreadData,
  setAwaitingDraftQuestionsResponse,
  setAwaitingResponse,
  setChatInputMessage,
  setLastMessageId,
  setLegalReviewRequested,
  setMessagingDisabled,
  setThreadTitle,
} from "./messageSlice";
import { resetChatPage } from "./chatSlice";
import { useWebSocketContext } from "../../WebsocketProvider";
import { resetData, resetReferenceDocDownloadUrl } from "./threadsSlice";
import AskToDraftModal from "../../components/chat/Modals/AskToDraftModal";
import DraftDocumentQuestionaireModal from "./Modals/DraftDocumentQuestionaireModal";
import LegalReviewRequestAside from "../LawyerHub/LegalReviewRequestAside/index.js";
import UpSellFlowPopup from "../LawyerHub/UpsellFlowPopup";
import { ArrowDown, ArrowUpRight, Briefcase, ShareFat } from "@phosphor-icons/react";
import { trackAnalytics } from "../helpers";
import {
  resetRequestLegalReviewFailureReason,
  setCurrentPageLegalReviewStatus,
  setShowRequestLegalReviewAside,
} from "../onboarding/onboardingSlice";
import { setSelectedParties } from "../Homepage/HomeSlice";
import { setWordCountPopup } from "./messageSlice";
import WordLimitPopup from "src/components/WordLimitPopup";

const ChatPageComponent = () => {
  const dispatch = useDispatch();
  const { isLoaded } = useUser();
  let { threadType, chatId } = useParams();
  const [inputMessage, setInputMessage] = useState("");
  const [steps, setSteps] = useState([]);
  const [isScrollToBottomButtonVisible, setIsScrollToBottomButtonVisible] = useState(false);
  const [threadMessages, setThreadMessages] = useState([]);
  const [sentences, setSentences] = useState([]);
  const [shimmerNotAllowed, setShimmerNotAllowed] = useState(false);

  const { sendMessage, createMessage } = useWebSocketContext();

  const chatpageRef = useRef(null);
  const messageListRef = useRef(null);
  const bottomRef = useRef(null);

  const getThreadStatus = useSelector((state) => state.messageSlice.getThreadStatus);
  const awaitingResponse = useSelector((state) => state.messageSlice.awaitingResponse);
  const threads = useSelector((state) => state.threads.threads);
  const threadData = useSelector((state) => state.messageSlice.threadData);
  const email = useSelector((state) => state.homeslice.email);
  const showAskToDraftModal = useSelector((state) => state.messageSlice.showAskToDraftModal);
  const draftDocumentModalOpen = useSelector((state) => state.modalSlice.draftDocumentModalOpen);
  const showRequestLegalReviewAside = useSelector((state) => state.onboarding.showRequestLegalReviewAside);

  const showSubscriptionPopup = useSelector((state) => state.onboarding.showSubscriptionPopup);
  const showUpgradingPlan = useSelector((state) => state.onboarding.showUpgradingPlan);
  const showBusinessPlanUnlocked = useSelector((state) => state.onboarding.showBusinessPlanUnlocked);
  const showFindBestLawyer = useSelector((state) => state.onboarding.showFindBestLawyer);
  const showMeetingConfirmation = useSelector((state) => state.onboarding.showMeetingConfirmation);
  const showNextSteps = useSelector((state) => state.onboarding.showNextSteps);
  const showScheduleMeeting = useSelector((state) => state.onboarding.showScheduleMeeting);
  const scroll = useSelector((state) => state.messageSlice.scroll);
  const lawyerHubData = useSelector((state) => state.onboarding.lawyerHubData);
  const currentPageLegalReviewStatus = useSelector((state) => state.onboarding.currentPageLegalReviewStatus);
  const metadata = useSelector((state) => state.onboarding.userMetadata);
  const requestLegalReviewStatus = useSelector((state) => state.onboarding.requestLegalReviewStatus);
  const submittingQnA = useSelector((state) => state.messageSlice.submittingQnA);
  const regenerationHappening = useSelector((state) => state.messageSlice.regenerationHappening);

  useEffect(() => {
    if (requestLegalReviewStatus === "success") {
      dispatch(
        addMessage({
          new_messages: [{ message_type: "legal_review_message" }],
        })
      );
    }
  }, [requestLegalReviewStatus]);

  useEffect(() => {
    dispatch(resetRequestLegalReviewFailureReason());
    dispatch(resetChatPage());
    dispatch(resetData());
    dispatch(resetThreadData());
    dispatch(setSelectedParties({}));
    if (threadType === "ask") {
      dispatch(getAskThreadMessages(chatId));
    }
    if (threadType === "draft") {
      dispatch(getDraftThreadMessages(chatId));
    }
    dispatch(setAwaitingResponse(false));
    dispatch(setMessagingDisabled(false));
    dispatch(setAwaitingDraftQuestionsResponse(false));
    dispatch(resetReferenceDocDownloadUrl());

    setSteps([
      "Analyzing your request",
      `Reviewing account info on ${metadata?.entity_info?.name || metadata?.first_name}`,
      "Preparing response",
    ]);

    getCurrentLegalReviewStatus();

    // dispatch(getThread(chatId));
  }, [chatId]); // eslint-disable-line

  useEffect(() => {
    setInputMessage("");
  }, [chatId]); // eslint-disable-line

  useEffect(() => {
    scrollContainerToBottom(messageListRef);
  }, [scroll, threadData.messages]); // eslint-disable-line

  useEffect(() => {
    if (threadData.messages?.length > 1) {
      if (threadData?.document?.url || threadData?.document?.google_doc_id) {
        setSteps(["Analyzing your request", "Conducting legal research", "Updating your document"]);
      } else {
        setSteps(["Analyzing your request", "Conducting legal research ", "Preparing response"]);
      }
      var uniqueMessages = threadData.messages.filter(
        (message, index, self) =>
          index ===
          self.findIndex(
            (t) => t.text === message.text && t.message_type === message.message_type && t.id === message.id
          )
      );
      setThreadMessages(uniqueMessages);
      let lastMessageId = null;
      for (let i = uniqueMessages.length - 1; i >= 0; i--) {
        if (
          uniqueMessages[i].id !== null &&
          uniqueMessages[i].id !== "" &&
          uniqueMessages[i].message_type !== "regenerate_ai_response"
        ) {
          lastMessageId = uniqueMessages[i].id;
          break;
        }
      }

      if (lastMessageId !== null) {
        dispatch(setLastMessageId(lastMessageId));
      }
    } else {
      setThreadMessages(threadData.messages);
      let lastMessageId = null;
      for (let i = threadData?.messages?.length - 1; i >= 0; i--) {
        if (
          threadData.messages[i]?.id !== null &&
          threadData.messages[i]?.id !== "" &&
          threadData.messages[i]?.message_type !== "regenerate_ai_response"
        ) {
          lastMessageId = threadData.messages[i].id;
          break;
        }
      }

      if (lastMessageId !== null) {
        dispatch(setLastMessageId(lastMessageId));
      }
    }
    if (threadData?.messages && threadData?.messages.length > 0) {
      var lastMessageType = threadData.messages[threadData.messages.length - 1].message_type;
      if (lastMessageType === "draft_qna_message") {
        setSentences(["Provide answers to the questions above to continue"]);
        dispatch(setMessagingDisabled(true));
        if (submittingQnA) {
          setShimmerNotAllowed(false);
        } else {
          setShimmerNotAllowed(true);
        }
      } else if (lastMessageType === "regenerate_ai_response") {
        setSentences([
          "Unfortunately the AI wasn't able to handle your last response, use the regenerate button above to try again",
        ]);
        dispatch(setMessagingDisabled(true));
        if (regenerationHappening) {
          setShimmerNotAllowed(false);
        } else {
          setShimmerNotAllowed(true);
        }
      } else {
        setSentences(["Message Inhouse AI"]);
        setShimmerNotAllowed(false);
      }
    }
  }, [scroll, threadData.messages, getThreadStatus, window.location.href]); // eslint-disable-line

  useEffect(() => {
    getCurrentLegalReviewStatus();
  }, [lawyerHubData]); // eslint-disable-line

  useEffect(() => {
    if (getThreadStatus === "success") {
      var results = [];
      Object.keys(threads).forEach((key) => {
        const filtered = threads[key].filter((item) => {
          return item.id === chatId;
        });
        if (filtered.length > 0) {
          results = results.concat(filtered);
        }
      });
      if (results.length > 0) {
        dispatch(setThreadTitle(results[0].title));
      }
    }
  }, [getThreadStatus, threads]); // eslint-disable-line

  useValidatePageAccess();

  const onsubmit = () => {
    const wordCount = countInputMessageWords(inputMessage);
    if (wordCount <= 10000) {
      trackAnalytics(
        `${threadData.thread_type} thread: additional message`,
        {
          user_email: email,
          thread_id: chatId,
          message: inputMessage,
        },
        metadata
      );
      // window.analytics.track(`${threadData.thread_type} thread: additional message`, {
      //     "user_email": email, "thread_id": chatId, "message": inputMessage
      // })
      dispatch(setChatInputMessage(inputMessage));
      var message = createMessage(threadType, "add-message", { message: inputMessage, thread_id: chatId });
      sendMessage(message, true);
      dispatch(setMessagingDisabled(true));
    } else {
      dispatch(setWordCountPopup(true));
    }
  };
  const scrollContainerToBottom = (messageListRef) => {
    if (messageListRef.current) {
      messageListRef.current.scrollTop += 150;
    }
  };

  const onSumbitWithTenThousandWords = () => {
    const inpMessage = getFirstTenkWords(inputMessage);
    trackAnalytics(
      `${threadData.thread_type} thread: additional message`,
      {
        user_email: email,
        thread_id: chatId,
        message: inputMessage,
      },
      metadata
    );
    dispatch(setChatInputMessage(inpMessage));
    var message = createMessage(threadType, "add-message", { message: inputMessage, thread_id: chatId });
    sendMessage(message, true);
    dispatch(setMessagingDisabled(true));
  };

  const getCurrentLegalReviewStatus = () => {
    if (lawyerHubData) {
      var legalReview = lawyerHubData?.find((item) => item.thread_id === threadData.id);
      if (
        legalReview &&
        threadData.messages.find((item) => item.message_type === "legal_review_message") === undefined
      ) {
        dispatch(setLegalReviewRequested(true));
      }
      dispatch(setCurrentPageLegalReviewStatus(legalReview));
    }
  };

  useEffect(() => {
    // console.log(messageListRef.current, bottomRef.current, getThreadStatus)
    if (!messageListRef.current || !bottomRef.current || getThreadStatus !== "success") return;
    console.log("Setting observer");
    const observer = new IntersectionObserver(
      ([entry]) => {
        // Update visibility state
        setIsScrollToBottomButtonVisible(!entry.isIntersecting);
      },
      {
        root: messageListRef.current, // The scrollable container
        rootMargin: "0px 0px 100px 0px", // 100px from the bottom
        threshold: 0, // Trigger if even 1px is visible
      }
    );

    observer.observe(bottomRef.current);

    // Cleanup on component unmount
    return () => observer.disconnect();
  }, [getThreadStatus, messageListRef.current, bottomRef.current]);

  const countInputMessageWords = (s) => {
    return s.trim().split(/\s+/).length;
  };
  const getFirstTenkWords = (message) => {
    if (!message) return "";
    const words = message.split(/\s+/).slice(0, 10000).join(" ");
    return words;
  };

  return (
    <>
      {isLoaded ? (
        <BaseLayout>
          <div className="flex flex-col w-[100%]">
            <WordLimitPopup onProceed={onSumbitWithTenThousandWords} />
            <div className="flex flex-col justify-between h-screen">
              <TopNav page="chat"></TopNav>
              <div className="h-[57px] w-full flex md:hidden"></div>
              {getThreadStatus === "loading" && threadMessages.length === 0 && (
                <div className="w-full h-full flex flex-col justify-center items-center gap-[8px]">
                  <div className="w-8 h-8 border-2 border-t-0 border-[#000] rounded-full animate-spin"></div>
                  <p className="text-black">Fetching thread data...</p>
                </div>
              )}
              {getThreadStatus === "failed" && threadMessages.length === 0 && (
                <div className="w-full h-full flex flex-col justify-center items-center gap-[8px]">
                  {/* <div className="w-8 h-8 border-2 border-t-0 border-[#000] rounded-full animate-spin"></div> */}
                  <p className="text-center text-black">
                    Failed to fetch thread data. <br /> Please refresh the page and try again
                  </p>
                </div>
              )}

              {(getThreadStatus === "success" || threadMessages) && (
                <>
                  <div
                    className="py-[24px] mx-auto overflow-y-auto gap-[12px] justify-end flex-1  w-full"
                    id="scrolling_div"
                    ref={messageListRef}
                  >
                    {threadMessages.map((message, index) => (
                      <div className="px-3 md:px-0 md:w-[736px] mx-auto">
                        {renderMessage(message, index, threadData, "full", email, threadType, false, setSteps)}
                      </div>
                    ))}
                    <p role="bottom of chat" ref={bottomRef} className=""></p>
                    {(threadData?.document?.url ||
                      (!threadData?.document?.url &&
                        threadData?.document?.google_doc_id &&
                        !awaitingResponse &&
                        !threadData?.document?.isLocked)) && (
                      <div className="flex flex-col md:w-[736px] px-3 md:px-0 mx-auto">
                        <div className="flex flex-col md:w-[736px] px-3 md:px-0 mx-auto">
                          <a
                            href={
                              threadData?.document.url
                                ? threadData?.document?.url
                                : `https://docs.google.com/document/d/${threadData?.document?.google_doc_id}`
                            }
                            target="_blank"
                            rel="noreferrer"
                            className="cursor-pointer flex flex-row max-w-[425px] my-[12px] rounded-[8px] "
                          >
                            <div className="bg-[#FAFAFA] px-[20px] py-[16px] w-[72px] flex rounded-l-[8px] border rounded-r-none border-collapse">
                              <img src={DocIcon} width="32" alt="doc" className="my-auto !w-[32px]" />
                            </div>
                            <div className="flex-1 px-[20px] bg-white rounded-r-[8px] border gap-[8px] border-collapse border-l-0 py-[12px] pl-[24px] pr-[12px] rounded-l-none flex flex-col">
                              <div className="my-auto text-[16px] text-[#333333] font-[600]">
                                {threadData?.document?.google_doc_title || "View Document"}
                              </div>
                              {threadData?.document?.google_doc_title && (
                                <div className="my-auto text-[12px] text-[#18475A] font-[500] flex flex-row justify-between w-full">
                                  <span className="">View Document</span>
                                  <span className="">
                                    <ArrowUpRight size={16} />
                                  </span>
                                </div>
                              )}
                            </div>
                          </a>
                          {!currentPageLegalReviewStatus && (
                            <button
                              onClick={() => {
                                dispatch(setShowRequestLegalReviewAside(true));
                              }}
                              className="cursor-pointer flex flex-row w-fit p-[12px] my-[12px] text-[#FFF] rounded-[8px] border border-[#E0E0E0] bg-[#18475A] text-[14px] leading-[19.6px] font-[500] gap-[8px]"
                            >
                              <Briefcase size={16} className="text-white my-auto" />
                              <span className="my-auto">
                                {metadata?.lawyer_info?.name
                                  ? "Consult " + metadata?.lawyer_info?.name
                                  : "Request Legal Review"}
                              </span>
                            </button>
                          )}
                        </div>
                      </div>
                    )}
                    {threadData?.document?.isLocked && (
                      <UpgradeToUnlockDocument
                        threadData={threadData}
                        dispatch={dispatch}
                        metadata={metadata}
                        email={email}
                      />
                    )}
                    {awaitingResponse && !shimmerNotAllowed && (
                      <div className="mt-[12px] w-full md:w-[736px] px-3 md:px-0 mx-auto">
                        <>{threadType === "review" && <ReviewShimmer steps={steps} />}</>
                        <>{threadType === "ask" && <AskShimmer steps={steps} />}</>
                        <>{threadType === "draft" && <DraftShimmer steps={steps} />}</>
                      </div>
                    )}
                  </div>
                  <div className="relative flex flex-col w-full">
                    {isScrollToBottomButtonVisible && (
                      <div
                        onClick={() => bottomRef.current.scrollIntoView({ behavior: "smooth" })}
                        className="absolute px-1 py-1 text-gray-500 -translate-x-1/2 border !border-gray-400 rounded-full cursor-pointer -top-4 bg-white left-1/2"
                      >
                        <ArrowDown size={16} />
                      </div>
                    )}
                    <ChatBoxComponent
                      size="full"
                      inputMessage={inputMessage}
                      setInputMessage={setInputMessage}
                      onsubmit={onsubmit}
                      sentences={sentences?.length > 0 ? sentences : null}
                    />
                  </div>
                </>
              )}
            </div>
          </div>
          {showAskToDraftModal && <AskToDraftModal />}

          {draftDocumentModalOpen && <DraftDocumentQuestionaireModal email={email} ref={chatpageRef} />}
          {showRequestLegalReviewAside && <LegalReviewRequestAside />}
          {(showSubscriptionPopup ||
            showUpgradingPlan ||
            showBusinessPlanUnlocked ||
            showFindBestLawyer ||
            showMeetingConfirmation ||
            showNextSteps ||
            showScheduleMeeting) && <UpSellFlowPopup />}
        </BaseLayout>
      ) : (
        <>
          {isLoaded ? (
            <div className="flex items-center justify-center w-full h-full">
              <p className="text-red-500">Failed to load user details. Please refresh the page.</p>
            </div>
          ) : (
            <div className="w-full h-full flex justify-center items-center mx-auto mt-[200px]">
              {/* <div className="spinner-grow spinner-grow-sm text-primary me-3" role="status"></div> */}
              {/* <div class="rotating-cursor !h-[50px]"></div> */}
              <p className="text-black text-[40px]">{isLoaded}</p>
            </div>
          )}
        </>
      )}
      <SavedDataModalComponent />
    </>
  );
};

export default ChatPageComponent;
