import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { ArrowRight, ArrowUpRight, CaretDoubleRight, Info, VideoCamera, X } from "@phosphor-icons/react";

import {
  requestLegalReview,
  resetRequestLegalReviewFailureReason,
  setShowLearnMoreModal,
} from "src/pages/onboarding/onboardingSlice";
import ReliabilityGuaranteedPopup from "../ReliabilityGuaranteedPopup";
import { PLANS, ReviewStatus } from "src/components/Constants";
import emailLogo from "src/images/emailLogo.svg";
import { Check } from "@phosphor-icons/react";
import legalReiviewImg from "src/images/review-request-img.svg";
import { trackAnalytics } from "src/pages/helpers";
import { setShowRequestLegalReviewAside, setShowScheduleMeeting } from "src/pages/onboarding/onboardingSlice";
import ScheduleCallUnAssignedLawyerAside from "./ScheduleCallUnAssignedLawyerAside";
import RequestReviewFormAside from "./RequestReviewFormAside";
import ReviewRequestedSuccessMessage from "./ReviewRequestedSuccessMessage";
import { useLocation } from "react-router-dom";
import LegalReviewCompleteAsidePersonal from "./Personal/LegalReviewCompleteAsidePersonal";
import LegalReviewRequestedSuccessfully from "./LegalReviewRequestedSuccessfully";
import LegalReviewRequestPersonal from "./Personal";
import LegalReviewRequestBusiness from "./Business";
import LegalReviewAsideFooter from "./Footer";
import Modal from "antd/es/modal/Modal";
const LegalReviewRequestAside = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const metadata = useSelector((state) => state.onboarding.userMetadata);

  const showRequestLegalReviewAside = useSelector((state) => state.onboarding.showRequestLegalReviewAside);
  // const showReviewRequestedModal = useSelector((state) => state.onboarding.showReviewRequestedModal);
  const requestLegalReviewStatus = useSelector((state) => state.onboarding.requestLegalReviewStatus);
  const threadData = useSelector((state) => state.messageSlice.threadData);
  const requestLegalReviewFailureReason = useSelector((state) => state.onboarding.requestLegalReviewFailureReason);
  const showLearnMoreModal = useSelector((state) => state.onboarding.showLearnMoreModal);
  const lawyerHubData = useSelector((state) => state.onboarding.lawyerHubData);
  const legalReview = lawyerHubData?.find((item) => item.thread_id === threadData?.id);

  const email = useSelector((state) => state.homeslice.email);
  const [ShowScheduleCall, setShowScheduleCall] = useState(false);
  const [text, setText] = useState("");
  const [loading, setLoading] = useState(false);
  const [notice, setNotice] = useState({});
  const [phoneNumber, setPhoneNumber] = useState("");
  const [phoneNumberValid, setPhoneNumberValid] = useState(false);
  const [footer, setFooter] = useState();
  const [absoluteRelativeRequired, setAbsoluteRelativeRequired] = useState(false);

  useEffect(() => {
    if (phoneNumber.length === 10 && !isNaN(phoneNumber) && ["0", "-", "+"].indexOf(phoneNumber[0]) === -1) {
      setPhoneNumberValid(true);
    } else {
      setPhoneNumberValid(false);
    }
  }, [phoneNumber]);

  useEffect(() => {
    setLoading(false);
    setNotice({});
    setText("");
    dispatch(resetRequestLegalReviewFailureReason());
  }, [showRequestLegalReviewAside]); // eslint-disable-line

  const submitRequest = () => {
    var err = {};
    if (window.location.pathname === "/home" && !phoneNumberValid) {
      err["phoneNumber"] = "Please enter a valid phone number";
    }
    if (window.location.pathname !== "/home" && !phoneNumberValid && !metadata?.lawyer_info) {
      err["phoneNumber"] = "Please enter a valid phone number";
    }
    if (window.location.pathname === "/home" && text.length === 0) {
      err["description"] = "Please enter a description";
    }
    if (Object.keys(err).length > 0) {
      setNotice(err);
      return;
    }
    setLoading(true);
    setNotice({});
    dispatch(
      requestLegalReview({
        threadId: threadData?.id,
        description: text,
        phone_number: phoneNumber,
        communication_preference: "phone",
      })
    );
    if (metadata.subscription_type === "subscriber_business") {
      if (metadata.legal_review_credits_used >= metadata.legal_review_credits_total) {
        trackAnalytics("request legal review clicked", {
          user_email: email,
          subscription_type: metadata.subscription_type,
          legal_review_credits_total: metadata.legal_review_credits_total,
          legal_review_credits_used: metadata.legal_review_credits_used,
        });
      } else if (metadata.legal_review_credits_total > metadata.legal_review_credits_used) {
        // set text that one legal credit will be used
        trackAnalytics("request legal review clicked", {
          user_email: email,
          subscription_type: metadata.subscription_type,
        });
      }
    } else if (metadata.lawyer_info === null) {
      // show find lawyer popup
      trackAnalytics("request legal review clicked", {
        user_email: email,
        subscription_type: metadata.subscription_type,
        lawyer_assigned: "not yet",
      });
    } else if (metadata.lawyer_info) {
      if (metadata.legal_review_credits_used >= metadata.legal_review_credits_total) {
        // show popup to upgrade plan. user is out of credits
        // also text should change
        trackAnalytics("request legal review clicked", {
          user_email: email,
          subscription_type: metadata.subscription_type,
          legal_review_credits_total: metadata.legal_review_credits_total,
          legal_review_credits_used: metadata.legal_review_credits_used,
        });
      } else if (metadata.legal_review_credits_total > metadata.legal_review_credits_used) {
        // set text that one legal credit will be used
        trackAnalytics("request legal review clicked", {
          user_email: email,
          subscription_type: metadata.subscription_type,
          lawyer_assigned: "yes",
        });
      }
    }
  };

  useEffect(() => {
    setNotice({});
  }, []);

  useEffect(() => {
    if (requestLegalReviewStatus === "failed") {
      setLoading(false);
      if (!requestLegalReviewFailureReason) {
        setNotice({ error: "Failed to request review. Please try again later." });
      } else {
        setNotice({ error: requestLegalReviewFailureReason.detail });
      }
    }
    if (requestLegalReviewStatus === "success") {
      setLoading(false);
    }
  }, [requestLegalReviewStatus, requestLegalReviewFailureReason]);

  useEffect(() => {
    if ((!legalReview && requestLegalReviewStatus !== "success") || requestLegalReviewFailureReason) {
      setAbsoluteRelativeRequired(true);
    } else if (requestLegalReviewStatus === "success") {
      setAbsoluteRelativeRequired(false);
    } else if (window.location.pathname === "/home") {
      setAbsoluteRelativeRequired(true);
    }

    // setFooter(generateFooter())
  }, [requestLegalReviewStatus, metadata, lawyerHubData, threadData]);

  return (
    <>
      {showRequestLegalReviewAside && (
        <div className="fixed inset-0 z-[1100] bg-[rgba(0,0,0,0.5)]">
          <aside className="h-[90%] md:h-screen top-[10%] md:top-0 w-full md:w-[572px] flex flex-col overflow-y-scroll bg-white border-l border-l-[#E4E4E4] md:border-0 shadow-xl absolute z-[1200] right-0 md:rounded-t-[0px] rounded-t-[12px]">
            <div
              className={`flex flex-col justify-between flex-1 items-center ${
                absoluteRelativeRequired ? "relative" : ""
              }`}
            >
              <div
                className={`flex justify-end w-full top-0 right-0 z-10 p-6 ${
                  absoluteRelativeRequired ? "absolute" : ""
                }`}
              >
                <button
                  className="flex items-center my-auto text-sm cursor-pointer font-semibold text-[#353535]"
                  onClick={() => {
                    dispatch(setShowRequestLegalReviewAside(false));
                    setShowScheduleCall(false);
                  }}
                >
                  <span className="hidden md:flex justify-center items-center">
                    {location.pathname === "/home" ? (
                      <>
                        Close <X className="ml-2" size={16} />
                      </>
                    ) : (
                      <>
                        Hide <CaretDoubleRight className="ml-2" size={16} />{" "}
                      </>
                    )}
                  </span>
                  <span className="md:hidden">
                    <X className="ml-2" size={16} />
                  </span>
                </button>
              </div>

              {metadata?.subscription_type === PLANS.PERSONAL_FREE ? (
                <LegalReviewRequestPersonal
                  email={email}
                  trackAnalytics={trackAnalytics}
                  dispatch={dispatch}
                  metadata={metadata}
                  legalReiviewImg={legalReiviewImg}
                  requestLegalReviewStatus={requestLegalReviewStatus}
                  text={text}
                  notice={notice}
                  setText={setText}
                  loading={loading}
                  setShowLearnMoreModal={setShowLearnMoreModal}
                  phoneNumber={phoneNumber}
                  setPhoneNumber={setPhoneNumber}
                  legalReview={legalReview}
                />
              ) : (
                <>
                  <LegalReviewRequestBusiness
                    email={email}
                    trackAnalytics={trackAnalytics}
                    dispatch={dispatch}
                    metadata={metadata}
                    notice={notice}
                    legalReiviewImg={legalReiviewImg}
                    requestLegalReviewStatus={requestLegalReviewStatus}
                    text={text}
                    setText={setText}
                    loading={loading}
                    setShowLearnMoreModal={setShowLearnMoreModal}
                    phoneNumber={phoneNumber}
                    setPhoneNumber={setPhoneNumber}
                    legalReview={legalReview}
                  />
                </>
              )}
              <div className="max-h-[120px] w-full flex justify-center items-center">
                <LegalReviewAsideFooter
                  metadata={metadata}
                  legalReview={legalReview}
                  requestLegalReviewStatus={requestLegalReviewStatus}
                  loading={loading}
                  submitRequest={submitRequest}
                  dispatch={dispatch}
                  setShowRequestLegalReviewAside={setShowRequestLegalReviewAside}
                  phoneNumberValid={phoneNumberValid}
                  text={text}
                />
              </div>
            </div>
          </aside>
        </div>
      )}
      {showLearnMoreModal && <ReliabilityGuaranteedPopup />}
    </>
  );
};

export default LegalReviewRequestAside;
