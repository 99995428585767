import { ArrowRight, Check, Info, VideoCamera } from "@phosphor-icons/react";
import React from "react";
import { setShowRequestLegalReviewAside, setShowScheduleMeeting } from "src/pages/onboarding/onboardingSlice";
import { useLocation } from "react-router-dom";
import { PLANS } from "src/components/Constants";
const LegalReviewRequestedSuccessfully = (props) => {
  const { email, dispatch, metadata } = props;
  const location = useLocation();
  const scheduleCall = () => {
    dispatch(setShowScheduleMeeting({ show: true, lawyer_details: {} }));
    dispatch(setShowRequestLegalReviewAside(false));
  };
  const getSuccessTitle = () => {
    if (location.pathname !== "/home") {
      return "Review Requested!";
    } else {
      return "Request sent successfully!";
    }
  };

  const getMessage = () => {
    if (!metadata?.lawyer_info) {
      var line1;
      var line2 = (
        <p className="text-[12px] md:text-[14px]">
          An e-mail has been sent on <b>{email}</b> to keep you updated on the request status.
        </p>
      );

      if (location.pathname === "/home") {
        line1 = (
          <p className="text-[12px] md:text-[14px]">
            We will connect you with a top-rated lawyer for <br />
            guidance on your specific legal needs soon.{" "}
          </p>
        );
      } else {
        line1 = (
          <p className="text-[12px] md:text-[14px]">
            {" "}
            We will connect you with a top-rated <br />
            lawyer to address your review request.
          </p>
        );
      }
      return (
        <>
          {line1}
          {line2}
        </>
      );
    } else {
      return (
        <>
          <p className="text-[12px] md:text-[14px]">
            Your request has been sent to <b>{metadata?.lawyer_info?.email}</b>, and your email <b>{email}</b> has been
            included in the email thread
          </p>
          <p className="font-medium text-[14px] font-sans text-[#353535]">
            You can also schedule a call with them to discuss your request.
          </p>
        </>
      );
    }
  };

  return (
    <>
      <div className="px-[53px] flex flex-col items-center gap-[24px] text-center">
        <div className="w-[53px] h-[53px] mb-2j">
          <Check
            size={24}
            weight="bold"
            className="border-2 rounded-full w-[56px] h-[56px] p-2 font-bold bg-[#137513] text-white"
          />
        </div>
        <>
          <h6 className="font-sans font-semibold text-[24px] text-center text-[#353535]">{getSuccessTitle()}</h6>
          {/* {location.pathname !== '/home' ?
                        <h6 className='font-sans font-semibold text-[24px] text-center text-[#353535]'>Review Requested!</h6> :
                        <h6 className='font-sans font-semibold text-[24px] text-center text-[#353535]'>Request sent successfully!</h6>
                    } */}

          {getMessage()}
          {metadata?.subscription_type !== PLANS.PERSONAL_FREE && (
            <>
              {!metadata?.lawyer_matching_meeting_at && !metadata.lawyer_info && (
                <button
                  className="flex items-center gap-[8px] px-[24px] py-[12px] rounded-[8px] bg-[#18475A] text-white"
                  onClick={scheduleCall}
                >
                  Schedule a Call <VideoCamera size={20} />
                </button>
              )}
              {!metadata?.lawyer_info && (
                <p
                  className="text-[#353535] font-sans text-[14px] underline font-medium cursor-pointer"
                  onClick={() => {
                    dispatch(setShowRequestLegalReviewAside(false));
                  }}
                >
                  Close
                </p>
              )}
            </>
          )}
        </>
      </div>
    </>
  );
};

export default LegalReviewRequestedSuccessfully;
