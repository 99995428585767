import React, { forwardRef, useEffect, useRef } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useUser } from "@clerk/clerk-react";
import BaseLayout from "../layouts/BaseLayout";
import useValidatePageAccess from "../../hooks/useValidatePageAccess";
import TopNav from "./Navs/TopNav";
import ReviewShimmer from "../../components/Shimmers/ReviewShimmer";
import DraftShimmer from "../../components/Shimmers/DraftShimmer";
import AskShimmer from "../../components/Shimmers/AskShimmer";
import { useDispatch, useSelector } from "react-redux";
import { renderMessage } from "../../components/DocumentViewer/helpers";
import SavedDataModalComponent from "../../components/Prompts/SavedDataModalComponent";
import DocIcon from "src/images/doc-icon.png";

import { setEmail } from "../Homepage/HomeSlice";
import { getSharedMessages, resetThreadData, setThreadTitle } from "./messageSlice";
import { resetChatPage } from "./chatSlice";
import { resetData } from "./threadsSlice";
import AskToDraftModal from "../../components/chat/Modals/AskToDraftModal";
import DraftDocumentQuestionaireModal from "./Modals/DraftDocumentQuestionaireModal";
import LegalReviewRequestAside from "../LawyerHub/LegalReviewRequestAside/index.js";
import UpSellFlowPopup from "../LawyerHub/UpsellFlowPopup";
import { ArrowUpRight, ShareFat } from "@phosphor-icons/react";
import { trackAnalytics } from "../helpers";

const Shared = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  let { threadType, chatId } = useParams();

  const chatpageRef = useRef(null);
  const messageListRef = useRef(null);

  const getThreadStatus = useSelector((state) => state.messageSlice.getThreadStatus);
  const awaitingResponse = useSelector((state) => state.messageSlice.awaitingResponse);
  const threads = useSelector((state) => state.threads.threads);
  const threadData = useSelector((state) => state.messageSlice.threadData);
  const email = useSelector((state) => state.homeslice.email);
  const showAskToDraftModal = useSelector((state) => state.messageSlice.showAskToDraftModal);
  const draftDocumentModalOpen = useSelector((state) => state.modalSlice.draftDocumentModalOpen);
  const showRequestLegalReviewAside = useSelector((state) => state.onboarding.showRequestLegalReviewAside);

  const showSubscriptionPopup = useSelector((state) => state.onboarding.showSubscriptionPopup);
  const showUpgradingPlan = useSelector((state) => state.onboarding.showUpgradingPlan);
  const showBusinessPlanUnlocked = useSelector((state) => state.onboarding.showBusinessPlanUnlocked);
  const showFindBestLawyer = useSelector((state) => state.onboarding.showFindBestLawyer);
  const showMeetingConfirmation = useSelector((state) => state.onboarding.showMeetingConfirmation);
  const showNextSteps = useSelector((state) => state.onboarding.showNextSteps);
  const showScheduleMeeting = useSelector((state) => state.onboarding.showScheduleMeeting);
  const userMetadata = useSelector((state) => state.onboarding.userMetadata);
  // const scroll = useSelector((state) => state.messageSlice.scroll);

  useEffect(() => {
    dispatch(resetChatPage());
    dispatch(resetData());
    dispatch(resetThreadData());
    dispatch(getSharedMessages(chatId));
    // dispatch(getThread(chatId));
  }, [chatId]); // eslint-disable-line

  useEffect(() => {
    if (getThreadStatus === "success") {
      var results = [];
      Object.keys(threads).forEach((key) => {
        const filtered = threads[key].filter((item) => {
          return item.id === chatId;
        });
        if (filtered.length > 0) {
          results = results.concat(filtered);
        }
      });
      if (results.length > 0 && threadData?.isSharedThread !== true) {
        dispatch(setThreadTitle(results[0].title));
      }
    }
  }, [getThreadStatus, threads]); // eslint-disable-line

  const scrollContainerToBottom = (messageListRef) => {
    if (messageListRef.current) {
      messageListRef.current.scrollTop = messageListRef.current.scrollHeight;
    }
  };

  useEffect(() => {
    // scrollContainerToBottom(messageListRef);
  }, [awaitingResponse, threadData.messages]); // eslint-disable-line

  return (
    <>
      <BaseLayout>
        <div className="flex flex-col w-[100%]">
          <div className="flex justify-between flex-col h-screen">
            <TopNav page="chat"></TopNav>
            {/* <p>{JSON.stringify(threadData.messages)}</p> */}
            {getThreadStatus === "success" && (
              <>
                <div
                  className="w-[736px] py-[24px] mx-auto overflow-y-auto gap-[12px] justify-end flex-1"
                  id="scrolling_div"
                  ref={messageListRef}
                >
                  {threadData.messages.map((message, index) => (
                    <div className="">{renderMessage(message, index, threadData, "full", email, threadType, true)}</div>
                  ))}
                  {(threadData?.document?.url || threadData?.document?.google_doc_id) && (
                    <a
                      href={
                        threadData?.document.url
                          ? threadData?.document?.url
                          : `https://docs.google.com/document/d/${threadData?.document?.google_doc_id}`
                      }
                      onClick={trackAnalytics(`${threadData.thread_type} thread: view document on shared thread`)}
                      target="_blank"
                      rel="noreferrer"
                      className="cursor-pointer flex flex-row max-w-[425px] my-[12px] rounded-[8px] "
                    >
                      <div className="bg-[#FAFAFA] px-[20px] py-[16px] w-[72px] flex rounded-l-[8px] border rounded-r-none border-collapse">
                        <img src={DocIcon} width="32" alt="doc" className="my-auto !w-[32px]" />
                      </div>
                      <div className="flex-1 px-[20px] bg-white rounded-r-[8px] border gap-[8px] border-collapse border-l-0 py-[12px] pl-[24px] pr-[12px] rounded-l-none flex flex-col">
                        <div className="my-auto text-[16px] text-[#333333] font-[600]">
                          {threadData?.document?.google_doc_title || "View Document"}
                        </div>
                        {threadData?.document?.google_doc_title && (
                          <div className="my-auto text-[12px] text-[#18475A] font-[500] flex flex-row justify-between w-full">
                            <span className="">View Document</span>
                            <span className="">
                              <ArrowUpRight size={16} />
                            </span>
                          </div>
                        )}
                      </div>
                    </a>

                    // <a href={threadData?.document?.url} target="_blank" rel="noreferrer"
                    //   className="cursor-pointer flex flex-row w-fit p-[12px] mt-[20px] text-[#FFF] rounded-[8px] border border-[#E0E0E0] bg-[#18475A] text-[14px] leading-[19.6px] font-[500] gap-[8px]"
                    //   onClick={trackAnalytics(`${threadData.thread_type} thread: view document on shared thread`, {
                    //     "user_email": email, "thread_id": chatId
                    //   }, userMetadata)}
                    // >
                    //   <ShareFat size={16} className="text-white my-auto" />
                    //   <span className="my-auto">View Document</span>
                    // </a>
                  )}

                  {awaitingResponse && (
                    <div className="mt-[12px]">
                      <>{threadType === "review" && <ReviewShimmer />}</>
                      <>{threadType === "ask" && <AskShimmer />}</>
                      <>{threadType === "draft" && <DraftShimmer />}</>
                    </div>
                  )}
                </div>
              </>
            )}
            {getThreadStatus === "loading" && (
              <div className="w-full h-full flex flex-col justify-center items-center gap-[8px]">
                <div className="w-8 h-8 border-2 border-t-0 border-[#000] rounded-full animate-spin"></div>
                <p className="text-black">Fetching thread data...</p>
              </div>
            )}
            {getThreadStatus === "failed" && (
              <div className="w-full h-full flex flex-col justify-center items-center gap-[8px]">
                {/* <div className="w-8 h-8 border-2 border-t-0 border-[#000] rounded-full animate-spin"></div> */}
                <p className="text-black text-center">
                  Failed to fetch thread data. <br /> Please refresh the page and try again
                </p>
              </div>
            )}
          </div>
        </div>
        {showAskToDraftModal && <AskToDraftModal />}

        {draftDocumentModalOpen && <DraftDocumentQuestionaireModal email={email} ref={chatpageRef} />}
        {showRequestLegalReviewAside && <LegalReviewRequestAside />}
        {(showSubscriptionPopup ||
          showUpgradingPlan ||
          showBusinessPlanUnlocked ||
          showFindBestLawyer ||
          showMeetingConfirmation ||
          showNextSteps ||
          showScheduleMeeting) && <UpSellFlowPopup />}
      </BaseLayout>

      <SavedDataModalComponent />
    </>
  );
};

export default Shared;
