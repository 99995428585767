import React, { useEffect, useRef, useState } from "react";

import { useSelector, useDispatch } from "react-redux";

import { useUser } from "@clerk/clerk-react";
import { useNavigate } from "react-router-dom";
import BaseLayout from "../layouts/BaseLayout.js";

import FourLawyersImage from "src/images/4-lawyer.svg";
// import { toast } from 'react-toastify';

import TopNav from "../chatpage/Navs/TopNav.js";

import { setDraftModalDraftType, setShowExploreMoreModal } from "../../modalSlice.js";
import {
  ArrowRight,
  Briefcase,
  ChatsCircle,
  City,
  FileMagnifyingGlass,
  Handshake,
  PenNib,
  Receipt,
  Wallet,
} from "@phosphor-icons/react";
import { getEducationMetadata } from "./HomeSlice.js";
import ExploreMoreModalComponent from "../../components/chat/Modals/ExploreMoreModal/index.js";
// import { getCheckoutUrl } from "../chatpage/chatSlice.js";
import { setShowRequestLegalReviewAside, setShowUpgradePlanNudge } from "../onboarding/onboardingSlice.js";
import { openPopup, openSmallScreenModal } from "src/components/DocumentViewer/helpers.js";
import { trackAnalytics } from "../helpers.js";
import { PLANS } from "src/components/Constants.js";
import {
  getPresignedUrl,
  resetFileUploadProgress,
  resetRedirectTo,
  resetThreadData,
  setAskToDraftIntentWSResponse,
  setMessagingDisabled,
  setNewThreadMessage,
  updateUploadedFileName,
  uploadFile,
} from "../chatpage/messageSlice.js";
import LegalReviewRequestAside from "../LawyerHub/LegalReviewRequestAside/index.js";
import ChatBoxComponent from "../chatpage/components/ChatBoxComponent.js";
import { useWebSocketContext } from "src/WebsocketProvider.js";
import { toast } from "react-toastify";

const Homepage = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const queryParams = new URLSearchParams(window.location.search);
  const open_draft = queryParams.get("open_draft");
  const open_membership = queryParams.get("open_membership");
  const [selectedCategory, setSelectedCategory] = useState("");
  const [suggestions, setSuggestions] = useState([]);
  const [categories, setCategories] = useState([]);
  const [smallScreen, setSmallScreen] = useState(false);
  const [inputMessage, setInputMessage] = useState("");
  const [referenceDoc, setReferenceDoc] = useState(null);

  const { isLoaded, user } = useUser();
  const { sendMessage, createMessage } = useWebSocketContext();
  const { isUploading, fileuploadError } = useSelector((state) => state.messageSlice);
  const [referenceDocId, setReferenceDocId] = useState(null);
  const [uploadPercentage, setUploadPercentage] = useState(0);

  const getUserDetailsStatus = useSelector((state) => state.auth.getUserDetailsStatus);
  const email = useSelector((state) => state.homeslice.email);
  const sidenavCollapsed = useSelector((state) => state.homeslice.sidenavCollapsed);
  const allSuggestions = useSelector((state) => state.homeslice.allSuggestions);
  const draftTypeSuggestions = useSelector((state) => state.homeslice.draftTypes);
  const userMetadata = useSelector((state) => state.onboarding.userMetadata);
  const educationMetadataFetchStatus = useSelector((state) => state.homeslice.educationMetadataFetchStatus);
  const threadData = useSelector((state) => state.messageSlice.threadData);
  const redirectTo = useSelector((state) => state.messageSlice.redirectTo);
  const presignedUrl = useSelector((state) => state.messageSlice.presignedUrl);
  const userConfig = useSelector((state) => state.onboarding.userConfig);

  useEffect(() => {
    dispatch(resetThreadData());
    dispatch(setMessagingDisabled(false));
  }, []);

  useEffect(() => {
    if (window.innerWidth < 768) {
      setSmallScreen(true);
    }
  }, []); // eslint-disable-line

  useEffect(() => {
    if (allSuggestions && allSuggestions.categories?.length > 0) {
      var cats = allSuggestions.categories;
      setCategories(cats);
      setSelectedCategory(cats[0]?.id);
    }
  }, [allSuggestions]); // eslint-disable-line

  useEffect(() => {
    if (open_membership) {
      dispatch(setShowUpgradePlanNudge(true));
    }
  }, [open_membership]);

  useEffect(() => {
    getSelectedCategorySuggestions();
  }, [selectedCategory]); // eslint-disable-line

  useEffect(() => {
    getSelectedCategorySuggestions();
    trackAnalytics("dashboard_home_viewed", { user_email: email }, userMetadata);
  }, []); // eslint-disable-line

  useEffect(() => {
    if (open_draft) {
      openDraftDocumentModal("homepage");
      navigate("/home");
    }
  }, [open_draft]); // eslint-disable-line

  useEffect(() => {
    if (educationMetadataFetchStatus === "failed") {
      dispatch(getEducationMetadata());
    }
  }, [educationMetadataFetchStatus]); // eslint-disable-line

  useEffect(() => {
    if (fileuploadError) {
      toast.error(`File Upload failed. Please try again.`);
      setUploadPercentage(0);
      setReferenceDocId(null);
      setReferenceDoc(null);
    }
  }, [fileuploadError]); // eslint-disable-line
  useEffect(() => {
    if (uploadPercentage === 100) {
      setReferenceDocId(presignedUrl?.storage_id);
    }
  }, [uploadPercentage]); // eslint-disable-line

  useEffect(() => {
    if (redirectTo) {
      var redirectUrl = redirectTo;
      dispatch(resetRedirectTo(null));
      navigate(redirectUrl);
    }
  }, [redirectTo]); // eslint-disable-line

  useEffect(() => {
    if (referenceDoc && presignedUrl) {
      var filename = referenceDoc.name;
      dispatch(updateUploadedFileName({ filename: filename, storage_id: presignedUrl?.storage_id }));
      trackAnalytics("ask thread: reference doc uploaded", { user_email: email, filename: filename }, userMetadata);
      // window.analytics.track("ask thread: reference doc uploaded", { "user_email": email, "filename": filename });
      dispatch(
        uploadFile({
          file: referenceDoc,
          data: presignedUrl.upload_fields,
          url: presignedUrl.url,
          onUploadProgress: (progress) => {
            setUploadPercentage(progress);
          },
        })
      );
    }
  }, [presignedUrl]); // eslint-disable-line

  const openDraftDocumentModal = (source, draft) => {
    trackAnalytics(`Draft document from ${source}`, { user_email: email }, userMetadata);
    // window.analytics.track(`Draft document from ${source}`, { "user_email": email });
    dispatch(setDraftModalDraftType(draft));
  };

  const getSelectedCategorySuggestions = () => {
    var allExamples = [];
    var category = selectedCategory;
    if (!category && allSuggestions.categories && allSuggestions.categories.length > 0) {
      category = allSuggestions.categories?.find((category) => category.name === "IP")?.id;
      setSelectedCategory(category);
    }
    if (category && allSuggestions.categories && allSuggestions.categories.length > 0) {
      ["ask", "draft"].forEach((type) => {
        var st = allSuggestions[type + "_examples"].map((value) => ({ ...value, sort: Math.random(), type: type }));
        // var examples = allSuggestions[type + "_examples"].filter(suggestion => suggestion.type === type);
        allExamples = allExamples.concat(st.filter((suggestion) => suggestion.category_id === category));
      });

      var newExamples = allExamples.sort((a, b) => a.sort - b.sort).map((value) => value);
      setSuggestions(newExamples.slice(0, 5));
    }
  };

  const exploreAllClicked = () => {
    trackAnalytics("Explore all clicked (homepage)", { user_email: email }, userMetadata);
    // window.analytics.track("Explore all clicked (homepage)", { "user_email": email });
    dispatch(setShowExploreMoreModal(true));
  };

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    if (!file) {
      // toast.error("No file selected.");
      return;
    }
    const fileExtension = file.name.split(".").pop().toLowerCase();

    if (["docx", "pdf"].includes(fileExtension)) {
      console.log("Valid file selected:", file);
      dispatch(getPresignedUrl());
      dispatch(resetFileUploadProgress());
      setUploadPercentage(0);
      setReferenceDoc(file);
    } else {
      toast.error("Invalid file format. Only docx and pdf are allowed.");
      setReferenceDoc(null);
    }
  };

  const getCircle = (type) => {
    switch (type) {
      case "ask":
        return (
          <div>
            <ChatsCircle size={16} className="text-[#C61DCA]" />
          </div>
        );

      case "draft":
        return (
          <div>
            <PenNib size={16} className="text-[#13866F]" />
          </div>
        );
      case "review":
        return (
          <div>
            <FileMagnifyingGlass size={16} className="text-[#949494]" />
          </div>
        );
      default:
        return "#C61DCA";
    }
  };
  const getIcon = (type, bold = false) => {
    var realType = `${type}${bold ? "-bold" : ""}`;
    switch (realType) {
      case "ask":
        return <ChatsCircle size={16} className="text-[#C61DCA]" />;

      case "draft":
        return <PenNib size={16} className="text-[#13866F]" />;
      case "review":
        return <FileMagnifyingGlass size={16} className="text-[#E79571]" />;
      case "briefcase":
        return <Briefcase size={20} className="text-[#686868]" />;

      case "briefcase-bold":
        return <Briefcase size={20} className="text-[#686868]" weight="bold" />;

      case "city":
        return <City size={20} className="text-[#686868]" />;
      case "city-bold":
        return <City size={20} className="text-[#686868]" weight="bold" />;
      case "wallet":
        return <Wallet size={20} className="text-[#686868]" />;
      case "wallet-bold":
        return <Wallet size={20} className="text-[#686868]" weight="bold" />;
      case "handshake":
        return <Handshake size={20} className="text-[#686868]" />;
      case "handshake-bold":
        return <Handshake size={20} className="text-[#686868]" weight="bold" />;
      case "receipt":
        return <Receipt size={20} className="text-[#686868]" />;
      case "receipt-bold":
        return <Receipt size={20} className="text-[#686868]" weight="bold" />;
      default:
        return "#C61DCA";
    }
  };

  const onsubmit = () => {
    trackAnalytics(
      "new thread from homepage. sending first message",
      { user_email: email, message: inputMessage },
      userMetadata
    );
    trackAnalytics(
      "dashboard_home_send_chat_button_clicked",
      { user_email: email, message: inputMessage },
      userMetadata
    );
    // window.analytics.track("new ask thread. sending first message", { "user_email": email, "message": inputMessage })
    if (isUploading) {
      toast.error(`File Upload is in progress.`);
    }
    dispatch(setNewThreadMessage(inputMessage));
    var docId = referenceDocId;
    if (uploadPercentage === 100 && referenceDoc) {
      docId = referenceDocId;
    } else {
      docId = null;
    }
    var message = createMessage("ask", "create-thread", { message: inputMessage, reference_doc_storage_id: docId });
    var response = sendMessage(message, true);
    if (!response) {
      dispatch(setMessagingDisabled(true));
    }
  };

  const openAskPage = (message) => {
    dispatch(resetThreadData());
    navigate("/ask", { state: { message: message } });
  };
  const goToSomePlace = (suggestion) => {
    if (userMetadata?.subscription_type !== PLANS.SUBSCRIBER_ENTERPRISE) {
      if (userMetadata?.threads_remaining === 0) {
        if (userMetadata?.subscription_type === PLANS.PERSONAL_FREE) {
          openPopup(dispatch, "personal_free_limits_exhausted");
        } else {
          openPopup(dispatch, "upgrade_plan");
        }
        return;
      }
    }
    dispatch(setAskToDraftIntentWSResponse({}));
    switch (suggestion.type) {
      case "ask":
        openAskPage(suggestion?.actual_prompt);
        trackAnalytics("dashboard_home_get_advice", { user_email: email }, userMetadata);
        break;
      case "draft":
        trackAnalytics("dashboard_home_draft_new", { user_email: email, small_screen: smallScreen }, userMetadata);
        // if (smallScreen) {
        //     openSmallScreenModal(dispatch)
        //     return
        // }
        var draft = draftTypeSuggestions?.find((draft) => draft.id === suggestion?.draft_metadata_id);
        if (draft) {
          openDraftDocumentModal("home page tiles", draft);
        } else {
          openDraftDocumentModal("home page tiles", suggestion?.display_prompt);
        }
        break;
      case "review":
        openDraftDocumentModal();
        break;
      default:
        break;
    }
  };

  const retryEducationMetaFetch = () => {
    trackAnalytics("Retry education meta fetch", { user_email: email }, userMetadata);
    // window.analytics.track("Retry education meta fetch", { "user_email": email });
    dispatch(getEducationMetadata());
  };

  return (
    <>
      <LegalReviewRequestAside />
      {isLoaded ? (
        <BaseLayout>
          <div className="flex flex-col w-full h-screen overflow-y-scroll pt-[50px] md:pt-0">
            <div
              className={`flex flex-col w-full h-auto md:h-[60%] mx-auto pb-[32px] ${
                sidenavCollapsed ? " md:max-w-[1376px] " : "md:max-w-[1256px]"
              }`}
            >
              <TopNav page="home"></TopNav>
              <div
                className={`w-full mx-auto gap-[24px] flex flex-col px-3 ${
                  sidenavCollapsed ? "md:px-[60px]" : "md:px-[20px]"
                }`}
              >
                <div
                  className={`${
                    sidenavCollapsed ? "md:w-[1248px]" : " md:w-[1134px]"
                  } w-full mx-auto gap-[8px] flex flex-col mt-5`}
                >
                  <h1 className="text-[#686868] text-center text-[14px] md:text-[20px] leading-[28px] font-[500]">
                    Welcome{user?.fullName ? ` ${user.firstName},` : ","}
                  </h1>
                  <p className="text-xl text-center md:text-[32px] md:leading-[44.8px] font-[Lora] italic font-[700] text-[#18475A]">
                    How can we help you today?
                  </p>
                </div>
                <div
                  className={`flex md:flex-col flex-col  justify-between mx-auto w-full ${
                    sidenavCollapsed ? "md:w-[1248px]" : " md:w-[1134px]"
                  }`}
                >
                  <ChatBoxComponent
                    size="full"
                    allowAttachReference={true}
                    inputMessage={inputMessage}
                    setInputMessage={setInputMessage}
                    onsubmit={onsubmit}
                    referenceDoc={referenceDoc}
                    handleFileChange={handleFileChange}
                    sentences={["Ask me any legal questions here – feel free to attach a reference if it helps"]}
                    percentage={uploadPercentage}
                    setReferenceDoc={setReferenceDoc}
                    fileuploadSuccess={uploadPercentage}
                  />
                  {userConfig?.homepage?.talktolawyerbanner?.showBanner && (
                    <>
                      <div
                        className="cursor-pointer w-full hidden md:flex flex-row max-w-[816px] md:h-[114px] mx-auto justify-between rounded-[12px] gap-[24px] 
                        talk-to-lawyer-banner
                       pl-[34px] border-[0.5px] border-[#24837052] mb-[24px] md:mb-[48px] hover:shadow-[0px_4px_8px_0px_#13866F42] transition-all duration-500 ease-in-out hover:bg-[#2483706e] 
                      "
                        onClick={() => {
                          trackAnalytics("home:talk_to_lawyer_center_bar", { user_email: email }, userMetadata);
                          dispatch(setShowRequestLegalReviewAside(true));
                        }}
                      >
                        <div className="flex flex-col my-auto gap-[8px]">
                          <p className="text-[18px] font-[600] leading-[28.8px] text-[#18475A]">
                            {userConfig?.homepage?.talktolawyerbanner?.banner_text_line1}
                          </p>
                          <p className="text-[16px] font-[400] leading-[25.6px] text-[#353535]">
                            {userConfig?.homepage?.talktolawyerbanner?.banner_text_line2}
                          </p>
                        </div>
                        <div className="">
                          <img src={FourLawyersImage} alt="Four Lawyers" className="h-full my-auto" />
                        </div>
                        <div className="padding text-[#748A8C]">{userConfig?.homepage?.talktolawyerbanner?.button}</div>
                      </div>
                      <div
                        className="w-full md:hidden flex flex-row  mx-auto justify-between rounded-[12px]  bg-gradient-to-r from-[#ECF5F3] to-[rgba(236,245,243,0.96)] px-[20px] py-[20px] border-[0.5px] border-[#24837052] mb-[24px] md:mb-[48px]"
                        onClick={() => {
                          trackAnalytics("home:talk_to_lawyer_center_bar", { user_email: email }, userMetadata);
                          dispatch(setShowRequestLegalReviewAside(true));
                        }}
                      >
                        <div className="flex flex-col my-auto gap-[8px]">
                          <p className="text-[14px] font-[600] leading-[21px] text-[#18475A]">
                            {userConfig?.homepage?.talktolawyerbanner?.banner_text_line1}
                          </p>
                          <p className="text-[12px] font-[400] leading-[18px] text-[#353535]">
                            {userConfig?.homepage?.talktolawyerbanner?.banner_text_line2}
                          </p>
                          {userConfig?.homepage?.talktolawyerbanner?.button}
                        </div>
                        <div className="">
                          <img src={FourLawyersImage} alt="Four Lawyers" className="h-full my-auto" />
                        </div>
                      </div>
                    </>
                  )}
                </div>
              </div>
            </div>
            {educationMetadataFetchStatus === "success" && (
              <>
                {categories.length > 0 && (
                  <div
                    className={`${
                      sidenavCollapsed ? "md:w-[1376px]" : "md:w-[1262px]"
                    } lg:w-full w-full flex flex-col md:h-full bg-white pb-[32px] md:pb-[64px] pt-[24px] gap-[24px]`}
                  >
                    <div
                      className={`w-full mx-auto flex flex-col px-3 gap-[20px] ${
                        sidenavCollapsed ? "md:px-[60px]" : "md:px-[20px]"
                      }`}
                    >
                      <div className={`${sidenavCollapsed ? "md:w-[1248px]" : " md:w-[1134px]"}  md:mx-auto`}>
                        <p className="text-[16px] md:text-[18px] leading-[28.8px] font-[600] flex flex-row gap-[8px]">
                          {/* <span className="my-auto"><Lightning size={24} className="text-[#B6B6B6]" /></span> */}
                          <span className="my-auto text-[#011513] md:text-[#264653]">
                            Explore Prompts tailored for you
                          </span>
                        </p>
                      </div>
                      <div
                        className={`${
                          sidenavCollapsed ? "w-full md:w-[1248px]" : "w-full md:w-[1134px]"
                        } mx-auto flex md:flex-wrap md:flex-row gap-[8px] md:h-[50px] overflow-x-scroll border-b md:border-none`}
                      >
                        {categories.length > 0 && (
                          <>
                            {categories?.map((key) => (
                              <button
                                className={`flex flex-row md:border gap-[8px] md:rounded-[98px] px-[12px] md:px-[24px] py-[8px] my-auto text-[12px] leading-[14px] ${
                                  selectedCategory === key.id
                                    ? "font-[600] !border-[#18475A] border-b  bg-[#FCFCFA]"
                                    : "font-[400]"
                                } border-[#E4E4E4]`}
                                onClick={() => {
                                  trackAnalytics(
                                    `dashboard_home_${key?.name?.toLowerCase()}`,
                                    { user_email: email },
                                    userMetadata
                                  );
                                  setSelectedCategory(key.id);
                                }}
                              >
                                <span
                                  className={`my-auto ${
                                    selectedCategory === key.id ? "text-[#141414]" : "text-[#686868]"
                                  }`}
                                >
                                  {getIcon(key.icon_name, selectedCategory === key.id ? true : false)}
                                </span>
                                <span
                                  className={`my-auto text-[14px] leading-[21px] ${
                                    selectedCategory === key.id
                                      ? "font-[500] md:font-[600] text-[#141414]"
                                      : "text-[#686868] font-[400]"
                                  }`}
                                >
                                  {key.name}
                                </span>
                              </button>
                            ))}
                          </>
                        )}
                      </div>
                    </div>
                    <div
                      className={`w-full mx-auto flex flex-wrap md:flex-col gap-[20px] px-3 ${
                        sidenavCollapsed ? "md:px-[60px]" : "md:px-[20px]"
                      }`}
                    >
                      <div
                        className={`w-full ${
                          sidenavCollapsed ? "md:w-[1248px]" : " md:w-[1134px]"
                        } mx-auto flex md:flex-row flex-col gap-[12px] md:gap-[24px]`}
                      >
                        {suggestions.length > 0 && (
                          <>
                            {suggestions.map((suggestion, index) => (
                              <div
                                className="w-full flex flex-row md:!flex-col md:h-[100%] border px-[16px] py-[24px] cursor-pointer rounded-[8px] md:w-[236.8px] gap-[10px] bg-[#FCFCFA]"
                                key={index}
                                onClick={() => {
                                  trackAnalytics(
                                    "dashboard_home_tailored_suggestions",
                                    { user_email: email },
                                    userMetadata
                                  );
                                  goToSomePlace(suggestion);
                                }}
                              >
                                {getCircle(suggestion.type)}
                                <p className="text-[#353535] text-[14px] leading-[21px] font-[500]">
                                  {suggestion.display_prompt}{" "}
                                </p>
                              </div>
                            ))}
                          </>
                        )}
                      </div>
                      <div
                        className={`${
                          sidenavCollapsed ? "w-[1248px]" : " w-[1134px]"
                        } mx-auto flex justify-end gap-[24px]`}
                      >
                        <p
                          className="py-[12px] text-[14px] leading-[17.5px] font-[Helvetica Neue] font-[500] text-[#18475A] rounded cursor-pointer"
                          onClick={() => {
                            trackAnalytics(
                              "dashboard_home_tailored_suggestions_explore",
                              { user_email: email },
                              userMetadata
                            );
                            exploreAllClicked();
                          }}
                        >
                          SEE MORE
                        </p>
                      </div>
                    </div>
                  </div>
                )}
                {categories.length === 0 && educationMetadataFetchStatus === "success" && (
                  <div className="flex flex-col w-full h-full bg-white py-[64px] gap-[40px]">
                    <p className="text-center w-full">Failed to load suggestions</p>
                    <p className="text-center w-full">
                      <button
                        className="rounded-[8px] border bg-[#264653] text-white px-[12px] py-[8px]"
                        onClick={retryEducationMetaFetch}
                      >
                        <span>Retry</span>
                      </button>
                    </p>
                  </div>
                )}
              </>
            )}
            {educationMetadataFetchStatus === "loading" && (
              <div className="flex flex-col justify-center items-center w-full my-5">
                <div className="w-12 h-12 border-2 border-t-0 border-black rounded-full animate-spin"></div>
                <div className="">Fetching suggestions...</div>
              </div>
            )}
          </div>
          <ExploreMoreModalComponent />
        </BaseLayout>
      ) : (
        <>
          {isLoaded && getUserDetailsStatus === "failed" ? (
            <div className="flex items-center justify-center w-full h-full">
              <p className="text-red-500">Failed to load user details. Please refresh the page.</p>
            </div>
          ) : (
            <div className="w-full h-full flex justify-center items-center mx-auto mt-[200px]"></div>
          )}
        </>
      )}
    </>
  );
};

export default Homepage;
