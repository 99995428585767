import { Briefcase } from '@phosphor-icons/react';
import React from 'react';
import { useDispatch } from 'react-redux';
import { setShowLawyerCard } from 'src/pages/onboarding/onboardingSlice';
import { setShowRequestLegalReviewAside } from 'src/pages/onboarding/onboardingSlice';
const TalkToDedicatedLawyerButton = ({ userMetadata }) => {
    const dispatch = useDispatch();

    return (
        <button className="bg-[#ECF5F3] md:bg-[#18475A] border w-[158x] h-[30px] md:h-[48px] rounded-[8px] gap-[8px] md:gap-[12px]  md:py-[8px] px-[12px] flex flex-row justify-center items-center"
            onClick={() =>dispatch(setShowRequestLegalReviewAside(true))}>
            <span className='my-auto'>
                <img src={userMetadata?.lawyer_info?.pre_signed_image_url} width="24" height="24"
                    className="rounded-full my-auto w-[18px] md:w-[24px] h-[18px] md:h-[24px] border-white border-[1px]"
                    alt={userMetadata?.lawyer_info?.name} />
            </span>
            <span className="md:text-white my-auto text-[12px] md:text-[14px] md:leading-[19.6px]">Talk to {userMetadata?.lawyer_info?.name}</span>
            <span className="my-auto"><Briefcase size={16} className="text-[#011513] md:text-white my-auto" /></span>
        </button>
    );
};

export default TalkToDedicatedLawyerButton;