import React from "react";
import Modal from "src/components/Modal";
import Button from "./Button";
import { useDispatch, useSelector } from "react-redux";
import { setShowLearnMoreModal } from "../onboarding/onboardingSlice";

const items = [
  {
    img: "/images/reliability-1.png",
    text: "Get Tailored Matches",
    para: "We'll present a list of lawyers who specialize in your specific need, drawn from a network of over 2,000 AI-friendly lawyers",
  },
  {
    img: "/images/reliability-2.png",
    text: "Get Expert Advice",
    para: "Your selected lawyer will review your chat history and documents, offering a transparent quote that saves you time and money.",
  },
  {
    img: "/images/reliability-3.png",
    text: "Pay-As-You-Go",
    para: "Forget inflated retainers. Pay only for the legal work you actually need, with no hidden costs or surprises.",
  },
];

const ReliabilityGuaranteedPopup = () => {
  const dispatch = useDispatch();
  const showLearnMoreModal = useSelector((state) => state.onboarding.showLearnMoreModal);

  const closePopup = () => {
    dispatch(setShowLearnMoreModal(false));
  };
  return (
    <Modal
      contentAreaStyles={{ paddingLeft: "0px", paddingRight: "0px" }}
      isOpen={showLearnMoreModal}
      bodyStyle={{ paddingBottom: "0px", paddingTop: "40px" }}
      onClose={() => {
        closePopup();
      }}
    >
      <div className="px-10">
        <div>
          <h2 className="text-[#141414] text-2xl font-semibold">Peace of Mind Is One Click Away</h2>
          <p className="text-[#353535] text-lg font-medium ">
            Let a seasoned lawyer finalize your task with confidence
          </p>
          <img src="/images/realiability.png" className="w-[145px] h-[145px] absolute right-[75px] top-10" />
        </div>
        <ul className="mt-10 flex flex-col gap-[40px]">
          {items.map((item, index) => (
            <li className={`flex items-center  gap-[24px] ${index % 2 === 0 ? "flex-row" : "flex-row-reverse"}`}>
              <img src={item.img} className="w-[416px] h-[190px]" />
              <div className="flex flex-col gap-[12px]">
                <p className="w-[500px] flex items-center justify-items-center text-[#141414] font-[600] text-lg my-auto gap-[12px]">
                  <span className="my-auto w-[24px] h-[24px] rounded-[81px] bg-[#F2F2F2] text-center text-[12px] font-[400]">
                    {index + 1}
                  </span>
                  <span>{item.text}</span>
                </p>
                <p className="w-[500px] flex items-center  px-8 text-[#353535] font-[400] text-lg">{item.para}</p>
              </div>
            </li>
          ))}
        </ul>
      </div>
      <hr className="mt-6 text-[#E4E4E4]" />
      <div className="flex items-center justify-between px-10 my-4">
        <p className="text-sm font-medium text-[#353535]">
          See{" "}
          <a
            href="https://www.inhouse.app/terms-of-service"
            target="blank"
            rel="noopener noreferrer"
            className="font-semibold underline text-[#18475A]"
          >
            Terms & Conditions
          </a>{" "}
          for more info
        </p>
        <Button
          text={"Ok, got it"}
          onClick={() => {
            dispatch(setShowLearnMoreModal(false));
          }}
        />
      </div>
    </Modal>
  );
};

export default ReliabilityGuaranteedPopup;
