import { homePageClickAction, openPricingModal } from "src/pages/helpers";
import { Bicycle, Buildings, Lightning, OfficeChair } from "@phosphor-icons/react";
import { ArrowRight } from "@phosphor-icons/react/dist/ssr";
import { useDispatch } from "react-redux";
import TalkToDedicatedLawyerButton from "src/pages/chatpage/Navs/TalkToDedicatedLawyerButton";
import AskInhouseImg from '../images/AskInhouseImg.svg'
import InhouseProIcon from '../images/InhouseProIcon.svg'
import InhouseCounselIcon from '../images/InhouseCounselIcon.svg'
export const stateOptions = [
  "Alabama",
  "Alaska",
  "Arizona",
  "Arkansas",
  "California",
  "Colorado",
  "Connecticut",
  "Delaware",
  "Florida",
  "Georgia",
  "Hawaii",
  "Idaho",
  "Illinois",
  "Indiana",
  "Iowa",
  "Kansas",
  "Kentucky",
  "Louisiana",
  "Maine",
  "Maryland",
  "Massachusetts",
  "Michigan",
  "Minnesota",
  "Mississippi",
  "Missouri",
  "Montana",
  "Nebraska",
  "Nevada",
  "New Hampshire",
  "New Jersey",
  "New Mexico",
  "New York",
  "North Carolina",
  "North Dakota",
  "Ohio",
  "Oklahoma",
  "Oregon",
  "Pennsylvania",
  "Rhode Island",
  "South Carolina",
  "South Dakota",
  "Tennessee",
  "Texas",
  "Utah",
  "Vermont",
  "Virginia",
  "Washington",
  "West Virginia",
  "Wisconsin",
  "Wyoming",
];

export const companyExistance = [
  { label: "Not Started", value: "not-started" },
  { label: "0 - 2 years", value: "0-2" },
  { label: "2 - 10 years", value: "2-10" },
  { label: "10+ years", value: "10+ years" },
];

export const numberOfEmployees = [
  { label: "0", value: "0" },
  { label: "1-10", value: "1-10" },
  { label: "11-50", value: "11-50" },
  { label: "50+", value: "50+" },
  // { label: "201-500", value: "201-500" },
  // { label: "501-1000", value: "501-1000" },
  // { label: "1000+", value: "1000+" },
];

export const legalWorkOption = [
  { label: "Self-help", value: "Self-help" },
  { label: "Online Legal Services", value: "Online Legal Services" },
  { label: "Outside Counsel", value: "Outside Counsel" },
  { label: "Inside Counel", value: "Inside Counel" },
  { label: "Other", value: "Other" },
];

export const legalWorkTypePeriod = [
  { label: "Weekly", value: "Weekly" },
  { label: "Monthly", value: "Monthly" },
  { label: "Quarterly", value: "Quarterly" },
  { label: "Annually", value: "Annually" },
  { label: "Other", value: "Other" },
];

export const legalWorkTypes = [
  { label: "Employment/HR", value: "employment/HR" },
  { label: "Drafting Contracts", value: "drafting contracts" },
  { label: "Reviewing Contracts", value: "reviewing contracts" },
  { label: "Litigation", value: "litigation" },
  // { label: "Intellectual Property", value: "Intellectual Property" },
  // { label: "Licensing/Regulations", value: "Licensing/Regulations" },
  { label: "Other", value: "Other" },
];

export const annualLegalEXpenses = [
  { label: "Self Help", value: "Self Help" },
  { label: "under $5K", value: "under $5K" },
  { label: "$5K - $25K", value: "$5K - $25K" },
  { label: "over $25K", value: "over $25K" },
  // { label: "$500k-$2M", value: "$500k-$2M" },
  // { label: "Over $2M", value: "Over $2M" },
];

export const industryArray = [
  { label: "Technology", value: "Technology" },
  { label: "Retail", value: "Retail" },
  { label: "Agency", value: "Agency" },
  { label: "Consulting", value: "Consulting" },
  { label: "E-Commerce", value: "E-Commerce" },
  { label: "Real Estate", value: "Real Estate" },
  { label: "Manufacturing ", value: "Manufacturing " },
  { label: "Professional Services", value: "Professional Services" },
  { label: "Other", value: "Other" },
];

// export const entityOptions = [
//   { value: "c_corporation", label: "C-corporation" },
//   { value: "llc", label: "LLC" },
//   { value: "s_corporation", label: "S-corporation" },
//   { value: "partnership", label: "Partnership" },
//   { value: "sole_proprietorship", label: "Sole Proprietorship" },
//   { value: "non_profit", label: "Non Profit" },
//   { value: "other", label: "Other" },
// ];
// export const entityOptions = {
//   corporation: "Corporation",
//   llc: "LLC",
//   partnership: "Partnership",
//   sole_proprietorship: "Sole Proprietorship",
//   non_profit: "Non Profit",
//   other: "Other",
// }

export const PLANS = {
  // ADHOC: "ad_hoc_legal_review",

  // this is before personal/ business selection
  PRE_ONBOARDING: "pre_onboarding",

  // personal accounts
  FREE_TIER: "free_tier", // old free user. only for legacy purposes
  PERSONAL_FREE: "personal_free", // this is the new free users. personal account + free. 5 limited tasks per month + unlimited legal reviews

  // business accounts
  BUSINESS_TRIAL: "business_trial", // this lasts for 14 days
  BUSINESS_TRIAL_ENDED: "business_trial_ended", // after expiry of business trial
  SUBSCRIBER_BUSINESS: "subscriber_business", // essential . 10 tasks per month + 1 legal review per month
  SUBSCRIBER_ENTERPRISE: "subscriber_enterprise", // unlimited
};


 export const free_tier = "free_tier"
 export const subscriber_enterprise = "subscriber_enterprise"

export const Inhouse_ASK = "Ask Inhouse";
export const Inhouse_PRO = "Inhouse PRO";
export const Inhouse_COUNSEL = "Inhouse Counsel";

export const PLANS_V2 = {
  free_tier: {
    name: Inhouse_ASK,
    titleClassName: "",
    borderClassName: "",
    title: Inhouse_ASK,
    icon: AskInhouseImg,
    subTitle: "For occasional legal questions or personal use",
    pricing: "$0",
    paymentFrequency: "",
    paymentFrequencyText: "FREE FOREVER",
    featuresHeading: "",
    features: [
      <p className="text-[14px] leading-[21px] text-[#444444] font-[500]">
        <span className="text-[#222222] font-[600]">Instant Legal Advice</span> from our AI onany issue
      </p>,
      <p className="text-[14px] leading-[21px] text-[#444444]">
        Pay-as-you-go  <span className="text-[#222222]">lawyer support </span>
      </p>,
      // <p className="text-[14px] leading-[21px] text-[#444444]">
      //   <span className="text-[#222222]">On-demand lawyer support</span> @$99
      // </p>,
    ],
    btnTitle: "Current Plan",
    buttonClass: "ask-inhouse-plan-button",
    isHighlighted: false,
  },
  subscriber_enterprise: {
    name: Inhouse_PRO,
    titleClassName: "",
    borderClassName: "",
    title: Inhouse_PRO,
    icon: InhouseProIcon,
    subTitle: "For startups and early entrepreneurs",
    pricing: "$49",
    paymentFrequency: "/mo",
    paymentFrequencyText: "CANCEL ANYTIME",
    featuresHeading: "Everything on Ask Inhouse, plus:",
    features: [
      <p className="text-[14px] leading-[21px] text-[#444444] font-[500]">Draft  
        <span className="text-[#248370] font-[600]"> Unlimited </span> legal documents with AI.
      </p>,
      <p className="text-[14px] leading-[21px] text-[#444444] font-[500]">
        <span className="text-[#222222] font-[600]">Access  </span> your legal document drafts anytime
      </p>,
    ],
    btnTitle: "Upgrade Now",
    buttonClass: "inhouse-pro-plan-button",
    isHighlighted: true,
  },
  Inhouse_COUNSEL: {
    name: Inhouse_COUNSEL,
    titleClassName: "",
    borderClassName: "true",
    title: "Inhouse Counsel",
    icon: InhouseCounselIcon,
    subTitle: "Suited for businesses ready to grow",
    pricing: "$149",
    paymentFrequency: "/mo",
    paymentFrequencyText: "CANCEL ANYTIME",
    featuresHeading: "Everything on Inhouse Pro, plus:",
    features: [
      <p className="text-[14px] leading-[21px] text-[#444444]">
        <span className="text-[#248370] font-semibold">Unlimited*  </span> Lawyer Consultation
      </p>,
     <p className="text-[14px] leading-[21px] text-[#444444]">
     <span className="text-[#248370] font-[600]">Unlimited*  </span> Lawyer Reviewed  Documents
   </p>,
    <p className="text-[14px] font-[500] leading-[21px] text-[#444444]">
    <span className="text-[#222222] font-[600]">Annual  </span> Legal Health Checks
  </p>,
  <p className="text-[14px] leading-[21px] font-[600] text-[#444444]">
  <span className="text-[#222222] font-[500]">Assisted </span>  Onboarding
</p>,


    ],
    btnTitle: "Upgrade Now",
    buttonClass: "inhouse-counsel-button ",
    isHighlighted: false,
  },
};

export const PLAN_TYPES = {
  MONTHLY: "monthly",
  YEARLY: "yearly",
};

export const entityOptions = {
  Corporation: "Corporation",
  LLC: "LLC",
  Partnership: "Partnership",
  "Sole Proprietorship": "Sole Proprietorship",
  "Non Profit": "Non Profit",
  Other: "Other",
};

export const stateOptionsNew = {
  Alabama: "Alabama",
  Alaska: "Alaska",
  Arizona: "Arizona",
  Arkansas: "Arkansas",
  California: "California",
  Colorado: "Colorado",
  Connecticut: "Connecticut",
  Delaware: "Delaware",
  Florida: "Florida",
  Georgia: "Georgia",
  Hawaii: "Hawaii",
  Idaho: "Idaho",
  Illinois: "Illinois",
  Indiana: "Indiana",
  Iowa: "Iowa",
  Kansas: "Kansas",
  Kentucky: "Kentucky",
  Louisiana: "Louisiana",
  Maine: "Maine",
  Maryland: "Maryland",
  Massachusetts: "Massachusetts",
  Michigan: "Michigan",
  Minnesota: "Minnesota",
  Mississippi: "Mississippi",
  Missouri: "Missouri",
  Montana: "Montana",
  Nebraska: "Nebraska",
  Nevada: "Nevada",
  "New Hampshire": "New Hampshire",
  "New Jersey": "New Jersey",
  "New Mexico": "New Mexico",
  "New York": "New York",
  "North Carolina": "North Carolina",
  "North Dakota": "North Dakota",
  Ohio: "Ohio",
  Oklahoma: "Oklahoma",
  Oregon: "Oregon",
  Pennsylvania: "Pennsylvania",
  "Rhode Island": "Rhode Island",
  "South Carolina": "South Carolina",
  "South Dakota": "South Dakota",
  Tennessee: "Tennessee",
  Texas: "Texas",
  Utah: "Utah",
  Vermont: "Vermont",
  Virginia: "Virginia",
  Washington: "Washington",
  "West Virginia": "West Virginia",
  Wisconsin: "Wisconsin",
  Wyoming: "Wyoming",
};

export const plans = [
  {
    isActive: true,
    title: "Unlimited Plan",
    titleIcon: <Buildings className="ml-2 text-[#DCA218]" size={14} />,
    billCycle: "MONTHLY",
    planName: PLANS.SUBSCRIBER_ENTERPRISE,
    price: "189",
    priceYearly: "149",
    button: {
      text: "Upgrade to Unlimited Plan",
      className: "!font-bold",
      // onClick={() => {setPlan(PLANS.SUBSCRIBER_BUSINESS)}}
    },
    badge: {
      text: "MOST Popuplar",
      className: "",
      show: true,
    },
    features: [
      { text: "Unlimited legal tasks ✨", highlight: true },
      { text: "Dedicated Lawyer" },
      { text: "Unlimited Legal Reviews ✨", highlight: true },
    ],
  },
  {
    isActive: false,
    title: "Essential Plan",
    titleIcon: <OfficeChair className="ml-2" size={14} />,
    billCycle: "MONTHLY",
    price: "59",
    priceYearly: "49",
    planName: PLANS.SUBSCRIBER_BUSINESS,
    button: {
      secondary: true,
      text: "Upgrade to Essential Plan",
    },
    features: [
      { text: "10 legal tasks/month" },
      { text: "Dedicated Lawyer" },
      // { text: '1 lawyer review/month' },
      // { text: 'Additional Review costs  $49 / each' },
    ],
  },

  {
    isActive: false,
    title: "Starter Plan",
    titleIcon: <Bicycle className="ml-2" size={14} />,
    planName: PLANS.SUBSCRIBER_BUSINESS,
    button: {
      text: "Your Current Plan",
      disabled: true,
    },
    features: [{ text: "2 legal tasks" }, { text: "Pay $49 per Lawyer Verification" }],
  },
];

export const ReviewStatus = Object.freeze({
  pending_lawyer_matching: "pending_lawyer_matching",
  in_progress: "in_progress",
  completed: "completed",
  // rejected_by_lawyer: "rejected_by_lawyer",
  // submitted_legal_review: "submitted_legal_review",
  // accepted_by_lawyer: "accepted_by_lawyer",
  // accepted_by_inhouse: "accepted_by_inhouse",  // credits available or payment done, has been sent to the lawyer. for pro plans if the lawyer matching is
  // pending_lawyer_matching: "pending_lawyer_matching", //
  // pending_payment: "pending_payment",
  // not_requested: "not_requested",
});

export const ApiStatus = Object.freeze({
  success: "success",
  error: "error",
  loading: "loading",
  idle: "idle",
});

export const userData = {
  homepage: {
    topnav: {
      free_tier: ({ dispatch, metadata, email, page }) => {
        return (
          <>
            <button
                 className="text-[#84610e] md:text-[#353535] md:bg-[#fbf6e7] md:border-[1px] border-[#DCA218] w-[158x] h-[48px] md:rounded-[8px] gap-[8px]  px-[12px] flex flex-row"
                 onClick={() => {
                  openPricingModal(dispatch, metadata, email, page);
                 }}
               >
                 <span className="my-auto font-semibold text-[13px] md:text-[14px] leading-[19.6px] text-underline ">
                   Upgrade to Inhouse Pro{" "}
                 </span>
                 <span className="my-auto">
                   <Lightning weight="fill" size={16} className="text-[#DCA218] my-auto" />
                 </span>
               </button>
          </>
        );
      },
      subscriber_enterprise: ({ dispatch, metadata, email, page }) => {
        return (
          <>
            <button
                 className="text-[#84610e] md:text-[#353535] md:bg-[#fbf6e7] md:border-[1px] border-[#DCA218] w-[158x] h-[48px] md:rounded-[8px] gap-[8px]  px-[12px] flex flex-row"
                 onClick={() => {
                  openPricingModal(dispatch, metadata, email, page);
                 }}
               >
                 <span className="my-auto font-semibold text-[13px] md:text-[14px] leading-[19.6px] text-underline ">
                   Upgrade to Inhouse Counsel{" "}
                 </span>
                 <span className="my-auto">
                   <Lightning weight="fill" size={16} className="text-[#DCA218] my-auto" />
                 </span>
               </button>
          </>
        );
      },
      Inhouse_COUNSEL: (dispatch, metadata, email, page) => {<TalkToDedicatedLawyerButton userMetadata={metadata} />},
    },
    talktolawyerbanner: {
      free_tier: {
        showBanner: true,
        banner_text_line1: "Specialised legal advice @ Affordable Rates",
        banner_text_line2: "2000+ AI friendly lawyers practicing in all 50 states of the USA",
        button: <ArrowRight size={24} className=" mt-[75px]" />,
      },
      subscriber_enterprise: {
        showBanner: true,
        banner_text_line1: "Specialised legal advice @ Affordable Rates",
        banner_text_line2: "2000+ AI friendly lawyers practicing in all 50 states of the USA",
        button: <ArrowRight size={24} className=" mt-[75px]" />,
      },
      Inhouse_COUNSEL: {
        showBanner: false,
        banner_text_line1: "talk to a lawyer specialised for your needs",
        banner_text_line2: "2000+ AI friendly lawyers practicing in all 50 states of the USA",
        button: <ArrowRight size={24} className=" mt-[75px]" />,
      },
    },
  },
  sidenav: {
    iconOnUserImage: {
      free_tier: () => {},
      subscriber_enterprise: ({sidenavCollapsed}) => {
        return (
           <span className={`absolute top-0 right-0 translate-x-[35%] translate-y-[-20%] ${sidenavCollapsed ? "block" : "hidden"}`}>
           <Lightning size={16} className=" text-[#DCA218]" weight="fill" /></span>
        );
      },
      Inhouse_COUNSEL: ({sidenavCollapsed}) => {
        return (
           <span className={`absolute top-0 right-0 translate-x-[35%] translate-y-[-20%] ${sidenavCollapsed ? "block" : "hidden"}`}>
           <Lightning size={16} className=" text-[#DCA218]" weight="fill" /></span>
        );
      },
    },
    displayPlanName: {
      free_tier: () => {},
      subscriber_enterprise: () => {
        return (
          <p className="bg-[#fff8e6] px-[8px] py-[4px] font-sans font-medium text-[10px] leading-[12px] text-[#686868] flex justify-center items-center gap-[4px] rounded-[4px]">Inhouse Pro <span> <Lightning size={10} className=" text-[#DCA218]" weight="fill" /></span> </p>              
        );
      },
      Inhouse_COUNSEL: () => {
        return (
          <p className="bg-[#fff8e6] px-[8px] py-[4px] font-sans font-medium text-[10px] leading-[12px] text-[#686868] flex justify-center items-center gap-[4px] rounded-[4px]">Inhouse Pro <span> <Lightning size={10} className=" text-[#DCA218]" weight="fill" /></span> </p>        
        );
      },
    },
    planName: {
      free_tier: "free tier",
      subscriber_enterprise: "subscriber_enterprise",
      Inhouse_COUNSEL: "Inhouse_COUNSEL",
    },
  },
  chat: {
    draftDocument: {
      islocked: "This document is locked by another user. Please try again later.",
      "subscription-plan-popup": "2 options only instead of 3",
    },
  },
  legalReview: {
    isTextMandatory: {
      home: true,
      thread: false,
    },
    dedicatedLawyer: {},
    nonDedicatedLawyer: {},
    free_tier: {
      banner: "something",
      text: "soemthing",
      buttonCta: "something",
      buttonClasses: "seomthing",
      formLabel: "something",
      formPlanceholder: "something",
      isTextMandatory: "something",
      phoneNumberLabel: "seomelabel",
      phonenumberplaceholder: "someplaceholder",
    },

    subscriber_enterprise: {
      banner: "something",
      text: "soemthing",
      buttonCta: "something",
      buttonClasses: "seomthing",
      formLabel: "something",
      formPlanceholder: "something",
      isTextMandatory: "something",
      phoneNumberLabel: "seomelabel",
      phonenumberplaceholder: "someplaceholder",
    },
    Inhouse_COUNSEL: {},
    requestSuccessMessage: {
      free_tier: "",
      subscriber_enterprise: "something",
      Inhouse_COUNSEL: "something",
    },
    footer: {
      free_tier: "",
      subscriber_enterprise: "something",
      Inhouse_COUNSEL: "something",
    },
    cta: {
      free_tier: "get help",
      subscriber_enterprise: "share chat",
      Inhouse_COUNSEL: "request review",
    },
  },
  lawyerHubData: {},
};
