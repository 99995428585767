import React from "react";

const LegalReviewCompleteAsidePersonal = () => {
  return (
    <div>
      <h2>Legal Review Complete</h2>
      <p>Your legal review has been completed successfully.</p>
    </div>
  );
};

export default LegalReviewCompleteAsidePersonal;
