import { X } from '@phosphor-icons/react';
import { Modal } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { setSmallScreenModalOpen } from 'src/pages/Homepage/HomeSlice';
import DesktopImg from '../../../images/Desktop.svg'
const SmallScreenModalComponent = () => {
    const dispatch = useDispatch();

    const smallScreenModalOpen = useSelector((state) => state.homeslice.smallScreenModalOpen);

    const closeModal = () => {
        dispatch(setSmallScreenModalOpen(false));
    }

    return (
        <Modal
            open={smallScreenModalOpen}
            className="rounded overflow-hidden "
            footer={null}
            onCancel={() => dispatch(setSmallScreenModalOpen(false))}
            cancelButtonProps={{
                style: { display: 'none' }
            }}
        >
            <div className="w-full flex flex-col  gap-[24px]">
                <div className="">
                    <div className="flex flex-col border-b gap-4 py-[20px] px-[16px]">
                        <div className='flex gap-[8px] w-full justify-end'>
                            <X
                                size={24}
                                className="my-auto cursor-pointer"
                                color="#343330"
                                onClick={() => closeModal()}
                                id="cancel-draft-document-button"
                            />
                        </div>
                    </div>
                    <div className="flex flex-col justify-center items-center p-5 gap-[10px]">
                        <img src={DesktopImg} alt="Desktop" className='w-[137px] h-[125px] mb-[30px]' /> 
                        <p className='text-[18px] font-semibold leding-[25px] text-[#353535] text-center'>
                        This feature is currently best experienced on desktop.
                        </p>
                        <p className='text-[12px] font-normal leding-[19px] text-[#353535] text-center'> We're working on the mobile version and will update you soon!</p>
                    </div>
                </div>
            </div>
        </Modal >
    );
};

export default SmallScreenModalComponent;