import React from "react";
import SubscriptionCard from "./SubscriptionCard";
import { ShieldCheck } from "@phosphor-icons/react";

const PlanCards = (props) => {
  const { wrapperClassName, PLAN_TYPES, setPlan, checkoutUrlStatus, cards = [], billCycle = "MONTHLY" } = props;

  return (
    <>
      <div className="w-full justify-center items-center gap-[4px] mt-[10px]">
        <p className="font-bold italic text-[32px] leading-[44px] font-[lora] text-center text-[#18475A]">
          Upgrade your plan
        </p>
        <p className="text-[#686868] font-normal text-[16px] leading-[25px] font-sans text-center">
          Take charge of your legal needs—choose a plan
        </p>
      </div>
      <div className={`flex gap-6 h-[467px] ${wrapperClassName}`}>
        {Object.keys(cards).map((key) => (
          <SubscriptionCard
            data={cards[key]}
            billCycle={billCycle}
            PLAN_TYPES={PLAN_TYPES}
            setPlan={setPlan}
            checkoutUrlStatus={checkoutUrlStatus}
          />
        ))}
      </div>
      {/* {JSON.stringify(cards)} */}

      <p className="mt-6 text-[12px] font-normal italic font-sans text-center">
        *Unlimited 30-minute sessions, one per topic, to ask legal questions or have documents reviewed. Learn More
      </p>
      {/* <ul className='flex justify-center gap-6 mt-10'>
                <li className='flex items-center bg-[#137513]/10 rounded-full px-3 py-1 text-xs font-semibold'><ShieldCheck weight='fill' size={20} className='text-[#137513]/60 mr-2' />100% Money Back Guarantee </li>
                <li className='flex items-center bg-[#137513]/10 rounded-full px-3 py-1 text-xs font-semibold'><ShieldCheck weight='fill' size={20} className='text-[#137513]/60 mr-2' />100% Reliability Guaranteed </li>
            </ul> */}
    </>
  );
};

export default PlanCards;
