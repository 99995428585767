import { useState, useEffect } from 'react';
import { PLANS } from 'src/components/Constants';

const TasksLeftText = ({ userMetadata }) => {
    const [numTotal, setNumTotal] = useState(0);
    const [numRemaining, setNumRemaining] = useState(0);
    const [dark, setDark] = useState(0);

    useEffect(() => {
        var total = userMetadata?.threads_total;
        var remaining = userMetadata?.threads_remaining;
        setDark(Math.round((remaining / total * 119) / 5) * 5);
        setNumTotal(total);
        setNumRemaining(remaining);
    }, [])
    if (userMetadata?.subscription_type === PLANS.BUSINESS_TRIAL_ENDED) {
        return (
            <div className="flex flex-col gap-[8px] my-auto">
                <span className="my-auto text-[14px] leading-[21px]">
                    Your Free Trial has ended</span>
            </div>
        )
    }
    else {
        return (
            <div className="flex flex-col gap-[8px] my-auto" title={`Legal tasks will renew again on ${userMetadata?.subscription_renewal_date}`}>
                <span className="my-auto text-[13px] md:text-[14px] leading-[21px] hidden md:block">
                    {numTotal ? <>{`${numRemaining}/ ${numTotal}`}</> : "0"} legal tasks left</span>
                <span className="my-auto text-[13px] md:text-[14px] leading-[21px] md:hidden">{numTotal ? <>{`${numRemaining}/ ${numTotal} legal tasks left`}</> : "No tasks left"}</span>
                <div className="flex flex-row w-[119px] h-[4px] bg-[#E4E4E4] rounded-[40px]">
                    <span className={`h-[4px] disp-${dark} rounded-[40px]`}></span>
                </div>
            </div>
        )
    }
};

export default TasksLeftText;