import { ArrowRight, Info } from "@phosphor-icons/react";
import React from "react";
import { ReviewStatus } from "src/components/Constants";
import { PLANS } from "src/components/Constants";
import { useLocation } from "react-router-dom";
const LegalReviewAsideFooter = (props) => {
  const location = useLocation();
  const {
    metadata,
    legalReview,
    requestLegalReviewStatus,
    loading,
    submitRequest,
    dispatch,
    setShowRequestLegalReviewAside,
    phoneNumberValid,
    text,
  } = props;

  var tandcMessage = (
    <p className="my-auto text-[10px] md:text-[12px]">
      See{" "}
      <a
        href="https://www.inhouse.app/terms-of-service"
        rel="noopener noreferrer"
        className="underline cursor-pointer"
        target="blank"
      >
        Terms & Conditions
      </a>{" "}
      for more info
    </p>
  );
  var informationMessage = (
    <>
      <div className="flex items-start gap-[8px]">
        <Info size={18} className="text-[#949494]" />
        <p className="text-[#353535] text-[10px] md:text-[12px] font-medium ">
          Your information is protected by our{" "}
          <a
            href="https://www.inhouse.app/privacy-policy"
            rel="noopener noreferrer"
            className="underline cursor-pointer"
            target="blank"
          >
            Privacy Policy,
          </a>{" "}
          but not Attorney Client Privilege.
        </p>
      </div>
    </>
  );
  var nullFooter = null;

  if (
    metadata?.subscription_type !== PLANS.PERSONAL_FREE &&
    legalReview?.status === ReviewStatus.pending_lawyer_matching
  ) {
    if (!metadata?.lawyer_info && window.location.pathname !== "/home") {
      return (
        <div className="flex justify-between gap-[12px] border-t border-t-[#E4E4E4] mt-4 py-3 px-3 w-full my-auto">
          {informationMessage}
        </div>
      );
    }
  }

  if (metadata?.subscription_type !== PLANS.PERSONAL_FREE && requestLegalReviewStatus === "success") {
    return <>{nullFooter}</>;
  }

  if (
    requestLegalReviewStatus === "success" ||
    (!metadata?.lawyer_info && legalReview?.status === ReviewStatus.pending_lawyer_matching)
  ) {
    // ok got it footer
    return (
      <section className="w-full flex">
        <div className="flex justify-between flex-col md:flex-row gap-[12px] border-t border-t-[#E4E4E4] mt-4 py-3 px-6 w-full my-auto">
          {informationMessage}

          <button
            className={`bg-[#18475A] text-white font-medium rounded-lg flex items-center justify-center h-[48px] gap-[12px] py-[12px] px-[12px] disabled:!bg-[#949494]`}
            onClick={() => {
              dispatch(setShowRequestLegalReviewAside(false));
            }}
          >
            <span className="my-auto w-[105px]">OK, got it!</span>
            <ArrowRight size={18} className="my-auto text-white" />
          </button>
        </div>
      </section>
    );
  }

  if (!legalReview && requestLegalReviewStatus !== "success") {
    // get help footer
    return (
      <section className="w-full flex">
        <div
          className={`flex ${
            metadata.lawyer_info && location.pathname !== "/home" ? "flex-row w-full justify-between" : "flex-col"
          } gap-[12px] border-t border-t-[#E4E4E4] mt-4 py-3 px-6 my-auto`}
        >
          {metadata.lawyer_info && location.pathname !== "/home" ? (
            tandcMessage
          ) : (
            <>
              <p className="text-[12px] leading-[18px] text-[#686868] w-full">
                By proceeding, you agree to our{" "}
                <a
                  href="https://www.inhouse.app/terms-of-service"
                  rel="noopener noreferrer"
                  className="underline cursor-pointer"
                  target="blank"
                >
                  Terms of Service
                </a>{" "}
                and{" "}
                <a
                  href="https://www.inhouse.app/privacy-policy"
                  rel="noopener noreferrer"
                  className="underline cursor-pointer"
                  target="blank"
                >
                  {" "}
                  Privacy Policy
                </a>{" "}
                , including the use of your information and consent to receive SMS messages. Your information is
                protected by our Privacy Policy, but not Attorney Client Privilege.
              </p>
            </>
          )}
          <button
            className={`bg-[#18475A] text-[14px] md:text-[16px] text-white font-medium rounded-lg flex h-[48px] gap-[12px] py-[12px] px-[12px] disabled:!bg-[#949494] justify-center  items-center`}
            onClick={() => {
              submitRequest();
            }}
            // disabled={
            //   loading || (!phoneNumberValid && !metadata.lawyer_info) || (window.location.pathname === "/home" && !text)
            // }
          >
            {loading ? (
              <>
                <div className="flex items-center justify-center">
                  <div className="w-4 h-4 border-2 border-t-[#fff] rounded-full animate-spin"></div>
                </div>
              </>
            ) : (
              <>
                <span className={`my-auto `}>
                  {metadata.lawyer_info && window.location.pathname !== "/home" ? "Share for Review" : "Get Help"}
                </span>
                <ArrowRight size={18} className="my-auto text-white" />
              </>
            )}
          </button>
        </div>
      </section>
    );
  }

  var onlyDisclaimerFooter = (
    <section className="w-full flex">
      <div className="flex justify-between gap-[12px] border-t border-t-[#E4E4E4] mt-4 py-3 px-6 w-full my-auto">
        {informationMessage}
      </div>
    </section>
  );
};

export default LegalReviewAsideFooter;
