import React, { useEffect } from "react";
import Modal from "../../components/Modal";
import { Buildings, Check, OfficeChair, X } from "@phosphor-icons/react";
import Button from "./Button";
import "./lawyerHub.css";
import PlanCards from "./components/PlanCards";
import { useDispatch, useSelector } from "react-redux";
import { setShowCheckoutModal, setShowUpgradePlanNudge } from "../onboarding/onboardingSlice";
import { PLANS, PLAN_TYPES, plans, PLANS_V2 } from "../../components/Constants";
import { getCheckoutUrl, setUserSelectedPlan } from "../chatpage/chatSlice";
import { toast } from "react-toastify";
import Switch from "./components/Switch";
import { Spin } from "antd";
import { useUser } from "@clerk/clerk-react";

const PlanSelectionPopup = ({ isOpen }) => {
  const dispatch = useDispatch();
  const [planType, setPlanType] = React.useState(PLAN_TYPES.MONTHLY);
  const [plan, setPlan] = React.useState(null);
  const [plansToShow, setPlansToShow] = React.useState([]);

  const { user } = useUser();

  const showUpgradePlanNudge = useSelector((state) => state.onboarding.showUpgradePlanNudge);
  const threadData = useSelector((state) => state.messageSlice.threadData);
  const checkoutUrl = useSelector((state) => state.chat.checkoutUrl);
  const checkoutUrlStatus = useSelector((state) => state.chat.checkoutUrlStatus);
  const userMetadata = useSelector((state) => state.onboarding.userMetadata);
  const pricingPlans = useSelector((state) => state.onboarding.plansToShow);

  const threads_remaining = userMetadata?.threads_remaining;
  const subscription_type = userMetadata?.subscription_type;
  const threads_total = userMetadata?.threads_total;
  const threads_used = threads_total - threads_remaining;

  const closePopup = () => {
    dispatch(setShowUpgradePlanNudge(false));
  };

  useEffect(() => {
    if (Object.keys(pricingPlans).length > 0) {
      setPlansToShow(pricingPlans);
    } else {
      setPlansToShow(PLANS_V2);
    }
  }, [pricingPlans]);
  useEffect(() => {
    setPlanType(PLAN_TYPES.MONTHLY);
    setPlan(null);
  }, [showUpgradePlanNudge]);

  useEffect(() => {
    if (plan) {
      var data;
      if (plan === PLANS.ADHOC) {
        data = {
          payment_type: "ad_hoc_legal_review",
          subscription_info: null,
          legal_review_thread_id: threadData?.id,
          redirect_url: `${process.env.REACT_APP_URL}/?x-vercel-protection-bypass=${process.env.REACT_APP_VERCEL_PROTECTION_BYPASS}`,
        };
      } else if (plan === PLANS.SUBSCRIBER_BUSINESS || plan === PLANS.SUBSCRIBER_ENTERPRISE) {
        data = {
          payment_type: "subscription",
          subscription_info: {
            subscription_type: plan,
            payment_frequency: planType,
          },
          legal_review_thread_id: threadData?.id,
          redirect_url: `${process.env.REACT_APP_URL}/?x-vercel-protection-bypass=${process.env.REACT_APP_VERCEL_PROTECTION_BYPASS}`,
        };
      }
      dispatch(setUserSelectedPlan(plan));
      dispatch(getCheckoutUrl(data));
    }
  }, [plan]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (["idle", "loading", "success", undefined].indexOf(checkoutUrlStatus) === -1) {
      toast.error(checkoutUrlStatus);
    }
  }, [checkoutUrlStatus]);

  useEffect(() => {
    if (checkoutUrl) {
      closePopup();
    }
  }, [checkoutUrl]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <Modal
      contentAreaStyles={{ paddingLeft: "40px", paddingRight: "40px" }}
      isOpen={isOpen}
      bodyStyle={{ paddingBottom: "40px" }}
      onClose={() => {
        closePopup();
      }}
    >
      <PlanCards
        cards={plansToShow}
        billCycle={planType}
        PLAN_TYPES={PLAN_TYPES}
        setPlan={setPlan}
        wrapperClassName="mt-[20px]"
        checkoutUrlStatus={checkoutUrlStatus}
      />
      {checkoutUrlStatus === "loading" && <Spin fullscreen tip="Loading..."></Spin>}
    </Modal>
  );
};

export default PlanSelectionPopup;
