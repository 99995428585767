import React from "react";
import remarkGfm from "remark-gfm";
import Markdown from "react-markdown";
import ChangeParty from "../Prompts/ChangeParty";

const MessageRender = ({ message, userType, avatar, isFirstAiMessage, sharedpage, chatInfo }) => {
  const formatText = (text) => {
    if (text) {
      text = text.replace(/`/g, "&#96;");
      text = text.replace(/\\n/g, " \n ");
      text = text.replace(/\n#/g, " \n \n #");
      text = text.replace(/\n/g, " \n\n ");
      text = text.replace(/\\t/g, "&nbsp;&nbsp;&nbsp;&nbsp;");
      text = text.replace(/\t/g, "&nbsp;&nbsp;&nbsp;&nbsp;");
    }
    return text;
  };

  return (
    <div className="message-render">
      {userType === "user" && (
        <div
          className={`flex rounded-b-[5px]  text-black font-[500] ${
            message !== undefined && message?.length < 175
              ? "text-[16px] md:text-[24px] leading-[25px] md:leading-[33.6px]"
              : ""
          }
                    ${
                      message !== undefined &&
                      message?.length < 250 &&
                      message?.length >= 175 &&
                      "md:text-[20px] md:leading-[25.6px] text-[12px] leading-[16px]"
                    } ${
            message !== undefined &&
            message?.length >= 250 &&
            "text-[15px] leading-[18px] md:text-[18px] md:leading-[21px]"
          }</>
                    }}`}
        >
          <div className="chat">
            {isFirstAiMessage && <img src={avatar} alt="AI" className="w-[32px] h-[32px] mt-[16px]" />}
            {message && <Markdown remarkPlugins={[remarkGfm]}>{`${formatText(message)}`}</Markdown>}
            {!sharedpage && (chatInfo?.show_party_update_option || chatInfo?.payload?.allow_update_party) && (
              <ChangeParty chatInfo={chatInfo} />
            )}
          </div>
        </div>
      )}

      {userType === "ai" && (
        <div
          className={`flex rounded-b-[5px] text-[14px] md:text-[16px] leding-[22px] md:leading-[25.6px] text-[#333333]}`}
        >
          <div className="chat">
            {isFirstAiMessage && <img src={avatar} alt="AI" className="w-[32px] h-[32px] mt-[16px]" />}
            {message && <Markdown remarkPlugins={[remarkGfm]}>{`${formatText(message)}`}</Markdown>}
            {!sharedpage && (chatInfo?.show_party_update_option || chatInfo?.payload?.allow_update_party) && (
              <ChangeParty chatInfo={chatInfo} />
            )}
          </div>
        </div>
      )}
    </div>
  );
};

export default MessageRender;
