import React, { useEffect, useState, useRef } from 'react';
import { useSelector } from 'react-redux';
import { Pencil, FloppyDisk } from "@phosphor-icons/react";

const FollowupInputPrompt = ({ index, question, answers, setAnswers, submitAnswer, disabled, isDocumentGenerated, skipQuestion, edit,
    setEdit }) => {
    const [inputValue, setInputValue] = React.useState(answers[index]);
    // const [edit, setEdit] = useState(true)
    const textareaRef = useRef(null);
    const threadData = useSelector(state => state.messageSlice.threadData);
    const awaitingResponse = useSelector(state => state.messageSlice.awaitingResponse);

    useEffect(() => {
        if (inputValue) {
            setAnswers({ ...answers, [index]: inputValue });
        }
    }, [inputValue]); // eslint-disable-line


    const handleKeyDown = (e) => {
        if (e.key === "Enter" &&
            (e.ctrlKey || e.metaKey) &&
            !e.shiftKey && inputValue) {
            if (inputValue?.length > 0) {
                submitAnswer(question, index, inputValue);
            }
            else {
                skipQuestion(question, index)
            }
            setEdit(true)
        }
    };

    // const handelEdit = () => {
    //     setEdit(false)
    //     adjustTextareaHeight();
    // }
    const handelSave = () => {
        submitAnswer(question, index, inputValue);
        setEdit(true)
    }

    const adjustTextareaHeight = () => {
        const textarea = textareaRef.current;
        textarea.style.height = "auto";
        textarea.style.height = `${Math.min(textarea.scrollHeight, 110)}px`;
    };
    return (
        <div className='flex justify-center flex-col items-center gap-[8px]'>
            <div className='flex justify-center items-center m-0 w-full px-[10px]'>
                <div className='flex flex-row justify-between w-full items-center border border-collapse border-[#E0E0E0] rounded-[8px]'>
                    <div className="flex w-full ">
                        <textarea type="text"
                            placeholder={threadData?.isSharedThread ? "Answer not yet available" : "Type your answer"}
                            className="w-full text-[14px] md:text-base cursor-pointer disabled:cursor-auto rounded-[8px] px-[10px] py-[10px]  md:px-[14px] md:py-[12px]"
                            value={inputValue}
                            disabled={(edit && disabled) || threadData?.isSharedThread || isDocumentGenerated}
                            onChange={(e) => { setInputValue(e.target.value); adjustTextareaHeight(); }}
                            onKeyDown={handleKeyDown}
                            onBlur={() => {
                                if (inputValue?.length > 0) {
                                    submitAnswer(question, index, inputValue);
                                }
                                setEdit(true);
                            }}
                            rows="1"
                            style={{
                                maxHeight: "90px",
                                lineHeight: "24px",
                                minHeight: "24px",
                            }}
                            ref={textareaRef}
                        />
                    </div>
                    {/* {!awaitingResponse && <div>   {!threadData?.isSharedThread ? <>

                        {disabled && <div> {edit ? !isDocumentGenerated && <Pencil size={20} className='mx-2 cursor-pointer' onClick={handelEdit} /> :
                            !isDocumentGenerated && <FloppyDisk size={20} className='mx-2 cursor-pointer' onClick={handelSave} />} </div>}
                    </> : null}</div>} */}
                </div>
            </div>
            {(!disabled || (disabled && !edit)) && (
                <div className='flex justify-between gap-[8px] w-full px-[14px]'>
                    <p className='flex flex-row text-[#13866F] gap-[8px]'>
                        {/* <Sparkle size={14.5} className='my-auto' weight='bold' />
                                                    <span className='text-[14px] my-auto leading-[22.5px] font-[600]'>Explain this</span> */}
                    </p>
                    <div className='flex flex-row gap-[8px]'>
                        {!inputValue?.trim() ? <>{edit && <button className='border-0 text-[#001613] text-[14px] leading-[22.4px] font-[600] rounded-[8px] p-[8px] cursor-pointer'
                            onClick={() => skipQuestion(question, index)}>Skip</button>}</> :
                            <>{edit && <button className='bg-[#13866F] text-[#fff] rounded-[4px] px-[16px] h-[30px] text-[14px] cursor-pointer disabled:cursor-default leading-[22.4px] my-auto align-items-center'
                                disabled={answers[index] === undefined || answers[index] === null || answers[index] === ""}
                                onClick={() => { submitAnswer(question, index, answers[index]) }}
                            >Continue</button>}</>}

                        {!edit && !isDocumentGenerated && <button className='bg-[#13866F] text-[#fff] rounded-[4px] px-[16px] h-[30px] text-[14px] cursor-pointer disabled:cursor-default leading-[22.4px] my-auto align-items-center'
                            disabled={answers[index] === undefined || answers[index] === null || answers[index] === ""}
                            onClick={() => handelSave()}
                        >Save</button>}
                    </div>
                </div>
            )}
        </div>
    );
};

export default FollowupInputPrompt;