import { Lightbulb } from "@phosphor-icons/react";
import React, { useEffect, useState } from "react";
import ForwardIcon from "../../images/forward.svg";
// import FollowupInputPrompt from './FollowupInputPrompt';
import { useDispatch, useSelector } from "react-redux";
import { setSubmittingQnA, setUpdatingQuestion } from "../../pages/chatpage/messageSlice";
// import AskAvatar from "../../images/AskAvatar.svg";
// import ReviewAvatar from "../../images/ReviewAvatar.svg";
import DraftAvatar from "../../images/DraftAvatar.svg";
import Markdown from "react-markdown";
import remarkGfm from "remark-gfm";
import { useWebSocketContext } from "../../WebsocketProvider";
import PostAnswers from "./PostAnswers";
import QnA from "./QnA";
import applicationStore from "src/persistence/stores/RootStore";
import SkipAllQuestionsConfirmPopup from "./SkipAllQuestionsConfirmPopup";
import { setSkipAllQuestionConfirm } from "../../pages/chatpage/messageSlice";
const FollowupQuestionsPrompt = ({ message, skipped, isFirstAiMessage, threadType, setSteps }) => {
  const dispatch = useDispatch();

  const [answers, setAnswers] = useState({});
  const [activeIndex, setActiveIndex] = useState(0);
  const [questionsAnswered, setQuestionsAnswered] = useState([]);
  const [questionsSkipped, setQuestionsSkipped] = useState([]);
  const [questions, setQuestions] = useState([]);
  const [submitButton, setSubmitButton] = useState();
  // const [classes, setClasses] = useState("")
  const [isDocumentGenerated, setIsDocumentGenerated] = useState(false);
  const [isRenderingComplete, setIsRenderingComplete] = useState(false);
  const [indexOfMessage, setIndexOfMessage] = useState(0);

  const { sendMessage, createMessage } = useWebSocketContext();
  const threadsData = useSelector((state) => state.messageSlice.threadData);
  const awaitingResponse = useSelector((state) => state.messageSlice.awaitingResponse);

  useEffect(() => {
    setIndexOfMessage(threadsData?.messages.indexOf(message));
    if (threadsData.questions) {
      setActiveIndex(threadsData.questions.active_index);
      setAnswers(threadsData.questions.answers);
      setQuestionsAnswered(threadsData.questions.answers);
      setQuestions(threadsData.questions.questions);
      setQuestionsAnswered(threadsData.questions.answers);
      setQuestionsSkipped(threadsData.questions.skipped);
    }
    if (threadsData?.document?.google_doc_id || threadsData?.document?.url) {
      setIsDocumentGenerated(true);
    }
  }, [threadsData]);

  useEffect(() => {
    if (
      threadsData.questions?.questions?.length ===
      threadsData.questions?.skipped.length + Object.keys(threadsData.questions?.answers).length
    ) {
      if (!awaitingResponse && !(threadsData?.document?.google_doc_id || threadsData?.document?.url)) {
        // user has taken action on all questions but the submit button is not clicked manually
        // so we will submit the answers automatically
        if (!(threadsData.is_shared || threadsData.isSharedThread)) {
          setTimeout(() => {
            submitFollowUpQuestions();
          }, 1000);
        }
      }
    }
  }, [threadsData?.questions]); // eslint-disable-line

  useEffect(() => {
    if (threadsData.questions?.questions?.length > 0) {
      var totalQuestions = threadsData.questions?.questions?.length;
      var totalActionCount = Object.keys(questionsAnswered).length + questionsSkipped.length;
      // 3 cases :
      // 1. skip all questions
      // 2. skip the rest questions
      // 3. submit and generte document
      if (threadsData.is_shared || threadsData.isSharedThread) {
        setSubmitButton(<></>);
        return;
      }
      if (
        totalQuestions - 1 === totalActionCount &&
        !(threadsData?.document?.google_doc_id || threadsData?.document?.url)
      ) {
        if (awaitingResponse) {
          // button with loading state
          setSubmitButton(<></>);
        } else {
          // submit & generate
          setSubmitButton(
            <button
              className="cursor-pointer rounded-[8px] bg-[#264653] text-white text-[14px] leading-[19.6px] p-[12px]"
              onClick={submitFollowUpQuestions}
            >
              Submit & Generate Draft
            </button>
          );
        }
      } else if (totalActionCount === 0) {
        // button with skip all
        setSubmitButton(
          <button
            className="rounded-[8px]  text-[#264653] text-[14px] leading-[19.6px] p-[12px] flex gap-[4px] cursor-pointer"
            onClick={confirmSkipAllQuestion}
          >
            <span>Skip all questions</span>
            <img src={ForwardIcon} className="my-auto w-[19.5px]" alt="forward-icon" />
          </button>
        );
      } else if (totalActionCount > 0 && totalActionCount < totalQuestions - 1) {
        // button with skip the rest
        setSubmitButton(
          <button
            className="rounded-[8px]  text-[#264653] text-[14px] leading-[19.6px] p-[12px] flex gap-[4px] cursor-pointer"
            onClick={confirmSkipAllQuestion}
          >
            <span>Skip the Rest and Generate Draft</span>
            <img src={ForwardIcon} className="my-auto w-[19.5px]" alt="forward-icon" />
          </button>
        );
      }
    }
  }, [
    questionsAnswered,
    questionsSkipped,
    threadsData.questions,
    questionsAnswered,
    questionsSkipped,
    awaitingResponse,
  ]); // eslint-disable-line

  useEffect(() => {
    if (questions.length > 0) {
      setIsRenderingComplete(false);
    }
  }, [questions]);

  const onQnARendered = (index) => {
    if (index === questions.length - 1) {
      setIsRenderingComplete(true);
    }
  };

  const skipQuestion = (question, index) => {
    var data = {
      id: question.id,
      activeIndex: index,
      action: "skip",
      thread_id: threadsData.id,
    };
    dispatch(setUpdatingQuestion(data));
    data = {
      question_id: question.id,
      action: "skipped",
      thread_id: threadsData.id,
      text: null,
    };
    var message = createMessage("draft", "update-question", data);
    sendMessage(message, false, true);
    dispatch(setSubmittingQnA(true));
  };

  const submitAnswer = (question, index, response) => {
    var data = {
      id: question.id,
      activeIndex: index,
      action: "answer",
      response: response,
      thread_id: threadsData.id,
    };
    dispatch(setUpdatingQuestion(data));
    data = {
      question_id: question.id,
      action: "submitted",
      text: response,
      thread_id: threadsData.id,
    };
    var message = createMessage("draft", "update-question", data);
    sendMessage(message, false, true);
    setQuestionsAnswered({ ...questionsAnswered, [index]: response });
    setActiveIndex(index + 1);
    dispatch(setSubmittingQnA(true));
  };
  const formatText = (text) => {
    text = text.replace(/`/g, "&#96;");
    text = text.replace(/\\n/g, " \n ");
    text = text.replace(/\n#/g, " \n \n #");
    text = text.replace(/\n/g, " \n\n ");
    text = text.replace(/\\t/g, "&nbsp;&nbsp;&nbsp;&nbsp;");
    text = text.replace(/\t/g, "&nbsp;&nbsp;&nbsp;&nbsp;");
    return text;
  };

  const submitFollowUpQuestions = () => {
    const responseAwaiting = applicationStore.getState().messageSlice.awaitingResponse;
    const thread = applicationStore.getState().messageSlice.threadData;
    if (!(!responseAwaiting && !(thread?.document?.google_doc_id || thread?.document?.url))) {
      return;
    }

    var message = createMessage("draft", "submit-and-create-document", { thread_id: threadsData.id });
    sendMessage(message, true);
    setSteps(["Analyzing your request", "Conducting legal research", "Drafting your document"]);
    dispatch(setSubmittingQnA(true));
  };
  const answeredIndexes = Object.keys(questionsAnswered)
    .filter((key) => questionsAnswered[key] !== "")
    .map((key) => parseInt(key));

  const confirmSkipAllQuestion = () => {
    dispatch(setSkipAllQuestionConfirm(true));
  };
  return (
    <div className="" id="follow-up">
      <SkipAllQuestionsConfirmPopup submitFollowUpQuestions={submitFollowUpQuestions} dispatch={dispatch} />
      {/* {(questionsSkipped.length + questionsAnswered.length === questions.length ) && ( */}
      {true && (
        <>
          {/* messageIndex = index of this particular message in the chat 
                totalMessageCount = total number of messages in the chat. 

                This is required to check when the follow up questions should be fully visible, or when it should be collapsed
            */}

          <div className="flex flex-col w-[100%] mt-[20px]">
            {message?.message_text && (
              <div
                className={`flex rounded-b-[5px]
                                   ${
                                     message?.is_user_message === true
                                       ? "text-black font-[500] text-[24px] leading-[33.6px]"
                                       : "text-[16px] leading-[25.6px] text-[#333333]"
                                   }`}
              >
                <div className="chat">
                  {isFirstAiMessage && threadType === "draft" && (
                    <img src={DraftAvatar} alt="AI" className="w-[32px] h-[32px] mb-[16px]" />
                  )}
                  <Markdown remarkPlugins={[remarkGfm]}>{`${formatText(message?.message_text)}`}</Markdown>
                </div>
              </div>
            )}
          </div>
          {isDocumentGenerated ? (
            <PostAnswers
              questions={questions}
              questionsAnswered={questionsAnswered}
              answers={answers}
              skipped={skipped}
              setAnswers={setAnswers}
              isDocumentGenerated={isDocumentGenerated}
            />
          ) : (
            <>
              <div className="flex flex-col rounded-[12px]">
                {questions.map((question, index) => (
                  <QnA
                    key={index}
                    index={index}
                    activeIndex={activeIndex}
                    setActiveIndex={setActiveIndex}
                    message={message}
                    submitAnswer={submitAnswer}
                    answers={answers}
                    setAnswers={setAnswers}
                    indexOfMessage={indexOfMessage}
                    awaitingResponse={awaitingResponse}
                    questionsAnswered={questionsAnswered}
                    questionsSkipped={questionsSkipped}
                    isDocumentGenerated={isDocumentGenerated}
                    threadsData={threadsData}
                    question={question}
                    answeredIndexes={answeredIndexes}
                    skipQuestion={skipQuestion}
                    skipped={skipped}
                    onRendered={() => onQnARendered(index)}
                  />
                ))}
              </div>
              {questions?.length > 0 && (
                <>
                  {!isRenderingComplete && !awaitingResponse && !isDocumentGenerated && !threadsData.isSharedThread && (
                    <div className="w-full flex justify-end mt-[12px]">
                      {Object.keys(questionsAnswered).length + questionsSkipped.length < questions.length - 1 && (
                        <>{submitButton}</>
                      )}
                      {Object.keys(questionsAnswered).length + questionsSkipped.length >= questions.length - 1 && (
                        <>{submitButton}</>
                      )}
                    </div>
                  )}
                </>
              )}
              {!isDocumentGenerated && !threadsData.isSharedThread && (
                <div className="w-full h-[58px] border-[1px] border-[#DCA218] flex items-center gap-[12px] px-[10px] py-[8px] rounded-[8px] bg-[#fff7e3] mt-[14px]">
                  <Lightbulb size={20} className="text-[#DCA218]" />
                  <p className="font-sans font-medium text-[13px] leading-[20px]">
                    Your responses will help me provide a more precise and relevant answer
                  </p>
                </div>
              )}
            </>
          )}
        </>
      )}
    </div>
  );
};

export default FollowupQuestionsPrompt;
