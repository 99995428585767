import React from "react";
import Button from "../Button";
import { Check } from "@phosphor-icons/react";

const SubscriptionCard = (props) => {
  const { data, billCycle, PLAN_TYPES, setPlan, checkoutUrlStatus } = props;

  const {
    name,
    titleClassName,
    borderClassName,
    title,
    icon,
    subTitle,
    pricing,
    paymentFrequency,
    paymentFrequencyText,
    featuresHeading,
    features,
    btnTitle,
    buttonClass,
    isHighlighted,
  } = data;

  return (
    <div
      className={`overflow-hidden w-[440px] rounded-xl border ${
        borderClassName && "outline outline-[4px] outline-[#ECF5F380] !border-[#248370]"
      } relative`}
    >
      {/* {badge.show && <div className={`absolute top-0 right-0 text-[10px] font-bold rounded-bl-xl py-2 px-4 bg-[#18475A14] uppercase`}></div>} */}
      <div className={`flex flex-col gap-[12px] px-6 pt-6 pb-3`}>
        <img src={icon} className="w-[32px] h-[32px]" />
        <p className="font-sans font-semibold text-[20px] leading-[30px]">{name}</p>
        <div>
          <p className="text-[#011513] font-[lora] text-[36px] leading-[50px]  font-bold">
            {pricing}{" "}
            <span className="font-normal text-[20px] leading-[44.8px] text-[#353535]">{paymentFrequency}</span>{" "}
          </p>
          <p className="text-[#686868] text-[10px] font-normal">{paymentFrequencyText}</p>
        </div>
      </div>
      <div className="px-4 mt-6">
        <p className="font-semibold text-[14px] text-[#444444]">{featuresHeading}</p>
        <ul className="">
          {features.map((feature) => (
            <li
              className={`text-sm  mt-3 font-normal flex items-center gap-[10px] text-[#353535] ${
                feature && "!font-bold"
              }`}
            >
              {" "}
              <span className="w-[16px] h-[16px] rounded-[48px]">
                <Check
                  size={14}
                  className={`mr-2 ${
                    name === "Ask Inhouse" ? "bg-[#FAFAFA]" : "bg-[#ECF5F3] text-[#248370]"
                  } rounded-full p-[2px]`}
                />
              </span>
              {feature}
            </li>
          ))}
        </ul>
      </div>
      <div className="absolute bottom-4 w-full px-[10px]">
        {btnTitle && (
          <div className="">
            <Button disabled={checkoutUrlStatus === "loading"} text={btnTitle} buttonClass={buttonClass} />
          </div>
        )}
      </div>
    </div>
  );
};

export default SubscriptionCard;
