import React from "react";
import LegalReviewRequestedSuccessfully from "../LegalReviewRequestedSuccessfully";
import { ReviewStatus } from "src/components/Constants";
import RequestReviewFormAside from "../RequestReviewFormAside";
import LegalReviewCompleteAsidePersonal from "./LegalReviewCompleteAsidePersonal";

const LegalReviewRequestPersonal = (props) => {
  const {
    email,
    trackAnalytics,
    dispatch,
    metadata,
    legalReiviewImg,
    requestLegalReviewStatus,
    text,
    setText,
    loading,
    setShowLearnMoreModal,
    phoneNumber,
    setPhoneNumber,
    legalReview,
    notice,
  } = props;
  return (
    <>
      {requestLegalReviewStatus === "success" ? (
        <LegalReviewRequestedSuccessfully metadata={metadata} dispatch={dispatch} email={email} />
      ) : (
        <>
          {" "}
          {legalReview ? (
            <>
              {/* // review already requested */}
              {legalReview?.status === ReviewStatus.in_progress ? (
                <LegalReviewRequestedSuccessfully email={email} dispatch={dispatch} metadata={metadata} />
              ) : (
                // review requested and is completed
                <LegalReviewCompleteAsidePersonal />
              )}
            </>
          ) : (
            // legal review is not yet requested
            <RequestReviewFormAside
              email={email}
              trackAnalytics={trackAnalytics}
              dispatch={dispatch}
              notice={notice}
              inputMandatory={false}
              text={text}
              setText={setText}
              loading={loading}
              setShowLearnMoreModal={setShowLearnMoreModal}
              legalReiviewImg={legalReiviewImg}
              phoneNumber={phoneNumber}
              setPhoneNumber={setPhoneNumber}
            />
          )}
        </>
      )}
    </>
  );
};

export default LegalReviewRequestPersonal;
