import React, { useState, useEffect, useRef } from "react";
import { Check } from "@phosphor-icons/react";

const DraftShimmer = ({ steps }) => {
  const [value1, setValue1] = useState("");
  const [value2, setValue2] = useState("");
  const [value3, setValue3] = useState("");
  const [step1, setStep1] = useState(null);
  const [step2, setStep2] = useState(null);
  const [step3, setStep3] = useState(null);

  const count = useRef(0);

  useEffect(() => {
    const interval = setInterval(() => {
      const options = [
        [30, 40, 80],
        [40, 50, 90],
        [50, 60, 80],
        [60, 70, 70],
        [70, 60, 60],
        [80, 50, 50],
        [90, 40, 40],
        [80, 50, 30],
        [70, 60, 40],
        [60, 70, 50],
        [50, 60, 60],
        [40, 50, 70],
      ];
      // var random = Math.floor(Math.random() * options.length);
      var option = options[count.current++ % options.length];
      setValue1(option[0]);
      setValue2(option[1]);
      setValue3(option[2]);
    }, 500);
    setTimeout(() => {
      setStep1(
        <div className="flex flex-row  my-auto gap-[10px]">
          <Check size={11.5} className="my-auto" />
          <p className="">{steps[0]}</p>
        </div>
      );
    }, 1000);
    setTimeout(() => {
      setStep1(
        <div className="flex flex-row  my-auto gap-[10px] text-[#333333]">
          <Check size={11.5} className="text-[#333333] my-auto" />
          <p className="">{steps[0]}</p>
        </div>
      );
      setStep2(
        <div className="flex flex-row  my-auto gap-[10px]">
          <Check size={11.5} />
          <p className="">{steps[1]}</p>
        </div>
      );
    }, 2000);
    setTimeout(() => {
      setStep1(
        <div className="flex flex-row text-[#333333] my-auto gap-[10px]">
          <Check size={11.5} className="text-[#333333] my-auto" />
          <p className="">{steps[0]}</p>
        </div>
      );
      setStep2(
        <div className="flex flex-row text-[#333333] my-auto gap-[10px]">
          <Check size={11.5} className="text-[#333333] my-auto" />
          <p className="">{steps[1]}</p>
        </div>
      );
      setStep3(
        <div className="flex flex-row  my-auto gap-[10px]">
          <Check size={11.5} className="my-auto" />
          <p className="text-black">{steps[2]}</p>
        </div>
      );
    }, 3000);

    return () => clearInterval(interval);
  }, []);

  return (
    <div className="flex flex-col gap-[6px]">
      {step1}
      {step2}
      {step3}
      <div className={`mt-[3px] md:mt-[12px] flex h-[30px] md:h-[40px] w-${value1} draft-gradient `}></div>
      <div className={`mt-[3px] md:mt-[12px] flex h-[30px] md:h-[40px] w-${value2} draft-gradient `}></div>
      <div className={`mt-[3px] md:mt-[12px] flex h-[30px] md:h-[40px] w-${value3} draft-gradient `}></div>
    </div>
  );
};

export default DraftShimmer;
