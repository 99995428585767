import { ChatsTeardrop } from "@phosphor-icons/react";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";
import { ArrowUpRight } from "@phosphor-icons/react/dist/ssr";
import { Link } from "react-router-dom";

const ReferenceThreadPrompt = (props) => {
  const dispatch = useDispatch();
  const [referenceThread, setReferenceThread] = useState({});
  const [askUrl, setAskUrl] = useState("");

  const threadData = useSelector((state) => state.messageSlice.threadData);
  const threads = useSelector((state) => state.threads.threads);

  useEffect(() => {
    var reference_thread_id = threadData?.reference_thread_id;
    if (!reference_thread_id) {
      return;
    }
    if (threadData?.isSharedThread) {
      setAskUrl(`/ask/shared/${reference_thread_id}`);
    } else {
      setAskUrl(`/ask/${reference_thread_id}`);
    }
    var lengthResults = 0;
    Object.keys(threads).forEach((key) => {
      threads[key].filter((item) => {
        const match = item.id === reference_thread_id;
        if (match) {
          setReferenceThread(item);
          lengthResults += 1;
        }
      });
      if (lengthResults > 0) {
        return;
      }
    });
  }, [threads, threadData]);

  return (
    <>
      {referenceThread?.id ? (
        <div className="w-auto">
          <div className="gap-[8px] flex flex-col py-[16px] px-[20px] border rounded-[12px] border-[#E4E4E4] bg-white">
            <span>Using thread context from:</span>
            <div className="rounded-[12px] flex-col gap-[4px] py-[12px] px-[16px] flex bg-[#F2F2F280]  cursor-pointer">
              <div className="flex flex-row gap-[8px] py-[16px]">
                <ChatsTeardrop size={13} className="text-[#A843AA] my-auto" />
                <Link to={`/ask/${referenceThread?.id}`} className="flex flex-row gap-[30px]">
                  <div className="flex flex-row gap-[30px]">
                    <p className="font-sans font-semibold text-[14px] text-[#686868] my-auto">
                      {referenceThread?.title}
                    </p>
                    <ArrowUpRight size={16} className="text-[#353535]" />
                  </div>
                </Link>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <></>
      )}
    </>
  );
};

export default ReferenceThreadPrompt;
