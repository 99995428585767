import { Briefcase } from "@phosphor-icons/react";
import React from "react";
import { useDispatch } from "react-redux";
import { legalReviewButtonClickedIntent, setShowRequestLegalReviewAside } from "src/pages/onboarding/onboardingSlice";
import TalkToDedicatedLawyerButton from "./TalkToDedicatedLawyerButton";
import { useSelector } from "react-redux";
import { trackAnalytics } from "src/pages/helpers";

const RequestReviewButton = ({ threadData }) => {
  const email = useSelector((state) => state.homeslice.email);
  const userMetadata = useSelector((state) => state.onboarding.userMetadata);
  const userConfig = useSelector((state) => state.onboarding.userConfig);
  const dispatch = useDispatch();
  return (
    <>
      {userMetadata?.lawyer_info?.name ? (
        <TalkToDedicatedLawyerButton userMetadata={userMetadata} />
      ) : (
        <button
          className=" text-[#18475A] border w-[158x] h-[30px] md:h-[48px] rounded-[8px] gap-[7px] md:gap-[12px] px-[12px] py-[8px] flex flex-row justify-center items-center"
          onClick={() => {
            trackAnalytics(
              "chatpage:legal_review_button_clicked",
              { threadId: threadData.id, user_email: email },
              userMetadata
            );
            dispatch(setShowRequestLegalReviewAside(true));
            dispatch(legalReviewButtonClickedIntent({ threadId: threadData.id }));
          }}
        >
          <span className="my-auto">
            <Briefcase size={18} className="text-[#011513 my-auto" />
          </span>
          <span className="text-[#011513] my-auto text-[10px] md:text-[14px] md:leading-[19.6px] font-semibold">
            Request Review
          </span>
        </button>
      )}
    </>
  );
};

export default RequestReviewButton;
