import { ChatsCircle, Lightning, PenNib, List, DotsThreeVertical } from "@phosphor-icons/react";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setShowUpgradePlanNudge } from "src/pages/onboarding/onboardingSlice";
import { resetPaymentStatus, setCheckoutUrl } from "../chatSlice";
import { trackAnalytics } from "src/pages/helpers";
import { generateSharedId, setThreadSelectedToShare } from "../threadsSlice";
import ShareThreadModal from "src/components/chat/Modals/ShareThreadModal";
import { ReviewStatus, PLANS, PLANS_V2 } from "src/components/Constants";
import ShareButton from "./ShareButton";
import RequestReviewButton from "./RequestReviewButton";
import UpgradeToPremiumButton from "./UpgradeToPremiumButton";
import TasksLeftText from "./TasksLeftText";
import TalkToLawyerButton from "./TalkToLawyerButton";
import TalkToDedicatedLawyerButton from "./TalkToDedicatedLawyerButton";
import ReviewPendingButton from "./ReviewPendingButton";
import ReviewCompletedButton from "./ReviewCompletedButton";
import CallScheduled from "./CallScheduled";
import inhouseImg from "../../../images/inhouse-rounded-logo.svg";
import { setMobileSideNav } from "src/pages/Homepage/HomeSlice";
import { resetUpdateActions } from "../threadsSlice";
import { userData } from "src/components/Constants";
import { PLANSV2 } from "src/components/Constants";
import ReviewRequestedButton from "./ReviewRequestedButton";
const TopNav = ({ page, showSteps }) => {
  const dispatch = useDispatch();
  const [text, setText] = React.useState("");
  const [duration, setDuration] = React.useState("");
  const [actionOnThread, setActionOnThread] = React.useState(""); // actions that can be done on a thread i.e Rename, Delete, Share
  const [updateActionLoading, setUpdateActionLoading] = React.useState(false);
  const [icon, setIcon] = React.useState(
    <span>
      <ChatsCircle size={16} className="text-[#C61DCA]" />
    </span>
  );

  const currentDate = new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate());
  const options = { weekday: "long", day: "numeric", month: "short", year: "numeric" };
  const formattedDate = currentDate.toLocaleDateString("en-US", options);

  const threads = useSelector((state) => state.threads.threads);
  const userMetadata = useSelector((state) => state.onboarding.userMetadata);
  const threadData = useSelector((state) => state.messageSlice.threadData);
  const email = useSelector((state) => state.onboarding.email);
  const lawyerHubData = useSelector((state) => state.onboarding.lawyerHubData);
  const threadTitleTop = useSelector((state) => state.messageSlice.threadTitleTop);
  const showRequestLegalReviewAside = useSelector((state) => state.onboarding.showRequestLegalReviewAside);
  const viewChatHistory = useSelector((state) => state.homeslice.viewChatHistory);
  var legalReview = lawyerHubData?.find((item) => item.thread_id === threadData.id);
  const userConfig = useSelector((state) => state.onboarding.userConfig);
  useEffect(() => {
    if (page === "chat" && Object.keys(threads).length > 0) {
      var keyName = "";
      for (var key in threads) {
        var index = threads[key].find((thread) => thread.id === threadData.id);
        if (index) {
          keyName = key;
          break;
        }
      }
      if (keyName) {
        setDuration(key);
      }
    }
  }, [page, threads]); // eslint-disable-line

  useEffect(() => {
    if (window.location.pathname.search("draft") > -1) {
      setIcon(
        <span>
          <PenNib size={16} className="text-[#13866F]" />
        </span>
      );
    } else {
      setIcon(
        <span>
          <ChatsCircle size={16} className="text-[#C61DCA]" />
        </span>
      );
    }
    if (page !== "ask_home") {
      if (threadData?.title) {
        setText(threadData.title);
      } else if (threadData?.isSharedThread || threadData.is_shared) {
        setText(threadData?.title);
      } else {
        if (threadTitleTop && threadTitleTop?.title) {
          setText(threadTitleTop.title);
        } else {
          if (threadData?.metadata?.created_at) {
            setText(new Date(threadData?.metadata?.created_at).toLocaleDateString("en-US", options));
          } else {
            setText(formattedDate);
          }
        }
      }
    } else {
      setText(formattedDate);
    }
  }, [threadData]); // eslint-disable-line

  const handleMobileSideNav = () => {
    dispatch(setMobileSideNav(true));
  };

  const openUpgradeModal = () => {
    trackAnalytics("subscription popup triggered", { position: "topnav", user_email: email }, userMetadata);
    trackAnalytics("dashboard_home_upgrade", { user_email: email }, userMetadata);
    dispatch(setCheckoutUrl(""));
    dispatch(resetPaymentStatus());
    dispatch(setShowUpgradePlanNudge(true));
  };

  const shareThread = () => {
    setActionOnThread("Share");
    trackAnalytics("thread shared: topnav", { thread_id: threadData.id, user_email: email }, userMetadata);
    dispatch(setThreadSelectedToShare(threadData.id));
    dispatch(generateSharedId({ thread: threadData, duration: duration }));
  };
  // const showBanner = () => {
  //   const FreeTierButton = userData.homepage.topnav.free_tier;
  //   const InhouseProButton = userData.homepage.topnav.subscriber_enterprise;
  //   const InhouseCounselButton = userData.homepage.topnav.Inhouse_COUNSEL;
  //   switch (userMetadata?.subscription_type) {
  //     case "":
  //     case null:
  //     case undefined:
  //       return <></>;
  //     case PLANSV2.free_tier:
  //       return (
  //         <div className="hidden md:flex flex-row gap-[16px] ">
  //           <FreeTierButton dispatch={dispatch} metadata={userMetadata} email={email} page={page} />
  //         </div>
  //       );
  //       case PLANSV2.subscriber_enterprise:
  //         return (
  //           <div className="hidden md:flex flex-row gap-[16px] ">
  //                {/* {userData?.homepage?.topnav?.subscriber_enterprise(dispatch, userMetadata, email, page)} */}
  //                <InhouseProButton dispatch={dispatch} metadata={userMetadata} email={email} page={page} />
  //           </div>
  //         );
  //         case PLANSV2.inhouse_counsel:
  //           return (
  //             <div className="hidden md:flex flex-row gap-[16px] ">
  //               {/* {userData?.homepage?.topnav?.Inhouse_COUNSEL(dispatch, userMetadata, email, page)} */}
  //               <InhouseCounselButton dispatch={dispatch} metadata={userMetadata} email={email} page={page} />
  //             </div>
  //           );
  //     default:
  //       return <></>;
  //   }
  //   //case payment_status = "starter/business/enterprise"
  // };

  if (page === "home") {
    return (
      <div className="fixed top-0 w-full md:static flex flex-col z-[1]">
        <div className="flex justify-between items-center h-[54px] md:h-[74px] bg-[#FFFFFF] md:bg-transparent md:justify-end w-full py-[16px] mx-auto px-[12px] md:px-[48px] gap-[16px]">
          <div className="w-[80px] flex justify-start items-center gap-[12px]">
            <List onClick={handleMobileSideNav} size={26} className="my-4 md:hidden" />
            <img src={inhouseImg} alt="Inhouse logo" className="w-[24px] h-[24px] md:hidden" />
          </div>
          {userConfig?.homepage?.topnav({ dispatch, userMetadata, email, page })}
        </div>
        {/* {!showRequestLegalReviewAside &&
          !viewChatHistory &&
          userMetadata.subscription_type !== PLANS.PERSONAL_FREE &&
          userMetadata.subscription_type !== PLANS.SUBSCRIBER_ENTERPRISE && (
            <div className="md:hidden w-full h-[41px] bg-[#fbf6e7] flex justify-center items-center border-[1px] border-[#E4E4E4] p-[12px]">
              {showPlansMobileView()}
            </div>
          )} */}
      </div>
    );
  } else if (page === "onboarding") {
    return (
      <div className="fixed top-0 w-full md:static flex flex-col ">
        <div className="flex justify-between items-center h-[54px] md:h-[74px] bg-[#FFFFFF] md:bg-transparent md:justify-end w-full py-[16px] mx-auto px-[12px] md:px-[48px] gap-[16px]">
          <div className="flex justify-center items-center gap-[12px]">
            <List onClick={handleMobileSideNav} size={26} className="my-4 mx-2 md:hidden" />
            <img src={inhouseImg} alt="Inhouse logo" className="w-[24px] h-[24px] md:hidden" />
          </div>
          {showSteps()}
        </div>
      </div>
    );
  } else if (page === "ask_home" || page === "chat") {
    var legalReview = lawyerHubData?.find((item) => item.thread_id === threadData.id);

    var meetingScheduled = false;
    if (userMetadata?.subscription_type === PLANS.PERSONAL_FREE && legalReview?.lr_lawyer_matching_meeting_time) {
      meetingScheduled = true;
    } else if (userMetadata?.lawyer_matching_meeting_at) {
      meetingScheduled = true;
    }
    return (
      <div className="fixed top-0 w-full md:static flex flex-row h-[54px] md:h-[74px] shadow-sm md:!shadow-none ">
        <div className="flex justify-between w-full py-[12px] border-b border-[#E4E4E4] px-[24px] bg-white md:!bg-[#FCFCFA]">
          <List onClick={handleMobileSideNav} size={26} className="md:hidden" />
          <h1 className="hidden md:flex flex-row gap-[8px] my-auto py-auto">
            {icon}
            <span className="text-[14px] leading-[21px] font-[500] my-auto">{text}</span>
          </h1>
          {page === "chat" && (
            <div className="flex justify-between gap-[16px]">
              {!legalReview ? (
                <>{!threadData?.isSharedThread && <RequestReviewButton threadData={threadData} />}</>
              ) : (
                <>
                  <ReviewRequestedButton />
                </>
              )}
              <div className="hidden md:block">
                <ShareButton shareThread={shareThread} />
              </div>
              <div className="md:hidden flex justify-center items-center border w-[30px] h-[30px] rounded-[6px]">
                <DotsThreeVertical size={32} />
              </div>
            </div>
          )}
          {page === "ask_home" && (
            <div className="flex justify-between gap-[16px]">
              <div className="md:hidden flex justify-center items-center border w-[30px] h-[30px] rounded-[6px]">
                <DotsThreeVertical size={32} />
              </div>
            </div>
          )}
          <ShareThreadModal
            open={actionOnThread === "Share"}
            setActionOnThread={setActionOnThread}
            actionOnThread="share"
            threadInfo={{ thread: threadData }}
            loading={updateActionLoading}
            setLoading={setUpdateActionLoading}
          />
        </div>
      </div>
    );
  } else {
    return <div className="flex flex-row w-full h-full py-auto"></div>;
  }
};

export default TopNav;
