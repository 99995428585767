import { VideoCamera } from "@phosphor-icons/react";
import lawyerReviewPendingImg from "../../../images/businesslawyerReviewPending.svg";
import { useState } from "react";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { setShowRequestLegalReviewAside, setShowScheduleMeeting } from "src/pages/onboarding/onboardingSlice";

const LawyerassignmentPending = () => {
  const email = useSelector((state) => state.homeslice.email);
  const userMetadata = useSelector((state) => state.onboarding.userMetadata);
  const meetingTime = userMetadata?.lawyer_matching_meeting_at
    ? new Date(userMetadata?.lawyer_matching_meeting_at + "Z").toLocaleString("en-US", {
        day: "2-digit",
        month: "long",
        year: "numeric",
        hour: "2-digit",
        minute: "2-digit",
        hour12: true,
      })
    : "";

  const dispatch = useDispatch();
  const scheduleCall = () => {
    dispatch(setShowScheduleMeeting({ show: true, lawyer_details: {} }));
    dispatch(setShowRequestLegalReviewAside(false));
  };

  return (
    <div className="w-full py-[0px] flex flex-col justify-center items-center  gap-[16px]">
      <div className="flex flex-col gap-[16px] justify-center items-center">
        <img
          src={lawyerReviewPendingImg}
          alt="lawyerReviewPendingImg"
          className="w-[243px] h-[246px] md:w-[300px] md:h-[291px]"
        />
        <p className="font-sans text-[18px] md:text-[24px] font-semibold text-center text-[#353535]">
          Assigning an Expert Lawyer <br /> to review your request
        </p>
        <p className="font-normal font-sans text-[12px] md:text-[14px] text-center leading-[21px] text-[#353535]">
          We’re pairing you with a top-rated lawyer <br /> who is best suited to your business needs.
          <br />
          <br />
        </p>
      </div>

      {!userMetadata?.lawyer_matching_meeting_at ? (
        <div className="w-full flex flex-col justify-center items-center mb-[50px] gap-[16px]">
          <p className="font-normal font-sans text-[14px] text-center leading-[21px] text-[#353535]">
            You’ll be notified on <span className="text-[#18475A] font-medium">{email}</span> <br />
            once the lawyer is assigned.
          </p>
          <hr className="w-full my-4" />
          <p className="text-[#353535] font-sans font-normal text-[12px] md:text-[14px] text-center leading-[21px]">
            To expedite the process, you can reach out to our legal support team on{" "}
            <span className="font-semibold text-[#18475A]"> support@inhouse.app </span> or setup a call
          </p>

          <button
            className="rounded-[8px] border border-[#D1D1D1] px-[24px] py-[12px] flex gap-[8px] justify-center items-center"
            onClick={scheduleCall}
          >
            <span className="text-[#264653] font-sans text-[14px] font-medium">Schedule a Call</span>
            <VideoCamera size={32} className="w-[16px] h-[16px] text-[#264653]" />
          </button>
        </div>
      ) : (
        <div className="w-[434px] h-[133px] bg-[#ECF5F3B2] rounded-[12px] px-[64px] py-[20px] flex flex-col gap-[24px]">
          <div className="flex flex-col gap-[4px]">
            <p className="font-normal text-[12px] md:text-[14px] font-sans leading-[21px] text-center text-[#353535]">
              {" "}
              Call scheduled with the legal support team on
            </p>
            <p className="font-semibold text-[16px] md:text-[18px] font-sans leading-[28px] text-center text-[#353535]">
              {meetingTime}
            </p>
          </div>
          <p className="font-medium text-[10px] font-sans leading-[15px] text-center text-[#353535]">
            You can check your e-mail <span className="text-[#18475A]">{email}</span> for meeting details
          </p>
        </div>
      )}
    </div>
  );
};

export default LawyerassignmentPending;
