import React from "react";
import ToggleSwitch from "../components/ToggleSwitch";
import Button from "../Button";
import USFlag from "src/images/us-flag.png";
import { ArrowUpRight, ShieldCheck } from "@phosphor-icons/react";
import { useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import { PLANS } from "src/components/Constants";
import { isDraft } from "@reduxjs/toolkit";
const RequestReviewFormAside = (props) => {
  const {
    email,
    trackAnalytics,
    setShowLearnMoreModal,
    dispatch,
    legalReiviewImg,
    text,
    setText,
    loading,
    phoneNumber,
    setPhoneNumber,
    notice,
  } = props;

  const location = useLocation();
  const [isFieldInFocus, setIsFieldInFocus] = React.useState(false);

  const [title, setTitle] = React.useState("");
  const [subTitle, setSubTitle] = React.useState("");
  const [bannerImage, setBannerImage] = React.useState();
  const [banner, setBanner] = React.useState();
  const [formLabelText, setFormLabelText] = React.useState("");
  const [formPlaceholderText, setFormPlaceholderText] = React.useState("");
  const [learnMoreText, setLearnMoreText] = React.useState("");

  const userMetadata = useSelector((state) => state.onboarding.userMetadata);
  // const lawyerHubData = useSelector((state) => state.onboarding.lawyerHubData);
  // const threadData = useSelector((state) => state.messageSlice.threadData);
  // let legalReview = lawyerHubData?.find((item) => item.thread_id === threadData.id);
  const requestLegalReviewStatus = useSelector((state) => state.onboarding.requestLegalReviewStatus);

  React.useEffect(() => {
    var isAskThread = location.pathname.indexOf("/ask/") !== -1;
    var isDraftThread = location.pathname.indexOf("/draft/") !== -1;
    var personal = {
      home: {
        label: "Include any details the lawyer should know",
        placeholder: "Enter details about your legal needs here",
        banner: (
          <>
            <img src={legalReiviewImg} alt="legalReviewImg" className="my-[24px]" />
            <div className="w-full px-8 gap-[24px] pr-[50px]">
              <h6 className="text-[#011513] text-[16px] md:text-[18px] font-semibold leading-[28.8px]">
                Consult an expert lawyer — Fast, Affordable, <br />
                and Specific to your needs
              </h6>
              <p className="text-[#353535] text-[14px] leading-[19.6px] font-normal mt-2">
                We will connect you with a lawyer specialized for your needs.
                <span
                  className="text-[#18475A] cursor-pointer underline"
                  onClick={() => {
                    trackAnalytics("learn more clicked from Request a Lawyer Review aside", { user_email: email });
                    dispatch(setShowLearnMoreModal(true));
                  }}
                >
                  Know More
                </span>
              </p>
            </div>
          </>
        ),
      },
      thread: {
        label: "Additional details",
        placeholder: "Enter additional context to the thread and your legal needs here",
        banner: (
          <>
            <img src={legalReiviewImg} alt="legalReviewImg" className="my-[24px]" />
            <div className="w-full px-8 gap-[24px] pr-[50px]">
              <h6 className="text-[#011513] text-[16px] md:text-[18px] font-semibold">
                Consult an expert lawyer — <br />
                Fast and Affordable
              </h6>
              <p className="text-[#353535] text-[14px] leading-[19.6px] font-normal mt-2">
                The chat will be sent to the specialized lawyer for context.{" "}
                <span
                  className="text-[#18475A] cursor-pointer underline"
                  onClick={() => {
                    trackAnalytics("learn more clicked from Request a Lawyer Review aside", { user_email: email });
                    dispatch(setShowLearnMoreModal(true));
                  }}
                >
                  Know More
                </span>
              </p>
            </div>
          </>
        ),
      },
    };

    var business = {
      lawyer: {
        home: {
          label: "Additional details",
          placeholder: "Enter additional context to the thread and your legal needs here",
          banner: (
            <>
              <img src={legalReiviewImg} alt="legalReviewImg" className="my-[24px]" />
              <div className="w-full px-8 gap-[24px] pr-[50px]">
                <h6 className="text-[#011513] text-[16px] md:text-[18px] font-semibold leading-[28.8px]">
                  Talk to your dedicated lawyer — <br />
                  for legal guidance on your specific needs
                </h6>
                <p className="text-[#353535] text-[14px] leading-[19.6px] font-normal mt-2">
                  We will connect you with a lawyer specialized for your needs.{" "}
                  <span
                    className="text-[#18475A] cursor-pointer underline"
                    onClick={() => {
                      trackAnalytics("learn more clicked from Request a Lawyer Review aside", { user_email: email });
                      dispatch(setShowLearnMoreModal(true));
                    }}
                  >
                    Know More
                  </span>
                </p>
              </div>
            </>
          ),
        },
        thread: {
          label: "Additional details",
          placeholder: "Enter additional context to the thread and your legal needs here",
          banner: (
            <>
              <img src={legalReiviewImg} alt="legalReviewImg" className="my-[24px]" />
              <div className="w-full px-8 gap-[24px] pr-[50px]">
                <h6 className="text-[#011513] text-[16px] md:text-[18px] font-semibold leading-[28.8px]">
                  Consult an expert lawyer — <br />
                  Fast and Affordable
                </h6>
                <p className="text-[#353535] text-[14px] leading-[19.6px] font-normal mt-2">
                  The chat will be sent to the specialized lawyer for context.{" "}
                  <span
                    className="text-[#18475A] cursor-pointer underline"
                    onClick={() => {
                      trackAnalytics("learn more clicked from Request a Lawyer Review aside", { user_email: email });
                      dispatch(setShowLearnMoreModal(true));
                    }}
                  >
                    Know More
                  </span>
                </p>
              </div>
            </>
          ),
        },
      },
      noLawyer: {
        home: {
          label: "Include any details the lawyer should know  ",
          placeholder: "Enter your legal needs and requirements here",
          banner: (
            <>
              <img src={legalReiviewImg} alt="legalReviewImg" className="my-[24px]" />
              <div className="w-full px-8 gap-[24px] pr-[50px]">
                <h6 className="text-[#011513] text-[16px] md:text-[18px] font-semibold leading-[28.8px]">
                  Consult an expert lawyer — <br />
                  Fast and Affordable
                </h6>
                <p className="text-[#353535] text-[14px] leading-[19.6px] font-normal mt-2">
                  We will connect you with a lawyer specialized for your needs.{" "}
                  <span
                    className="text-[#18475A] cursor-pointer underline"
                    onClick={() => {
                      trackAnalytics("learn more clicked from Request a Lawyer Review aside", { user_email: email });
                      dispatch(setShowLearnMoreModal(true));
                    }}
                  >
                    Know More
                  </span>
                </p>
              </div>
            </>
          ),
          learnMoreLine: (
            <p>
              We will connect you with a lawyer specialized for your needs.{" "}
              <span
                className="text-[#18475A] cursor-pointer underline"
                onClick={() => {
                  trackAnalytics("learn more clicked from Request a Lawyer Review aside", { user_email: email });
                  dispatch(setShowLearnMoreModal(true));
                }}
              >
                Know More
              </span>
            </p>
          ),
        },
        thread: {
          label: "Additional details",
          placeholder: "Enter additional context to the thread and your legal needs here",
          banner: (
            <>
              <img src={legalReiviewImg} alt="legalReviewImg" className="my-[24px]" />
              <div className="w-full px-8 gap-[24px] pr-[50px]">
                <h6 className="text-[#011513] text-[16px] md:text-[18px] font-semibold leading-[28.8px]">
                  {isDraftThread && <>Finalize this draft with an expert Lawyer</>}
                  {isAskThread && (
                    <>
                      Consult an expert lawyer — <br />
                      Fast and Affordable
                    </>
                  )}
                </h6>
                <p className="text-[#353535] text-[14px] leading-[19.6px] font-normal mt-2">
                  The chat will be sent to the specialized lawyer for context.{" "}
                  <span
                    className="text-[#18475A] cursor-pointer underline"
                    onClick={() => {
                      trackAnalytics("learn more clicked from Request a Lawyer Review aside", { user_email: email });
                      dispatch(setShowLearnMoreModal(true));
                    }}
                  >
                    Know More
                  </span>
                </p>
              </div>
            </>
          ),
          learnMoreLine: (
            <p>
              The chat will be sent to the specialized lawyer for context.{" "}
              <span
                className="text-[#18475A] cursor-pointer underline"
                onClick={() => {
                  trackAnalytics("learn more clicked from Request a Lawyer Review aside", { user_email: email });
                  dispatch(setShowLearnMoreModal(true));
                }}
              >
                Know More
              </span>
            </p>
          ),
        },
      },
    };
    // homepage or thread page
    // personal user or business user
    // lawyer info available or not
    if (window.location.pathname === "/home") {
      if (userMetadata?.subscription_type === PLANS.PERSONAL) {
        setFormLabelText(personal.home.label);
        setFormPlaceholderText(personal.home.placeholder);
        setLearnMoreText(personal.home.learnMoreLine);
        setBanner(personal.home.banner);
      } else {
        if (userMetadata?.lawyer_info) {
          // banner here
          let lawyer = userMetadata?.lawyer_info;
          let newbanner = (
            <>
              <div className="w-full px-8 flex flex-col gap-[24px] pr-[50px]">
                <div className="flex flex-row gap-[24px]">
                  <img
                    src={lawyer?.pre_signed_image_url}
                    alt={lawyer?.name}
                    className="w-[64px] h-[64px] rounded-full"
                  />
                  <div className="flex flex-col h-[44px] my-auto">
                    <p className="leading-[25.6px] text-[16px] font-[600] text-[#18475A]">{lawyer?.name}</p>
                    <p className="flex flex-row text-[12px] font-[500] leading-[18px] text-[#949494] gap-[4px]">
                      <ShieldCheck size={16} className="text-[#B6B6B6]" />
                      <span>Verified InHouse Counsel</span>
                    </p>
                  </div>
                </div>
                <h6 className="text-[#011513] text-[16px] md:text-[18px] font-semibold">
                  Talk to your dedicated lawyer — {lawyer?.name} <br />
                  for legal guidance on your specific needs
                </h6>
              </div>
            </>
          );
          setFormLabelText(business.lawyer.home.label);
          setFormPlaceholderText(business.lawyer.home.placeholder);
          setLearnMoreText(business.lawyer.home.learnMoreLine);
          setBanner(newbanner);
        } else {
          setFormLabelText(business.noLawyer.home.label);
          setFormPlaceholderText(business.noLawyer.home.placeholder);
          setLearnMoreText(business.noLawyer.home.learnMoreLine);
          setBanner(business.noLawyer.home.banner);
        }
      }
    } else {
      // chatpage
      if (userMetadata?.subscription_type === PLANS.PERSONAL) {
        setFormLabelText(personal.thread.label);
        setFormPlaceholderText(personal.thread.placeholder);
        setLearnMoreText(personal.thread.learnMoreLine);
        setBanner(personal.thread.banner);
      } else {
        if (userMetadata?.lawyer_info) {
          // banner here
          let lawyer = userMetadata?.lawyer_info;
          let newbanner = (
            <>
              <div className="w-full px-8 flex flex-col gap-[24px] pr-[50px]">
                <div className="flex flex-row gap-[24px]">
                  <img
                    src={lawyer?.pre_signed_image_url}
                    alt={lawyer?.name}
                    className="w-[64px] h-[64px] rounded-full"
                  />
                  <div className="flex flex-col h-[44px] my-auto">
                    <p className="leading-[25.6px] text-[16px] font-[600] text-[#18475A]">{lawyer?.name}</p>
                    <p className="flex flex-row text-[12px] font-[500] leading-[18px] text-[#949494] gap-[4px]">
                      {" "}
                      <ShieldCheck size={16} className="text-[#B6B6B6]" />
                      <span>Verified InHouse Counsel</span>
                    </p>
                  </div>
                </div>
                <h6 className="text-[#011513] text-[16px] md:text-[18px] font-semibold">
                  Talk to your dedicated lawyer — {lawyer?.name} <br />
                  for legal guidance on your specific needs
                </h6>
              </div>
            </>
          );
          setFormLabelText(business.lawyer.thread.label);
          setFormPlaceholderText(business.lawyer.thread.placeholder);
          setBanner(newbanner);
          setLearnMoreText(business.lawyer.thread.learnMoreLine);
        } else {
          setFormLabelText(business.noLawyer.thread.label);
          setFormPlaceholderText(business.noLawyer.thread.placeholder);
          setBanner(business.noLawyer.thread.banner);
          setLearnMoreText(business.noLawyer.thread.learnMoreLine);
        }
      }
    }
  }, []);

  return (
    <section className="w-full">
      <div id="Before-request">
        {location.pathname !== "/home" && userMetadata?.lawyer_info ? (
          <></>
        ) : (
          <div className="">
            <div className="relative flex flex-col py-12 border-[#18475A29] bg-[#F5FAF9]">
              <div>{banner}</div>
            </div>

            <div className="px-8 flex flex-col gap-[24px]">
              <div className="flex flex-col">
                <p className="text-[16px] font-[700] font-sans text-[#353535] mt-4">{formLabelText}</p>

                <textarea
                  placeholder={formPlaceholderText}
                  className="border px-3 py-3 h-[130px] border-[#D1D1D1] w-full mt-2 rounded-lg"
                  disabled={loading}
                  value={text}
                  onChange={(e) => setText(e.target.value)}
                />
                {notice.description && <p className="text-red-500 my-2">{notice.description}</p>}
              </div>
              <div className="w-full relative">
                <div className="flex flex-col gap-[12px] mb-[40px]">
                  <div className="flex flex-col">
                    <p className="font-sans font-[600] text-[16px] leading-[25.6px] text-[#353535]">
                      Phone number <sup class="text-red-500 font-[600]">*</sup>
                    </p>
                    <p className="text-[12px] leading-[18px] font-[400] text-[#686868]">
                      This helps us reach out to you to connect you with a lawyer.
                    </p>
                  </div>
                  <div className="gap-[5px] flex flex-col">
                    <div className="w-full h-[52px] border flex items-center rounded-[8px] bg-white">
                      <div
                        className="px-[12px] text-[14px] font-normal w-[58px] text-sans text-[#686868] rounded-[8px] flex flex-row items-center gap-[4px]"
                        title="Currently available only in the USA"
                      >
                        <img src={USFlag} className="w-[15px]" alt="🇺🇸" />
                        <span>+1</span>
                      </div>
                      <input
                        type="number"
                        name="telephone"
                        placeholder="Enter phone number"
                        className="border-l px-[12px] h-full w-full text-[14px] font-normal font-sans rounded-r-[8px]"
                        value={phoneNumber}
                        onBlur={() => {
                          setIsFieldInFocus(false);
                        }}
                        onFocus={() => {
                          setIsFieldInFocus(true);
                        }}
                        onChange={(e) => setPhoneNumber(e.target.value)}
                      />
                    </div>
                    {notice.phoneNumber && <p className="text-red-500">{notice.phoneNumber}</p>}
                  </div>
                </div>
                {/* {notice.error && <p className="text-red-500 my-2">{notice.error}</p>} */}
                {requestLegalReviewStatus === "failed" && (
                  <p className="text-red-500 mt-[-20px] mb-[20px]">Failed to request legal review</p>
                )}
              </div>
            </div>
          </div>
        )}
      </div>
    </section>
  );
};

export default RequestReviewFormAside;
