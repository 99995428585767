import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { extractErrors } from "../helpers";
import axios from "axios";
// import toast from "react-hot-toast";

// import { attemptAuthenticationRefresh } from "../UserAuthentication/AuthenticationSlice";
import { message } from "antd";
import { ErrorLogging } from "src/components/DocumentViewer/helpers";

const BASE_ENDPOINT = process.env.REACT_APP_BACKEND_BASE_ENDPOINT;

export const getPaymentStatus = createAsyncThunk("chat/getPaymentStatus", async (data, thunkAPI) => {
  const accessToken = localStorage.getItem("accessToken2");
  const url = `${BASE_ENDPOINT}/api/user/check-payment-status/${data.payment_id}`;
  const headers = { Authorization: `Bearer ${accessToken}` };
  try {
    const response = await axios.get(url, { headers: headers });
    return {
      response: response.data,
    };
  } catch (error) {
    return thunkAPI.rejectWithValue({ error: error });
  }
});

export const getCheckoutUrl = createAsyncThunk("chat/getCheckoutUrl", async (data, thunkAPI) => {
  const accessToken = localStorage.getItem("accessToken2");
  const url = `${BASE_ENDPOINT}/api/user/get-checkout-url`;
  const headers = { Authorization: `Bearer ${accessToken}` };
  try {
    const response = await axios.post(url, data, { headers: headers });
    return {
      response: response.data,
    };
  } catch (error) {
    return thunkAPI.rejectWithValue({ error: error });
  }
});

export const getRetainer = createAsyncThunk("chat/getRetainer", async (data, thunkAPI) => {
  var userType = localStorage.getItem("userType");
  const accessToken = localStorage.getItem("accessToken2");
  var url;
  if (userType === "lawyer") {
    url = `${BASE_ENDPOINT}/api/v0/lawyer/${data.clientId}/get-retainer-embed-url`;
  } else {
    url = `${BASE_ENDPOINT}/api/v0/user/get-retainer-embed-url`;
  }
  const headers = { Authorization: `Bearer ${accessToken}` };

  try {
    const response = await axios.get(url, { headers: headers });
    return {
      response: response.data,
      request_data: data,
    };
  } catch (error) {
    return thunkAPI.rejectWithValue({ error: error });
  }
});

// export const getRetainer = createAsyncThunk("chat/getRetainer", async (data, thunkAPI) => {
//   const headers = { "X-API-KEY": `${BoldSignKey}` };
//   const url = `https://api.boldsign.com/v1/document/getEmbeddedSignLink?documentId=${data.retainerDocumentId}&signerEmail=${data.signerEmail}&redirectUrl=${data.redirectUrl}`;
//   try {
//     const response = await axios.get(url, { headers: headers });
//     console.log("fetching retainer link", response.data);
//     return {
//       response: response.data,
//       request_data: data,
//     };
//   } catch (error) {
//     return thunkAPI.rejectWithValue({ error: error });
//   }
// });

export const submitLegalReview = createAsyncThunk("chat/submitLegalReview", async (data, thunkAPI) => {
  const accessToken = localStorage.getItem("accessToken2");
  const headers = { Authorization: `Bearer ${accessToken}` };
  const url = `${BASE_ENDPOINT}/api/v0/thread/${data.thread_id}/submit-legal-review`;
  try {
    const response = await axios.post(url, {}, { headers: headers });
    return {
      response: response.data,
      request_data: data,
    };
  } catch (error) {
    return thunkAPI.rejectWithValue({ error: error });
  }
});

export const sendFeedback = createAsyncThunk("chat/sendFeedback", async (data, thunkAPI) => {
  const accessToken = localStorage.getItem("accessToken2");
  const headers = { Authorization: `Bearer ${accessToken}` };
  try {
    const url = `${BASE_ENDPOINT}/api/v0/message/${data.message_id}/add-user-feedback`;
    const response = await axios.post(url, data, { headers: headers });
    return {
      response: response.data,
      data: data,
    };
  } catch (error) {
    return thunkAPI.rejectWithValue({ error: error });
  }
});

export const sendFeedbackWithMessage = createAsyncThunk("chat/sendFeedbackWithMessage", async (data, thunkAPI) => {
  const accessToken = localStorage.getItem("accessToken2");
  const headers = { Authorization: `Bearer ${accessToken}` };
  try {
    const url = `${BASE_ENDPOINT}/api/v0/message/${data.message_id}/add-user-feedback`;
    const response = await axios.post(url, data, { headers: headers });
    return {
      response: response.data,
      data: data,
    };
  } catch (error) {
    return thunkAPI.rejectWithValue({ error: error });
  }
});

export const addLegalReviewFeedback = createAsyncThunk("chat/addLegalReviewFeedback", async (data, thunkAPI) => {
  const accessToken = localStorage.getItem("accessToken2");
  const headers = { Authorization: `Bearer ${accessToken}` };
  try {
    const url = `${BASE_ENDPOINT}/api/v0/thread/${data.threadId}/add-legal-review-feedback`;
    const response = await axios.post(url, { feedback: data.rating }, { headers: headers });
    return {
      response: response.data,
      data: data,
    };
  } catch (error) {
    return thunkAPI.rejectWithValue({ error: error });
  }
});

export const getThread = createAsyncThunk("chat/getThread", async (chatId, thunkAPI) => {
  const accessToken = localStorage.getItem("accessToken2");
  const headers = { Authorization: `Bearer ${accessToken}` };
  try {
    const url = `${BASE_ENDPOINT}/api/thread/${chatId}/list-messages`;
    const response = await axios.get(url, { headers: headers });
    return { data: response.data, threadId: chatId };
  } catch (error) {
    return thunkAPI.rejectWithValue({ error: error, threadId: chatId });
  }
});

export const getSharedThread = createAsyncThunk("chat/getSharedThread", async (chatId, thunkAPI) => {
  const accessToken = localStorage.getItem("accessToken2");
  const headers = { Authorization: `Bearer ${accessToken}` };

  try {
    const url = `${BASE_ENDPOINT}/api/v0/thread/get-shared/${chatId}`;
    const response = await axios.get(url, { headers: headers });
    return { data: response.data, threadId: chatId };
  } catch (error) {
    return thunkAPI.rejectWithValue({ error: error, threadId: chatId });
  }
});

export const getAdditionalMessages = createAsyncThunk("chat/getAdditionalMessages", async (data, thunkAPI) => {
  const accessToken = localStorage.getItem("accessToken2");
  const headers = { Authorization: `Bearer ${accessToken}` };
  try {
    const url = `${BASE_ENDPOINT}/api/v0/thread/${data.chatId}/${data.messageId}/get-new-messages`;
    const response = await axios.get(url, { headers: headers });
    return { data: response.data, threadId: data.chatId };
  } catch (error) {
    return thunkAPI.rejectWithValue({ error: error, threadId: data.chatId });
  }
});

export const convertDocument = createAsyncThunk("chat/convertDocument", async (data, thunkAPI) => {
  const accessToken = localStorage.getItem("accessToken2");
  const headers = { Authorization: `Bearer ${accessToken}` };
  try {
    const url = `${BASE_ENDPOINT}/api/v0/user/document-to-text`;
    const response = await axios.post(url, data, { headers: headers });
    return {
      response: response,
      index: data[1],
      // "title": data[0]
    };
  } catch (error) {
    return thunkAPI.rejectWithValue({ error: error });
  }
});

export const saveAsTemplate = createAsyncThunk("chat/saveAsTemplate", async (data, thunkAPI) => {
  const accessToken = localStorage.getItem("accessToken2");
  const headers = { Authorization: `Bearer ${accessToken}` };

  try {
    const url = `${BASE_ENDPOINT}/api/v0/thread/${data.thread_id}/save-as-template`;
    const response = await axios.post(url, {}, { headers: headers });
    return { response: response.data, request: data };
  } catch (error) {
    return thunkAPI.rejectWithValue({ error: error });
  }
});

export const listTemplates = createAsyncThunk("chat/listTemplates", async (data, thunkAPI) => {
  const accessToken = localStorage.getItem("accessToken2");
  const headers = { Authorization: `Bearer ${accessToken}` };

  try {
    const url = `${BASE_ENDPOINT}/api/v0/user/list-templates`;
    const response = await axios.get(url, { headers: headers });
    return { response: response.data, request: data };
  } catch (error) {
    return thunkAPI.rejectWithValue({ error: error });
  }
});

export const deleteTemplate = createAsyncThunk("chat/deleteTemplate", async (data, thunkAPI) => {
  const accessToken = localStorage.getItem("accessToken2");
  const headers = { Authorization: `Bearer ${accessToken}` };

  try {
    const url = `${BASE_ENDPOINT}/api/v0/user/delete-template/${data.template_id}`;
    const response = await axios.post(
      url,
      {
        template_type: data.template_type,
      },
      { headers: headers }
    );
    return { response: response.data, request: data };
  } catch (error) {
    return thunkAPI.rejectWithValue({ error: error });
  }
});

export const renameTemplate = createAsyncThunk("chat/renameTemplate", async (data, thunkAPI) => {
  const accessToken = localStorage.getItem("accessToken2");
  const headers = { Authorization: `Bearer ${accessToken}` };

  try {
    const url = `${BASE_ENDPOINT}/api/v0/user/rename-template/${data.template_id}`;
    const response = await axios.post(
      url,
      {
        template_type: data.template_type,
        name: data.name,
      },
      { headers: headers }
    );
    return {
      response: response.data,
      request: data,
    };
  } catch (error) {
    return thunkAPI.rejectWithValue({ error: error });
  }
});

export const uploadExternalTemplate = createAsyncThunk("chat/uploadExternalTemplate", async (data, thunkAPI) => {
  const accessToken = localStorage.getItem("accessToken2");
  const headers = { Authorization: `Bearer ${accessToken}` };

  try {
    const url = `${BASE_ENDPOINT}/api/v0/user/new-external-template`;
    const response = await axios.post(url, data, { headers: headers });
    return {
      response: response.data,
      request: data,
    };
  } catch (error) {
    return thunkAPI.rejectWithValue({ error: error });
  }
});

export const acceptEditSuggestions = createAsyncThunk("chat/acceptEditSuggestions", async (data, thunkAPI) => {
  const accessToken = localStorage.getItem("accessToken2");
  const headers = { Authorization: `Bearer ${accessToken}` };

  try {
    const url = `${BASE_ENDPOINT}/api/v0/thread/${data.thread_id}/accept-edit-suggestions`;
    const response = await axios.post(url, data, { headers: headers });
    return {
      response: response.data,
      request: data,
    };
  } catch (error) {
    return thunkAPI.rejectWithValue({ error: error });
  }
});

export const dismissPlaceholderAPI = createAsyncThunk("chat/dismissPlaceholderAPI", async (data, thunkAPI) => {
  const accessToken = localStorage.getItem("accessToken2");
  const headers = { Authorization: `Bearer ${accessToken}` };

  try {
    const url = `${BASE_ENDPOINT}/api/v0/thread/${data}/dismiss-placeholder`;
    const response = await axios.post(url, {}, { headers: headers });
    return {
      response: response.data,
      request: data,
    };
  } catch (error) {
    return thunkAPI.rejectWithValue({ error: error });
  }
});

const chatSlice = createSlice({
  name: "chat",
  initialState: {
    paymentStatusFetchStatus: "idle",
    paymentStatus: "",
    messages: [],
    viewedChats: [],
    submittedChats: [],
    status: "idle",
    error: null,
    newChat: "new",
    newThreadCreated: false,
    threadId: null,
    nextStep: null,
    threads: [],
    threadsNewStructure: {},
    lastMessageId: null,
    sendMessageStatus: "idle",
    sendMessageError: "",
    sendMessageLawyerStatus: "idle",
    sendMessageLawyerError: "",
    feedbackStatus: "idle",
    getThreadStatus: "idle",
    deletedThread: {},
    deleteThreadStatus: "idle",
    updateTitleStatus: "idle",
    updateTitleError: null,
    updatedThreadId: null,
    getDynamicQuestionsStatus: "idle",
    getAllThreadStatus: "idle",
    feedbackError: null,
    getThreadError: {},
    getThreadsError: null,
    deleteThreadError: null,
    getDynamicQuestionsError: null,
    getAllThreadError: null,
    dynamicQuestions: [],
    fetchedThreadId: "",
    getAllClientsStatus: "idle",
    getAllClientsError: null,
    clients: [],
    // messagingDisabled: false,
    requestLegalReviewStatus: "idle",
    requestLegalReviewError: null,
    notUpdatedThreads: [],
    submitLegalReviewStatus: "idle",
    submitLegalReviewError: null,
    legalReviewSubmitted: false,
    legalReviewRequested: false,
    textExtractionStatus: "idle",
    textExtractionError: null,
    textExtractionTrimmed: null,
    extractedText: "",
    // prepareRetainerStatus: "idle",
    // prepareRetainerError: null,
    // retainerDocumentId: "",
    getRetainerStatus: "idle",
    getRetainerError: null,
    retainerLink: "",
    clientUnreadThreadMappings: {},
    acceptLegalReviewStatus: "idle",
    acceptLegalReviewError: null,
    rejectLegalReviewStatus: "idle",
    rejectLegalReviewError: null,
    selectedThread: null,
    intent_state: "ask",
    templateList: null,
    templateListStatus: "idle",
    templateListError: null,
    templateRenameStatus: "idle",
    templateRenameError: null,
    templateDeleteStatus: "idle",
    templateDeleteError: null,
    templateSaved: null,
    templateSaveStatus: "idle",
    templateSaveError: null,
    editTemplate: null,
    externalTemplateSaved: null,
    externalTemplateSaveError: null,
    externalTemplateSaveStatus: "idle",
    changesAccepted: false,
    lastConversationalMessage: null,
    acceptingEditSuggestionsStatus: "idle",
    userMessageCount: 0,
    saveAsTemplateChats: [],
    viewChatLinks: [],
    legalReviewRequestedChats: [],
    messageSentAt: null,
    messageSentForThread: null,
    additionalMessagesFetchedStatus: "idle",
    activePlaceholder: null,
    threadData: {},
    openPaymentModal: false,
    openPaymentSuccessModal: false,
    checkoutUrl: null,
    checkoutUrlStatus: "idle",
  },
  reducers: {
    closePaymentModal: (state) => {
      state.openPaymentModal = false;
    },
    setUserSelectedPlan: (state, action) => {
      state.userSelectedPlan = action.payload;
    },
    changePaymentSuccessModalView: (state, action) => {
      state.openPaymentSuccessModal = action.payload;
    },
    setPlaceholderDismissed: (state, action) => {
      if (state.threadData && state.threadData.placeholders) {
        var skippedPlaceholders = state.threadData.placeholders.skippedPlaceholders || [];
        if (skippedPlaceholders.indexOf(action.payload) === -1) {
          skippedPlaceholders.push(action.payload);
        }
        state.threadData.placeholders.skippedPlaceholders = skippedPlaceholders;
      } else if (!state.threadData) {
        state.threadData = { placeholders: { skippedPlaceholders: [action.payload] } };
      } else if (!state.threadData.placeholders) {
        state.threadData.placeholders = { skippedPlaceholders: [action.payload] };
      }
      var placeholdersLength = state.threadData.placeholders.placeholders.length;
      if (action.payload === placeholdersLength - 1) {
        state.activePlaceholder = null;
        state.threadData.placeholders.activePlaceholder = null;
      } else {
        state.threadData.placeholders.activePlaceholder = action.payload + 1;
      }
    },
    resetPaymentStatus: (state) => {
      state.checkoutUrlStatus = "idle";
    },
    setActivePlaceholder: (state, action) => {
      state.activePlaceholder = action.payload;
    },
    setMessagingSentAt: (state, action) => {
      state.messageSentAt = action.payload;
    },
    updateClientUnreadThreadMappings: (state, action) => {
      var clientId = action.payload.clientId;
      var threadId = action.payload.threadId;
      var task = action.payload.task;
      if (task === "add") {
        // {"client_id": {"draft": [thread_id1, thread_id2, thread_id3]}}
        if (!state.clientUnreadThreadMappings[clientId]) {
          state.clientUnreadThreadMappings = { ...state.clientUnreadThreadMappings, [clientId]: [] };
        }
        if (state.clientUnreadThreadMappings[clientId].indexOf(threadId) === -1) {
          state.clientUnreadThreadMappings[clientId] = [...state.clientUnreadThreadMappings[clientId], threadId];
        }
      }
      if (task === "remove") {
        // {"client_id": {"draft": [thread_id1, thread_id2, thread_id3]}}

        var threads = state.clientUnreadThreadMappings[clientId]?.filter((thread) => thread !== threadId);
        state.clientUnreadThreadMappings[clientId] = threads;
      }
    },
    addFeedbackToMessage: (state, data) => {
      const messages = JSON.parse(JSON.stringify(state.messages));
      var messageIndex = 0;
      for (var i = 0; i < messages.length; i++) {
        if (messages[i].id === data.payload.message_id) {
          messageIndex = i;
          break;
        }
      }
      if (messageIndex > -1) {
        messages[messageIndex].feedback_type = data.payload.action;
      }
      state.messages = [...messages];
    },
    updateViewedChats: (state, chatId) => {
      state.viewedChats = [...state.viewedChats, chatId.payload];
    },
    updateSubmittedChats: (state, chatId) => {
      state.submittedChats = [...state.submittedChats, chatId.payload];
    },
    enableNewChat: (state) => {
      state.newChat = "new";
    },
    disableNewChat: (state) => {
      state.newChat = "old";
    },
    setThreadId: (state, action) => {
      state.threadId = action.payload;
    },
    resetChangesAccepted: (state, action) => {
      state.changesAccepted = false;
    },
    updateMessagingWithAiMessage: (state, action) => {
      // check if the message is already present in the messages array
      state.messages = [...state.messages, action.payload.message];
      state.intent_state = action.payload.message.intent_state;
      if (action.payload.message.text === "Legal Matter Accepted") {
        state.legalReviewAccepted = action.payload.message.created_at;
      }
      if (action.payload.message.text === "Legal Matter Completed") {
        state.legalReviewSubmitted = action.payload.message.created_at;
      }
    },
    updateSelectedThread: (state, action) => {
      state.selectedThread = action.payload;
    },
    resetChatPage: (state, action) => {
      state.messages = [];
      state.textExtractionTrimmed = null;
      if (!action.payload || !action.payload.newThreadCreated) {
        state.status = "idle";
        state.sendMessageStatus = "idle";
        state.newThreadCreated = false;
        state.lastMessageId = null;
        state.getThreadStatus = "idle";
        // state.messagingDisabled = false;
        state.messageSentAt = null;
        state.messageSentForThread = null;
      }
      state.additionalMessagesFetchedStatus = "idle";
      state.saveAsTemplateChats = [];
      state.viewChatLinks = [];
      state.legalReviewRequestedChats = [];
      state.error = null;
      state.newChat = "new";
      state.threadId = null;
      state.nextStep = null;
      state.feedbackStatus = "idle";
      state.deleteThreadStatus = "idle";
      state.deletedThread = null;
      state.getDynamicQuestionsStatus = "idle";
      state.sendMessageError = "";
      state.sendMessageLawyerStatus = "idle";
      state.sendMessageLawyerError = "";
      state.getAllThreadStatus = "idle";
      state.feedbackError = null;
      state.legalReviewSubmitted = false;
      state.legalReviewRequested = false;
      state.legalReviewAccepted = false;
      state.getThreadError = null;
      state.getThreadsError = null;
      state.requestLegalReviewStatus = "idle";
      state.deleteThreadError = null;
      state.getDynamicQuestionsError = null;
      state.getAllThreadError = null;
      state.legalReviewRequested = false;
      // state.prepareRetainerStatus = "idle";
      // state.prepareRetainerError = null;
      state.retainerDocumentId = "";
      state.getRetainerStatus = "idle";
      state.getRetainerError = null;
      state.retainerLink = "";
      state.acceptLegalReviewStatus = "idle";
      state.acceptLegalReviewError = null;
      state.rejectLegalReviewStatus = "idle";
      state.rejectLegalReviewError = null;
      state.legalReviewFedbackStatus = "idle";
      state.legalReviewFeedbackError = null;
      state.intent_state = "ask";
      // state.templateList = [];
      // state.templateListStatus = "idel";
      // state.templateListError = null;
      state.templateRenameStatus = "idle";
      state.templateRenameError = null;
      state.templateDeleteStatus = "idle";
      state.templateDeleteError = null;
      state.templateSaved = null;
      state.templateSaveStatus = "idle";
      state.templateSaveError = null;
      state.externalTemplateSaveError = null;
      state.externalTemplateSaveStatus = "idle";
      state.externalTemplateSaved = null;
      state.lastConversationalMessage = null;
      state.userMessageCount = 0;
    },
    updateGetAllClientStatus: (state) => {
      state.getAllClientsStatus = "loading";
    },
    updateUserMessageCount: (state, action) => {
      state.userMessageCount = action.payload;
    },
    updateSaveAsTemplateChats: (state, action) => {
      if (state.saveAsTemplateChats.includes(action.payload)) {
        return;
      }
      state.saveAsTemplateChats = [...state.saveAsTemplateChats, ...action.payload];
      // console.log(state.saveAsTemplateChats)
    },
    updateLegalReviewRequestChats: (state, action) => {
      if (state.legalReviewRequestedChats.includes(action.payload)) {
        return;
      }
      state.legalReviewRequestedChats = [...state.legalReviewRequestedChats, ...action.payload];
      // console.log(state.legalReviewRequestedChats)
    },
    updateViewChatLinks: (state, action) => {
      if (state.viewChatLinks.includes(action.payload)) {
        return;
      }
      state.viewChatLinks = [...state.viewChatLinks, ...action.payload];
      // console.log(state.viewChatLinks)
    },
    createNewMessage: (state, action) => {
      state.messages = [...state.messages, action.payload];
    },
    updateNewThreadCreated: (state, action) => {
      state.newThreadCreated = action.payload;
      // state.lastMessageId = action.payload.lastMessageId;
      state.getThreadStatus = "idle";
      state.getThreadStatus = "success";
    },
    updateIntentState: (state, action) => {
      state.intent_state = action.payload;
    },
    updateTemplateTitleLocal: (state, action) => {
      let temp = state.templateList;
      // temp = temp.map((template) => {
      //   if (action.payload.template_id === template.id) {
      //     return {
      //       name: action.payload.name,
      //       ...template,
      //     };
      //   }
      //   return template;
      // });
      let index = temp.findIndex((template) => template.id === action.payload.template_id);
      if (index !== -1) {
        temp[index] = {
          ...temp[index],
          name: action.payload.name,
        };
      }
      state.templateList = temp.slice(0);
    },
    deleteTemplateLocal: (state, action) => {
      let temp = state.templateList;
      temp = temp.filter((template) => {
        return action.payload.template_id !== template.id;
      });
      state.templateList = temp.slice(0);
    },
    editTemplateLocal: (state, action) => {
      state.editTemplate = action.payload.template;
    },
    clearEditTemplateLocal: (state, action) => {
      state.editTemplate = null;
    },
    setLastConversationalMessage: (state, action) => {
      state.lastConversationalMessage = action.payload;
    },
    setCheckoutUrl: (state, action) => {
      state.checkoutUrl = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      // sendFeedback handlers
      .addCase(sendFeedbackWithMessage.pending, (state) => {
        state.feedbackStatus = "loading";
        state.error = "";
      })
      .addCase(sendFeedbackWithMessage.fulfilled, (state, action) => {
        if (action.payload.data.feedback !== "") {
          state.feedbackStatus = "success";
        } else {
          state.feedbackStatus = "idle";
        }
        state.error = "";
        state.threadId = action.payload.thread_id;
        state.nextStep = action.payload.next_step;
      })
      .addCase(sendFeedbackWithMessage.rejected, (state, action) => {
        // if (action.payload.error?.response?.status === 401) {
        //   attemptAuthenticationRefresh();
        // }
        state.feedbackStatus = "failed";
        state.error = action.payload.error;
        ErrorLogging(action?.payload?.error);
      })

      .addCase(submitLegalReview.pending, (state) => {
        state.submitLegalReviewStatus = "loading";
        state.submitLegalReviewError = "";
      })
      .addCase(submitLegalReview.fulfilled, (state, action) => {
        let message = action.payload.response.message;
        state.messages = [...state.messages, message];
        state.submitLegalReviewStatus = "success";
        state.submitLegalReviewError = "";
        state.legalReviewRequested = action?.payload?.response?.legal_review_requested;
        state.legalReviewSubmitted = action?.payload?.response?.legal_review_submitted;
        state.legalReviewAccepted = action?.payload?.response?.legal_review_accepted;
      })
      .addCase(submitLegalReview.rejected, (state, action) => {
        // if (action.payload.error?.response?.status === 401) {
        //   attemptAuthenticationRefresh();
        // }
        state.submitLegalReviewStatus = "failed";
        state.submitLegalReviewError = action.payload.error;
        ErrorLogging(action?.payload?.error);
      })
      // document to text conversion
      .addCase(convertDocument.pending, (state) => {
        state.textExtractionStatus = "loading";
        state.textExtractionError = "";
        state.textExtractionTrimmed = null;
      })
      .addCase(convertDocument.fulfilled, (state, action) => {
        state.textExtractionStatus = "success";
        state.textExtractionError = "";
        state.extractedText = action.payload.response.data.text;
        state.textExtractionTrimmed = action.payload.response.data.restricted_word_count;
      })
      .addCase(convertDocument.rejected, (state, action) => {
        state.textExtractionStatus = "failed";
        state.textExtractionError = action.payload.error;
        state.textExtractionTrimmed = null;
        ErrorLogging(action?.payload?.error);
      })
      // prepareRetainer handlers
      // .addCase(prepareRetainer.pending, (state) => {
      //   state.prepareRetainerStatus = "loading";
      //   state.prepareRetainerError = "";
      // })
      // .addCase(prepareRetainer.fulfilled, (state, action) => {
      //   state.prepareRetainerStatus = "success";
      //   state.prepareRetainerError = "";
      //   state.retainerDocumentId = action.payload.response.documentId;
      // })
      // .addCase(prepareRetainer.rejected, (state, action) => {
      //   state.prepareRetainerStatus = "failed";
      //   state.prepareRetainerError = action.payload.error;
      // })
      // getRetainer handlers
      .addCase(getRetainer.pending, (state) => {
        state.getRetainerStatus = "loading";
        state.getRetainerError = "";
      })
      .addCase(getRetainer.fulfilled, (state, action) => {
        state.getRetainerStatus = "success";
        state.getRetainerError = "";
        state.retainerLink = action.payload.response.embed_url;
      })
      .addCase(getRetainer.rejected, (state, action) => {
        state.getRetainerStatus = "failed";
        state.getRetainerError = action.payload.error;
        ErrorLogging(action?.payload?.error);
      })

      .addCase(getSharedThread.fulfilled, (state, action) => {
        state.getSharedThreadStatus = "success";
        state.getSharedThreadError = extractErrors(action.payload?.error?.response);
        state.fetchedThreadId = action.payload.threadId;
        state.messages = action.payload.data.messages.sort((a, b) => a.sequence_number - b.sequence_number);
      })
      .addCase(getSharedThread.rejected, (state, action) => {
        state.getSharedThreadStatus = "failed";
        state.getSharedThreadError = extractErrors(action.payload.error);
        state.messages = [];
        ErrorLogging(action?.payload?.error);
      })
      .addCase(getSharedThread.pending, (state) => {
        state.getSharedThreadStatus = "loading";
        state.getSharedThreadError = "";
        state.messages = [];
      })
      .addCase(addLegalReviewFeedback.fulfilled, (state, action) => {
        state.legalReviewFedbackStatus = "success";
        state.legalReviewFedbackError = "";
        state.legalReviewFedback = action.payload.data;
      })
      .addCase(addLegalReviewFeedback.rejected, (state, action) => {
        state.legalReviewFedbackStatus = "failed";
        state.legalReviewFedbackError = extractErrors(action.payload.error);
        ErrorLogging(action?.payload?.error);
      })
      .addCase(addLegalReviewFeedback.pending, (state, action) => {
        state.feedbackStatus = "loading";
        state.feedbackError = "";
      })
      .addCase(listTemplates.fulfilled, (state, action) => {
        state.templateListStatus = "success";
        state.templateListError = "";

        // Ensure external_templates is an array
        let external_templates = action.payload.response?.external_templates;
        if (!Array.isArray(external_templates)) {
          external_templates = external_templates ? [external_templates] : [];
        }
        external_templates = external_templates.sort((a, b) => a.creation_date - b.creation_date);

        // Ensure internal_templates is an array
        let internal_templates = action.payload.response?.internal_templates?.map((temp) => ({
          ...temp,
          isInternalTemplate: true,
        }));
        if (!Array.isArray(internal_templates)) {
          internal_templates = internal_templates ? [internal_templates] : [];
        }
        internal_templates = internal_templates.sort((a, b) => a.creation_date - b.creation_date);

        state.templateList = [...external_templates, ...internal_templates];
        const deletedTemplates = state?.templateList?.filter((temp) => temp.deleted)?.length;

        if (state?.templateList?.length === 0) {
          message.error("No templates found");
        } else if (state?.templateList?.length === deletedTemplates) {
          message.info(`No active templates found. You have ${deletedTemplates} archived items`);
        }
      })
      .addCase(listTemplates.rejected, (state, action) => {
        message.error("Something went wrong");
        state.templateListStatus = "failed";
        state.templateListError = extractErrors(action.payload.error);
        ErrorLogging(action?.payload?.error);
      })
      .addCase(listTemplates.pending, (state, action) => {
        state.templateListStatus = "loading";
        state.templateListError = "";
      })
      .addCase(deleteTemplate.fulfilled, (state, action) => {
        state.templateDeleteStatus = "success";
        state.templateDeleteError = "";
      })
      .addCase(deleteTemplate.rejected, (state, action) => {
        state.templateDeleteStatus = "failed";
        state.templateDeleteError = extractErrors(action.payload.error);
        ErrorLogging(action?.payload?.error);
      })
      .addCase(deleteTemplate.pending, (state, action) => {
        state.templateDeleteStatus = "loading";
        state.templateDeleteError = "";
      })
      .addCase(renameTemplate.fulfilled, (state, action) => {
        state.templateRenameStatus = "success";
        state.templateRenameError = "";
      })
      .addCase(renameTemplate.rejected, (state, action) => {
        state.templateRenameStatus = "failed";
        state.templateRenameError = extractErrors(action.payload.error);
        ErrorLogging(action?.payload?.error);
      })
      .addCase(renameTemplate.pending, (state, action) => {
        state.templateRenameStatus = "loading";
        state.templateRenameError = "";
      })
      .addCase(saveAsTemplate.fulfilled, (state, action) => {
        state.templateSaveStatus = "success";
        state.templateSaveError = "";
        state.templateSaved = action.payload.response.template;
        let temp = state.templateList || [];
        temp = [...temp, action.payload.response.template];
        state.templateList = temp.slice(0);
        state.messages = [...state.messages, action.payload.response.message];
        // state.messageSentAt = new Date();
        // state.messageSentForThread = state.threadId;
      })
      .addCase(saveAsTemplate.rejected, (state, action) => {
        state.templateSaveStatus = "failed";
        state.templateSaveError = extractErrors(action.payload.error);
        ErrorLogging(action?.payload?.error);
      })
      .addCase(saveAsTemplate.pending, (state, action) => {
        state.templateSaveStatus = "loading";
        state.templateSaveError = "";
      })
      .addCase(uploadExternalTemplate.fulfilled, (state, action) => {
        state.externalTemplateSaveStatus = "success";
        state.externalTemplateSaveError = "";
        state.externalTemplateSaved = action.payload.response.template;
        let temp = state.templateList || [];
        temp = [action.payload.response.template, ...temp];
        state.templateList = temp.slice(0);
      })
      .addCase(uploadExternalTemplate.rejected, (state, action) => {
        state.externalTemplateSaveStatus = "failed";
        state.templateSaveError = extractErrors(action.payload.error);
        ErrorLogging(action?.payload?.error);
      })
      .addCase(uploadExternalTemplate.pending, (state, action) => {
        state.externalTemplateSaveStatus = "loading";
        state.externalTemplateSaveError = "";
      })
      .addCase(acceptEditSuggestions.fulfilled, (state, action) => {
        state.acceptingEditSuggestionsStatus = "success";
        state.changesAccepted = true;
        state.messages = [...state.messages, action.payload.response];
      })
      .addCase(acceptEditSuggestions.rejected, (state, action) => {
        state.changesAccepted = false;
        state.acceptingEditSuggestionsStatus = "failed";
        ErrorLogging(action?.payload?.error);
      })
      .addCase(acceptEditSuggestions.pending, (state, action) => {
        state.changesAccepted = false;
        state.acceptingEditSuggestionsStatus = "loading";
      })
      .addCase(getCheckoutUrl.pending, (state, action) => {
        // state.checkoutUrl = action.payload.response.url;
        state.checkoutUrlStatus = "loading";
      })
      .addCase(getCheckoutUrl.fulfilled, (state, action) => {
        state.checkoutUrl = action.payload.response;
        state.checkoutUrlStatus = "success";
        state.openPaymentModal = true;
        // state.checkoutUrl = {
        //   "url": "https://inhouse-test.chargebee.com/pages/v4/nVAwWSvbw9QpHcpG5RwG8yCptNPlrFVU/?signature=VSRBcdk8cXM3Ce1URa9FcuzxkCSBEhJaBJ",
        //   "url_expiry_time": 1730984701,
        //   "payment_id": "4d9a65e3-c7f5-42c7-900f-1d8f69d7a15a"
        // }
      })
      .addCase(getCheckoutUrl.rejected, (state, action) => {
        var message = "Failed to get checkout url";
        try {
          message = JSON.parse(action.payload.error.request.response).detail;
          if (message.toLowerCase().search("user is already ") > -1) {
            message = "You are already subscribed to this plan";
          }
        } catch (e) {}
        state.checkoutUrl = "";
        state.checkoutUrlStatus = message;
        ErrorLogging(action?.payload?.error);
      })

      .addCase(getPaymentStatus.pending, (state, action) => {
        state.paymentStatusFetchStatus = "loading";
        state.paymentStatus = "";
      })
      .addCase(getPaymentStatus.fulfilled, (state, action) => {
        state.paymentStatus = action.payload.response.status;
        state.paymentStatusFetchStatus = "success";
      })
      .addCase(getPaymentStatus.rejected, (state, action) => {
        state.paymentStatusFetchStatus = "failed";
        ErrorLogging(action?.payload?.error);
      });
  },
});

export default chatSlice.reducer;
export const {
  enableNewChat,
  updateGetAllClientStatus,
  addFeedbackToMessage,
  disableNewChat,
  setThreadId,
  resetChatPage,
  updateViewedChats,
  updateSubmittedChats,
  // updateMessagingDisabled,
  updateClientUnreadThreadMappings,
  createNewMessage,
  updateNewThreadCreated,
  updateMessagingWithAiMessage,
  updateSelectedThread,
  updateTemplateTitleLocal,
  deleteTemplateLocal,
  editTemplateLocal,
  clearEditTemplateLocal,
  resetChangesAccepted,
  setLastConversationalMessage,
  updateUserMessageCount,
  updateViewChatLinks,
  updateSaveAsTemplateChats,
  updateLegalReviewRequestChats,
  setMessagingSentAt,
  setActivePlaceholder,
  setPlaceholderDismissed,
  closePaymentModal,
  setCheckoutUrl,
  resetPaymentStatus,
  setUserSelectedPlan,
  changePaymentSuccessModalView,
} = chatSlice.actions;
