import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useAuth, useUser } from "@clerk/clerk-react";
import { setUserIdentified } from "src/pages/onboarding/onboardingSlice";

const useValidatePageAccess = () => {
  const dispatch = useDispatch();
  const userMetadata = useSelector((state) => state.onboarding.userMetadata);
  const userIdentified = useSelector((state) => state.onboarding.userIdentified);

  const { getToken, isLoaded, isSignedIn } = useAuth();
  const { user } = useUser();

  useEffect(() => {
    const getMyToken = async () => {
      const token = await getToken({ template: "Backend" });
      return token;
    };
    const getTokenValue = async () => {
      const token = await getMyToken();
      if (token && typeof token === "string") {
        localStorage.setItem("accessToken2", token);
      }
    };
    getTokenValue();
  }, [getToken]);
  useEffect(() => {
    setInterval(() => {
      if (isSignedIn) {
        const getMyToken = async () => {
          const token = await getToken({ template: "Backend" });
          return token;
        };

        const getTokenValue = async () => {
          const token = await getMyToken();
          if (token && typeof token === "string") {
            localStorage.setItem("accessToken2", token);
          }
        };
        if (isSignedIn) {
          getTokenValue();
        }
      }
    }, 10000);
  }, [isSignedIn]);

  useEffect(() => {
    if (isLoaded) {
      if (isSignedIn) {
        const getMyToken = async () => {
          const token = await getToken({ template: "Backend" });
          return token;
        };

        const getTokenValue = async () => {
          const token = await getMyToken();
          // console.log("line 56, token=", token);
          if (token && typeof token === "string") {
            localStorage.setItem("accessToken2", token);
          }
        };
        // this is called to ensure that the
        // console.log("line 65", isSignedIn);
        if (isSignedIn) {
          // console.log("line 67, getting token value");
          getTokenValue();
        }
      } else {
        // console.log(`user is not signed in. lined 72.`);
        localStorage.removeItem("accessToken2");
        localStorage.removeItem("refreshToken2");
        localStorage.removeItem("user");
        localStorage.removeItem("userType");
        localStorage.removeItem("client_details");
        localStorage.removeItem("lawyer_info");
        localStorage.removeItem("showForm");
        if (window.location.href.search("/shared/") === -1) {
          window.location.href = process.env.REACT_APP_POST_SIGNOUT_URL;
        }
      }
    }
  }, [isLoaded, isSignedIn]);

  useEffect(() => {
    if (user) {
      if (userMetadata) {
        var email = user?.primaryEmailAddress?.emailAddress;
        var data = {
          email: email,
          first_name: userMetadata?.first_name,
          last_name: userMetadata?.last_name,
          organization_name: userMetadata?.entity_info?.name || "",
          state_incorporation: userMetadata?.entity_info?.state_incorporation || "",
          state_business: userMetadata?.entity_info?.primary_business_state || "",
          state_residence: userMetadata?.individual_info?.state_of_residence || "",
          account_type: userMetadata?.entity_info ? "business" : "personal",
          payment_frequency: userMetadata?.payment_frequency || "",
          assigned_lawyer_name: userMetadata?.lawyer_info?.name || "",
          assigned_lawyer_email: userMetadata?.lawyer_info?.email || "",
          subscription_type: userMetadata?.subscription_type || "",
        };
        var id = userMetadata?.user_id;
        if (id) {
          window.analytics.identify(id, data);
          dispatch(setUserIdentified(true));
        }
      }
    }
  }, [user, userMetadata]);
};

export default useValidatePageAccess;
