import React from "react";
import { Info } from "@phosphor-icons/react";
import TextArea from "antd/es/input/TextArea";

// interface TextInputProps {
//     label: string
//     value?: string
//     onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
//     placeholder?: string;
//     wrapperClassName?: string;
// }

const TextInput = (props) => {
  const { label = "Text Input", value, onChange, placeholder, wrapperClassName, tooltip, error, rows } = props;

  return (
    <div className={`flex flex-col ${wrapperClassName}`}>
      <div className="flex justify-start gap-2 items-center">
        <label className="text-[#302F2F] text-xs md:text-base font-semibold">{label}</label>
        <div className="relative group">
          {tooltip ? <Info size={17} className="cursor-pointer" /> : ""}
          {/* Tooltip */}
          <div className="absolute w-80 bg-[#686868] hidden group-hover:block text-white text-xs p-1.5 rounded-[16px] ml-6 bottom-1  ">
            {tooltip}
          </div>
        </div>{" "}
      </div>

      {!rows && (
        <input
          className="border w-full outline-none border-[#E4E4E4] rounded-full h-[48px] mt-2 text-[12px] md:text-[14px] px-4 text-[#302F2F] font-semibold placeholder:font-normal  focus:!border-[#18475A]"
          value={value}
          onChange={onChange}
          placeholder={placeholder}
        />
      )}
      {rows && (
        <textarea
          className="border w-full outline-none border-[#E4E4E4] rounded-xl  mt-2 text-md px-4 py-3 text-[#302F2F] font-semibold placeholder:font-normal focus:!border-[#18475A]"
          value={value}
          onChange={onChange}
          placeholder={placeholder}
          rows={rows}
        />
      )}
      {error && <p className="text-red-500 text-sm mt-1">{error}</p>}
    </div>
  );
};

export default TextInput;
