/* eslint-disable jsx-a11y/aria-role */
import React, { useEffect, useState } from "react";
import IndividualForm from "./IndividualForm";
import Button from "../LawyerHub/Button";
import { SignOutButton } from "@clerk/clerk-react";
import { useSelector, useDispatch } from "react-redux";
import { createUserAPI, getUserMetadata } from "../onboarding/onboardingSlice";
import { createNewParty, onboardUser } from "../../pages/Homepage/HomeSlice";
import InhouseLogo from "../../images/inhouse-logo-v2.png";
import { trackAnalytics } from "../helpers";
import OnboardingStep1 from "../onboarding/OnboardingStep1";
import OnboardingStep2 from "../onboarding/OnboardingStep2";
import homePrint from "../../images/homePrint.svg";
import tailor from "../../images/tailor.png";
import background from "../../images/bg.png";

const OnboardingV2 = ({ user, getToken, sidenav, moveNext }) => {
  const queryParams = new URLSearchParams(window.location.search);
  const utm_source = queryParams.get("utm_source");
  const next = queryParams.get("next");

  // const { user: clerkUser } = useUser();

  const [firstName, setFirstName] = useState("");
  const [fullName, setFullName] = useState("");
  const [lastName, setLastName] = useState("");
  const [organizationName, setOrganizationName] = useState("");
  // const [industry, setIndustry] = useState("");
  const [businessPurpose, setBusinessPurpose] = useState("");
  const [entityStructure, setEntityStructure] = useState("");
  const [businessState, setBusinessState] = useState("");
  const [incorporationState, setIncorporationState] = useState("");
  // const [profileUpdated, setProfileUpdated] = useState(false);
  // const [profileUpdationError, setProfileUpdationError] = useState(false);
  const [isEntity, setIsEntity] = useState(true);
  const [createUserAttemptCount, setCreateUserAttemptCount] = useState(0);
  const [notice, setNotice] = useState("");
  const [errors, setErrors] = useState({});
  const [step, setStep] = useState(sidenav ? 1 : 0);

  const [showLoading, setShowLoading] = useState(false);
  const [address, setAddress] = useState("");

  const dispatch = useDispatch();
  const onboardUserStatus = useSelector((state) => state.homeslice.onboardUserStatus);
  const onboardFailureReason = useSelector((state) => state.homeslice.onboardFailureReason);
  // const error = useSelector((state) => state.onboarding.error);
  const createUserAPIStatus = useSelector((state) => state.onboarding.createUserAPIStatus);
  const userAlreadyExist = useSelector((state) => state.onboarding.userAlreadyExist);
  const partiesList = useSelector((state) => state.homeslice.partiesList);
  const userMetadataFetchStatus = useSelector((state) => state.onboarding.userMetadataFetchStatus);
  const userMetadata = useSelector((state) => state.onboarding.userMetadata);
  const createNewPartyStatus = useSelector((state) => state.homeslice.createNewPartyStatus);

  const getMyToken = async () => {
    const token = await getToken({ template: "Backend" });
    return token;
  };
  const getTokenValue = async () => {
    const token = await getMyToken();
    if (token && typeof token === "string") {
      localStorage.setItem("accessToken2", token);
    }
  };

  const moveNextStep = () => {
    var err = false;
    var errs = {};
    if (isEntity) {
      if (step === 1) {
        if (!organizationName || organizationName === "") {
          err = true;
          errs["organizationName"] = "Please provide your Company Name";
          // return;
        }
        if (!businessPurpose || businessPurpose?.length < 3) {
          err = true;
          errs["businessPurpose"] = "Please enter a valid Business Purpose";
        }
        if (!businessState) {
          err = true;
          errs["businessState"] = "Please select Primary State of Business";
        }
        if (!incorporationState) {
          err = true;
          errs["incorporationState"] = "Please select State of Incorporation";
        }
        if (err) {
          setErrors({ ...errs });
          return;
        }
      }
    } else {
      if (step > 0) {
        if (!organizationName || organizationName?.length === "") {
          err = true;
          errs["organizationName"] = "Please add your Full Name";
        }
        if (!incorporationState) {
          err = true;
          errs["incorporationState"] = "Please select State of Residence";
        }
        if (err) {
          setErrors({ ...errs });
          return;
        }
      }
    }
    if (step === 1) {
      submit();
    } else {
      setErrors({});
      setStep((step) => step + 1);
    }
  };

  const submit = async () => {
    setShowLoading(true);
    setNotice("");

    var entityInfo = null;
    var individualInfo = null;
    if (isEntity) {
      var entityType;
      if (sidenav) {
        entityType = "Corporation";
      } else {
        entityType = "Corporation";
      }
      entityInfo = {
        type: entityType,
        state_incorporation: incorporationState,
        primary_business_state: businessState,
        address: address,
        business_purpose: businessPurpose,
      };
    } else {
      individualInfo = {
        state_residence: incorporationState,
        description: businessPurpose,
        address: address,
      };
    }
    var primaryId = null;
    if (partiesList.length > 0) {
      var primaryParty = partiesList.filter((party) => party.is_primary === true);
      if (primaryParty.length > 0) {
        primaryId = primaryParty[0].id;
      }
    }

    const data = {
      id: primaryId,
      is_primary: true,
      full_name: organizationName,
      entity_info: entityInfo,
      individual_info: individualInfo,
    };
    if (sidenav) {
      dispatch(createNewParty(data));
    } else {
      dispatch(onboardUser(data));
    }
  };

  const clearTokens = () => {
    localStorage.removeItem("accessToken2");
    localStorage.removeItem("refreshToken2");
    localStorage.removeItem("user");
    localStorage.removeItem("userType");
    localStorage.removeItem("client_details");
    localStorage.removeItem("lawyer_info");
    localStorage.removeItem("showForm");
  };

  useEffect(() => {
    setNotice("");
    getTokenValue();
    window.scrollTo({ top: 0, behavior: "smooth" });
    window.analytics.page("Onboarding page viewed", { user_email: user?.primaryEmailAddress?.emailAddress });
    if (utm_source === "signup") {
      setTimeout(() => {
        dispatch(createUserAPI());
      }, 3000);
    } else {
      dispatch(getUserMetadata());
      // dispatch(getSavedParties());
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (createUserAPIStatus === "success") {
      dispatch(getUserMetadata());
    } else if (createUserAPIStatus === "failed" && userAlreadyExist) {
      dispatch(getUserMetadata());
      // dispatch(getSavedParties());
    }
  }, [createUserAPIStatus, userAlreadyExist]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (userMetadataFetchStatus === "failed") {
      if (createUserAttemptCount < 2) {
        setCreateUserAttemptCount(createUserAttemptCount + 1);
        dispatch(createUserAPI());
      }
      // dispatch(getSavedParties());
    }
  }, [userMetadataFetchStatus]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (userMetadata) {
      if (!sidenav && userMetadata?.first_name) {
        window.location.href = "/home";
      }
      var is_not_personal = userMetadata.entity_info ? true : false;
      if (is_not_personal === null || is_not_personal === undefined) {
        is_not_personal = true;
      }
      var name = `${userMetadata?.first_name || ""}`;
      var state = is_not_personal
        ? userMetadata?.entity_info?.state_incorporation
        : userMetadata?.individual_info?.state_of_residence;
      if (is_not_personal) {
        setFullName(userMetadata?.entity_info?.name);
        setOrganizationName(userMetadata?.entity_info?.name);
      } else {
        if (!name) {
          name = user?.firstName;
        }
        setFullName(name);
        setOrganizationName(name);
        console.log("setting name to 241", name);
      }
      setBusinessState(userMetadata?.entity_info?.primary_business_state);
      setIncorporationState(state);
      setBusinessPurpose(userMetadata?.entity_info?.business_purpose || userMetadata?.individual_info?.description);
      setIsEntity(is_not_personal);
    }
  }, [userMetadata]);

  useEffect(() => {
    if (!fullName && user?.firstName) {
      setFullName(user?.firstName);
      setOrganizationName(user?.firstName);
    }
  }, [user]);

  useEffect(() => {
    if (onboardUserStatus === "success" || createNewPartyStatus === "success") {
      setShowLoading(false);
      dispatch(getUserMetadata());
      // dispatch(getSavedParties());
      trackAnalytics(
        "profile_updated",
        { user_email: user?.primaryEmailAddress?.emailAddress, source: sidenav ? "sidenav" : "onboarding" },
        userMetadata
      );
      moveNext(next);
    } else if (onboardUserStatus === "failed" || createNewPartyStatus === "failed") {
      setShowLoading(false);
      if (onboardFailureReason) {
        setNotice(onboardFailureReason);
      } else {
        setNotice("Error saving data. Please try again.");
      }
    }
  }, [onboardUserStatus, createNewPartyStatus]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    const spaceIndex = fullName?.indexOf(" ");
    if (spaceIndex && spaceIndex !== -1) {
      const firstName = fullName.slice(0, spaceIndex);
      const lastName = fullName.slice(spaceIndex + 1);
      setFirstName(firstName);
      setLastName(lastName);
    } else {
      console.log("setting last name as blank");
      setFirstName(fullName || "");
      setLastName(""); // Reset last name
    }
  }, [fullName]);

  return (
    <div className={`flex ${sidenav ? "flex-col" : "flex-col md:flex-row"} h-screen max-h-screen md:overflow-hidden`}>
      {/* <img
          className="bg-transparent my-2 self-start h-[50px] object-contain bg-white px-10 block md:hidden"
          src={InhouseLogo} alt="Inhouse"
        /> */}

      <div
        role="left side"
        className={` md:!bg-white bg-transparent w-full ${
          !sidenav ? "md:w-full lg:w-1/2" : ""
        } h-auto md:h-screen md:overflow-y-scroll px-6 md:px-10 py-4 pb-0 md:py-10 md:flex flex-col gap-[24px]`}
      >
        {!sidenav && (
          <div className="flex flex-row justify-between items-center">
            <img className="w-[199px] self-start h-[50px] object-contain" src={InhouseLogo} alt="Inhouse" />
            <p className="flex flex-row justify-end w-full my-3">
              <span
                className="cursor-pointer"
                onClick={() => {
                  window.analytics.reset();
                  clearTokens();
                }}
              >
                <SignOutButton redirectUrl={process.env.REACT_APP_POST_SIGNOUT_URL} />
              </span>
            </p>
          </div>
        )}
        <div className="px-auto py-[24px] md:h-screen">
          {!sidenav ? (
            <div className="lg:px-4 gap-[56px]">
              <div className="flex justify-start py-2 items-center gap-2">
                <p className="text-[#444444] font-medium text-lg pr-4 font-sans">STEP {step + 1} / 2 </p>
                <span className={`h-2 w-12 rounded-full ${step >= 0 ? "!bg-[#4F54E9]" : ""} bg-[#CCCCCC]`}></span>
                <span className={`h-2 w-12 rounded-full ${step >= 1 ? "!bg-[#4F54E9]" : ""} bg-[#CCCCCC]`}></span>
                {/* <span className={`h-2 w-12 rounded-full ${step >= 2 ? "!bg-[#4F54E9]" : ""} bg-[#CCCCCC] ${!isEntity && "hidden"}`} ></span> */}
              </div>
              {step === 0 && (
                <p className="text-[#0D0D12] font-bold text-[32px]  font-[lora] italic mt-3">
                  Which describe your legal needs?
                </p>
              )}
              {step > 0 && (
                <>
                  {isEntity ? (
                    <p className="w-full text-[#0D0D12] font-bold text-[32px] font-[lora] italic mt-3">
                      Tailor the AI for your specific needs
                    </p>
                  ) : (
                    <p className="w-full text-[#0D0D12] font-bold text-[32px] font-[lora] italic mt-3">
                      Let’s tailor the AI for your <br />
                      personal needs
                    </p>
                  )}
                </>
              )}
            </div>
          ) : (
            <div className="px-8">
              <p className="text-[#0D0D12] font-bold text-[32px]  font-[lora] italic mt-3">Update Profile?</p>
            </div>
          )}

          <div className="px-2 md:px-8">
            {step === 0 && <OnboardingStep1 setIsEntity={setIsEntity} setNotice={setNotice} isEntity={isEntity} />}
            {isEntity && step === 1 && (
              <OnboardingStep2
                organizationName={organizationName}
                businessPurpose={businessPurpose}
                setCompanyChange={(e) => setOrganizationName(e.target.value)}
                setPurposeChange={(e) => {
                  setBusinessPurpose(e.target.value);
                }}
                entityStructure={entityStructure}
                businessState={businessState}
                incorporationState={incorporationState}
                setPrimaryStateChange={(e) => setBusinessState(e.target.value)}
                setIncorporationStateChange={(e) => setIncorporationState(e.target.value)}
                setEntityStructureChange={(e) => setEntityStructure(e.target.value)}
                errors={errors}
              />
            )}

            {!isEntity && step === 1 && (
              <IndividualForm
                organizationName={organizationName}
                incorporationState={incorporationState}
                businessPurpose={businessPurpose}
                setOrganizationName={(e) => setOrganizationName(e.target.value)}
                setPurposeChange={(e) => {
                  setBusinessPurpose(e.target.value);
                }}
                setIncorporationState={(e) => setIncorporationState(e.target.value)}
                errors={errors}
              />
            )}
            {notice && (
              <>
                <p className="text-red-500 text-sm mt-1">{notice}</p>
              </>
            )}
            {notice === "User has already been onboarded" ? (
              <button
                className="bg-[#1B2B48] p-2 text-white rounded text-md font-sans cursor-pointer"
                onClick={() => moveNext(next)}
              >
                Go to Dashboard
              </button>
            ) : (
              <div className={`flex justify-end py-8 ${step === 1 ? "justify-between" : ""}`}>
                {!sidenav && (
                  <div className="hidden md:block">
                    {step === 1 && (
                      <Button
                        onClick={() => setStep((s) => s - 1)}
                        className="!font-bold !text-[#1B2B48] bg-transparent border-0 text-md font-sans cursor-pointer"
                        text="Go Back"
                      ></Button>
                    )}
                  </div>
                )}
                <Button
                  className="!rounded-full bg-[#1B2B48] text-base w-full md:w-[120px] flex justify-center !text-center"
                  text={
                    !showLoading ? (
                      step === 1 ? (
                        <p className="text-center">{sidenav ? "Update" : "Let's go"}</p>
                      ) : (
                        <p>Continue</p>
                      )
                    ) : (
                      <div className="w-6 h-6 border-2 border-t-0 border-[#fff] rounded-full animate-spin"></div>
                    )
                  }
                  onClick={moveNextStep}
                ></Button>
              </div>
            )}
          </div>
        </div>
      </div>

      {!sidenav && (
        <div
          role="right side"
          className=" bg-[#FAF9F7] w-full hidden lg:w-1/2 h-auto md:h-screen md:flex justify-center items-center"
        >
          {step === 0 && (
            <div className="flex justify-end items-center w-full h-full pl-4">
              <img src={homePrint} alt="homePrint" />
            </div>
          )}
          {step > 0 && (
            <div className="flex justify-center items-center w-full h-full relative">
              <div className="h-full w-full">
                <div className="flex flex-col justify-center items-center w-full h-full">
                  <img src={background} alt="bg" className="absolute object-cover z-10" />
                  <div className="absolute object-cover z-20 md:pl-[100px]">
                    <img src={tailor} alt="onboardRightsection" />
                  </div>
                </div>
                <div className="flex justify-center items-center w-full">
                  <p className="text-[16px] text-center w-[267px] leading-[22.8px] text-[#000] font-[500] justify-center mt-[-150px]">
                    Get personalised AI responses that <br />
                    are tailored just for your needs
                  </p>
                </div>
              </div>
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default OnboardingV2;
