import { Lock } from "@phosphor-icons/react";
import { openPricingModal } from "../helpers";
const UpgradeToUnlockDocument = (props) => {
  const { threadData, dispatch, metadata, email } = props;
  return (
    <div className="mt-4 flex flex-col gap-[16px] justify-center items-center px-[24px] py-[16px] w-[371px] rounded-[12px] bg-white border">
      <p className="font-[500] text-[16px] ">{threadData?.document?.google_doc_title}</p>
      <button
        className="w-full bg-[#18475A] text-white text-[12px] h-[41px] leading-[16.8px] font-semibold font-sans p-[12px] rounded flex justify-center items-center gap-[10px]"
        onClick={() => openPricingModal(dispatch, metadata, email, "chat")}
      >
        {" "}
        <Lock size={16} /> <span>Upgrade to Unlock Document</span>
      </button>
    </div>
  );
};

export default UpgradeToUnlockDocument;
