import React from 'react';

interface ButtonProps {
    text: string | React.ReactElement;
    onClick?: () => void;
    className?: string;
    secondary?: boolean;
    disabled?: boolean
    buttonClass?: String
}

function Button({ text, onClick, className, secondary, disabled, buttonClass }: ButtonProps) {
    return (
        <button
            className={`${buttonClass} w-full justify-center text-white font-medium py-2 px-6 rounded-lg flex items-center
            ${disabled ? '!text-gray-400 border !cursor-not-allowed !border-gray-300 !bg-white': ''} ${className ? className : ''}`}
            onClick={disabled ? () => {} : onClick}
        >
            {text}
        </button>
    );
}

export default Button;
